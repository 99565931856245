import {
  CHANGE_APPS_VERSION,
  CHANGE_APPS_VERSION_SUCCESS,
  CREATE_CONFIG,
  CREATE_CONFIG_SUCCESS,
  CREATE_WHITELABEL,
  CREATE_WHITELABEL_SUCCESS,
  DELETE_CONFIG,
  DELETE_CONFIG_SUCCESS,
  DELETE_WHITELABEL,
  DELETE_WHITELABEL_SUCCESS,
  GET_CONFIG,
  GET_CONFIG_SUCCESS,
  GET_RELEASE_NOTES,
  GET_RELEASE_NOTES_SUCCESS,
  GET_WHITELABEL,
  GET_WHITELABELS,
  GET_WHITELABELS_SUCCESS,
  GET_WHITELABEL_SUCCESS,
  RUN_WHITELABEL_BUILD,
  RUN_WHITELABEL_BUILD_SUCCESS,
  SET_BUILDS,
  SET_BUILDS_FAILURE,
  SET_BUILDS_SUCCESS,
  SET_BUILD_LOADING,
  UPDATE_CONFIG,
  UPDATE_CONFIG_SUCCESS,
  UPDATE_WHITELABEL,
  UPDATE_WHITELABEL_SUCCESS,
  UPLOAD_CONFIG_CERTIFICATE,
} from 'constants/ActionTypes';
import {
  ChangeAppsVersionSuccessActionPayload,
  CreateConfigActionPayload,
  CreateConfigSuccessActionPayload,
  CreateWhitelabelActionPayload,
  CreateWhitelabelSuccessActionPayload,
  DeleteConfigActionPayload,
  DeleteWhitelabelActionPayload,
  GetConfigActionPayload,
  GetConfigSuccessActionPayload,
  GetWhitelabelActionPayload,
  GetWhitelabelSuccessActionPayload,
  GetWhitelabelsSuccessActionPayload,
  RunWhitelabelBuildActionPayload,
  RunWhitelabelBuildSuccessActionPayload,
  SetBuildLoadingActionPayload,
  SetBuildsActionPayload,
  SetBuildsFailureActionPayload,
  SetBuildsSuccessActionPayload,
  UpdateConfigActionPayload,
  UpdateConfigSuccessActionPayload,
  UpdateWhitelabelActionPayload,
  UpdateWhitelabelSuccessActionPayload,
  UploadCertificatePayload,
  WhitelabelsActionTypes,
} from './types/Whitelabels';

export const getWhitelabels = (): WhitelabelsActionTypes => ({
  type: GET_WHITELABELS,
});

export const getWhitelabelsSuccess = (payload: GetWhitelabelsSuccessActionPayload): WhitelabelsActionTypes => ({
  type: GET_WHITELABELS_SUCCESS,
  payload,
});

export const getWhitelabel = (payload: GetWhitelabelActionPayload): WhitelabelsActionTypes => ({
  type: GET_WHITELABEL,
  payload,
});

export const getWhitelabelSuccess = (payload: GetWhitelabelSuccessActionPayload): WhitelabelsActionTypes => ({
  type: GET_WHITELABEL_SUCCESS,
  payload,
});

export const setBuilds = (payload: SetBuildsActionPayload): WhitelabelsActionTypes => ({
  type: SET_BUILDS,
  payload,
});

export const setBuildsSuccess = (payload: SetBuildsSuccessActionPayload): WhitelabelsActionTypes => ({
  type: SET_BUILDS_SUCCESS,
  payload,
});

export const setBuildsFailure = (payload: SetBuildsFailureActionPayload): WhitelabelsActionTypes => ({
  type: SET_BUILDS_FAILURE,
  payload,
});

export const getReleaseNotes = (): WhitelabelsActionTypes => ({
  type: GET_RELEASE_NOTES,
});

export const getReleaseNotesSuccess = (payload: string): WhitelabelsActionTypes => ({
  type: GET_RELEASE_NOTES_SUCCESS,
  payload,
});

export const changeAppsVersion = (payload: string): WhitelabelsActionTypes => ({
  type: CHANGE_APPS_VERSION,
  payload,
});

export const changeAppsVersionSuccess = (payload: ChangeAppsVersionSuccessActionPayload): WhitelabelsActionTypes => ({
  type: CHANGE_APPS_VERSION_SUCCESS,
  payload,
});

export const runWhitelabelBuild = (payload: RunWhitelabelBuildActionPayload): WhitelabelsActionTypes => ({
  type: RUN_WHITELABEL_BUILD,
  payload,
});

export const runWhitelabelBuildSuccess = (payload: RunWhitelabelBuildSuccessActionPayload): WhitelabelsActionTypes => ({
  type: RUN_WHITELABEL_BUILD_SUCCESS,
  payload,
});

export const setBuildLoading = (payload: SetBuildLoadingActionPayload): WhitelabelsActionTypes => ({
  type: SET_BUILD_LOADING,
  payload,
});

export const getConfig = (payload: GetConfigActionPayload): WhitelabelsActionTypes => ({
  type: GET_CONFIG,
  payload,
});

export const getConfigSuccess = (payload: GetConfigSuccessActionPayload): WhitelabelsActionTypes => ({
  type: GET_CONFIG_SUCCESS,
  payload,
});

export const updateConfig = (payload: UpdateConfigActionPayload): WhitelabelsActionTypes => ({
  type: UPDATE_CONFIG,
  payload,
});

export const updateConfigSuccess = (payload: UpdateConfigSuccessActionPayload): WhitelabelsActionTypes => ({
  type: UPDATE_CONFIG_SUCCESS,
  payload,
});

export const createConfig = (payload: CreateConfigActionPayload): WhitelabelsActionTypes => ({
  type: CREATE_CONFIG,
  payload,
});

export const createConfigSuccess = (payload: CreateConfigSuccessActionPayload): WhitelabelsActionTypes => ({
  type: CREATE_CONFIG_SUCCESS,
  payload,
});

export const deleteConfig = (payload: DeleteConfigActionPayload): WhitelabelsActionTypes => ({
  type: DELETE_CONFIG,
  payload,
});

export const deleteConfigSuccess = (payload: number): WhitelabelsActionTypes => ({
  type: DELETE_CONFIG_SUCCESS,
  payload,
});

export const createWhitelabel = (payload: CreateWhitelabelActionPayload): WhitelabelsActionTypes => ({
  type: CREATE_WHITELABEL,
  payload,
});

export const createWhitelabelSuccess = (payload: CreateWhitelabelSuccessActionPayload): WhitelabelsActionTypes => ({
  type: CREATE_WHITELABEL_SUCCESS,
  payload,
});

export const uploadConfigCertificate = (payload: UploadCertificatePayload): WhitelabelsActionTypes => ({
  type: UPLOAD_CONFIG_CERTIFICATE,
  payload,
});

export const updateWhitelabel = (payload: UpdateWhitelabelActionPayload): WhitelabelsActionTypes => ({
  type: UPDATE_WHITELABEL,
  payload,
});

export const deleteWhitelabel = (payload: DeleteWhitelabelActionPayload): WhitelabelsActionTypes => ({
  type: DELETE_WHITELABEL,
  payload,
});

export const updateWhitelabelSuccess = (payload: UpdateWhitelabelSuccessActionPayload): WhitelabelsActionTypes => ({
  type: UPDATE_WHITELABEL_SUCCESS,
  payload,
});

export const deleteWhitelabelSuccess = (payload: number): WhitelabelsActionTypes => ({
  type: DELETE_WHITELABEL_SUCCESS,
  payload,
});
