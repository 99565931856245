import { KeyboardEvent as ReactKeyboardEvent, useState } from 'react';

import BaseDialog from '../base/index';
import Button from '@material-ui/core/Button';
import { DEFAULT_VALIDATION_OPTIONS } from 'constants/ActionTypes';
import { INPUT_NAMES } from 'const';
import ReactHookFormError from 'models/ReactHookFormError';
import ValidationTextField from '../../validation-text-field';
import { handleInput } from 'util/input.utils';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface AddSectionFieldDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (key: string, value: string) => void;
}

const AddSectionFieldDialog = ({ open, handleSubmit, handleClose }: AddSectionFieldDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const {
    formState: { errors },
    control,
  } = useForm(DEFAULT_VALIDATION_OPTIONS);
  const isSaveEnabled = isEmpty(errors) && key && value;

  function handleKeyPress(event: ReactKeyboardEvent<HTMLInputElement>) {
    handleInput(event, INPUT_NAMES.KEY);
  }

  function handleSubmitButtonClick() {
    handleSubmit(key, value);
  }

  const children = (
    <div className="dialog-content">
      <ValidationTextField
        name="key"
        label={t('release-apps.config-info.labels.key')}
        required
        setValue={setKey}
        type="text"
        errors={errors as unknown as ReactHookFormError}
        onKeyPress={handleKeyPress}
        control={control}
        fullWidth
      />
      <ValidationTextField
        name="value"
        label={t('release-apps.config-info.labels.value')}
        required
        setValue={setValue}
        type="text"
        errors={errors as unknown as ReactHookFormError}
        control={control}
        fullWidth
      />
    </div>
  );

  const actions = [
    <Button key="cancel" variant="text" color="default" onClick={handleClose}>
      {t('buttons.cancel')}
    </Button>,
    <Button key="save" variant="contained" color="primary" onClick={handleSubmitButtonClick} disabled={!isSaveEnabled}>
      {t('buttons.save')}
    </Button>,
  ];
  const title = t('dialogs.addSectionField.title');
  const dialogProps = {
    title,
    actions,
    open,
    children,
    handleClose,
  };

  return <BaseDialog {...dialogProps} />;
};

export default AddSectionFieldDialog;
