import { CONTENT_TYPES, HTTP_METHODS } from '../../const';

import { AppCenterQueryData } from './AppCenterQueryData';
import config from '../../config';

export const sendRequest = (path: string, method: string, accessKey: string, body?: unknown): Promise<unknown> => {
  const queryData: AppCenterQueryData = {
    method,
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': CONTENT_TYPES.JSON,
      Accept: CONTENT_TYPES.JSON,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-API-Token': accessKey,
    },
    body: body ? JSON.stringify(body) : null,
  };

  return new Promise((resolve, reject) => {
    fetch(`${config.appcenterUrl}${path}`, queryData)
      .then((data) => data.json())
      .then((json) => {
        if (json.statusCode) {
          reject(json.code);
        }
        resolve(json);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

export const get = (path: string, accessKey: string): Promise<unknown> =>
  sendRequest(path, HTTP_METHODS.GET, accessKey);

export const put = (path: string, accessKey: string, data: unknown): Promise<unknown> =>
  sendRequest(path, HTTP_METHODS.PUT, accessKey, data);

export const post = (path: string, accessKey: string): Promise<unknown> =>
  sendRequest(path, HTTP_METHODS.POST, accessKey);

export const patch = (path: string, accessKey: string, data: unknown): Promise<unknown> =>
  sendRequest(path, HTTP_METHODS.PATCH, accessKey, data);
