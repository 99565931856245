import './builds-list.scss';

import { lowerCase, startCase } from 'lodash';

import AppCenterConfig from 'models/AppCenterConfig';
import Build from 'models/Build';
import { BuildStatuses } from 'const';
import Config from 'models/Config';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { MouseEvent as ReactMouseEvent } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { generateBuildLink } from 'services/appcenter/appcenter.service';
import moment from 'moment';

interface BuildsListProps {
  builds: Array<Build>;
  config: Config;
  platform: string;
  onDownloadBuildClick: (
    event: ReactMouseEvent<HTMLElement, MouseEvent> | KeyboardEvent,
    id: number,
    config: AppCenterConfig,
    platform: string
  ) => void;
}

export const isDownloadAllowed = (status: string): boolean => status === BuildStatuses.Succeeded;

// eslint-disable-next-line react/function-component-definition
function BuildsList({ builds, config, onDownloadBuildClick, platform }: BuildsListProps): JSX.Element {
  function handleDownloadBuildClick(item: Build, eventType: 'click' | 'press'): (event: unknown) => void {
    if (eventType === 'click') {
      return (event: ReactMouseEvent<HTMLElement, MouseEvent>) =>
        onDownloadBuildClick(event, item.id, config.build_data.appcenterConfig, platform);
    }
    if (eventType === 'press') {
      return (event: KeyboardEvent) => {
        const { key } = event;
        if (key === 'Enter') {
          onDownloadBuildClick(event, item.id, config.build_data.appcenterConfig, platform);
        }
      };
    }
    return () => {
      /* do nothing */
    };
  }

  return (
    <List className="builds-list">
      {Array.isArray(builds) &&
        builds.map((item) => (
          <div key={item.id}>
            <ListItem className="build-row" button key={item.id}>
              <div className="build-info">
                <div className="column">
                  <a
                    className="page-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={generateBuildLink(config.build_data.appcenterConfig, platform, item.id)}
                  >
                    {item.buildNumber}
                  </a>
                </div>
                <div className="column build-result">
                  {item.result ? item.result : lowerCase(startCase(item.status))}
                </div>
                <Tooltip
                  enterDelay={2000}
                  title={moment(item.finishTime).format('MMMM Do YYYY LT')}
                  placement="top"
                  arrow
                >
                  <div className="column">{moment(item.finishTime).format('ll LT')}</div>
                </Tooltip>
                <div className="download-build">
                  <i
                    className={`zmdi zmdi-download zmdi-hc-fw ${
                      isDownloadAllowed(item.result) ? '' : 'download-restricted'
                    }`}
                    aria-label="download-build"
                    role="button"
                    tabIndex={0}
                    onClick={handleDownloadBuildClick(item, 'click')}
                    onKeyPress={handleDownloadBuildClick(item, 'press')}
                  />
                </div>
              </div>
            </ListItem>
            <Divider light />
          </div>
        ))}
    </List>
  );
}

export default BuildsList;
