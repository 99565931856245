import './edit-release-dialog.scss';

import { WithTranslation, withTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

import BaseDialog from '../base';
import BuildSystemsDropDown from 'components/select-drop-down/build-systems';
import { Button } from '@material-ui/core';
import { DEFAULT_VALIDATION_OPTIONS } from 'constants/ActionTypes';
import GitBranchDropDown from 'components/select-drop-down/git-branch';

import ReactHookFormError from 'models/ReactHookFormError';
import ReleaseData from '../../../models/ReleaseData';
import UpdateReleaseData from '../../../models/ReleaseUpdateData';
import { UpdateReleaseRequest } from '../../../store/actions/types/Releases';
import ValidationTextField from 'components/validation-text-field';
import i18n from 'i18n';
import { updateRelease } from 'store/actions/Releases';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

export interface RenderReleaseEditorProps {
  open: boolean;
  release?: ReleaseData;
  closeDialog: () => void;
}

export interface EditReleaseDialogProps extends RenderReleaseEditorProps, WithTranslation {}

const EditReleaseDialog = ({ open, release, closeDialog }: EditReleaseDialogProps) => {
  const {
    formState: { errors },
    control,
  } = useForm(DEFAULT_VALIDATION_OPTIONS);
  const [notes, setNotes] = useState('');
  const [appVersion, setAppVersion] = useState('');
  const [buildSystem, setBuildSystem] = useState('');
  const [branch, setBranch] = useState('');
  const [commit, setCommit] = useState('');

  const updateNotes = useCallback((value: string) => setNotes(value.trim()), [setNotes]);
  const updateAppVersion = useCallback((value: string) => setAppVersion(value.trim()), [setAppVersion]);

  useEffect(() => {
    if (release) {
      setNotes(release.notes);
      setAppVersion(release.appVersion);
      setBuildSystem(release.buildSystem);
      setBranch(release.branch);
      setCommit(release.commit);
    }
  }, [release]);

  const dispatch = useDispatch();
  function onSubmit() {
    const userUUID = localStorage.getItem('user_uuid');

    // Actual UUID is set in the Saga, as that's the only place we can grab that bit of data
    const newReleaseDetails: UpdateReleaseData = {
      notes,
      appVersion,
      buildSystem,
      userUUID,
      branch,
      commit,
    };

    const request: UpdateReleaseRequest = {
      initiatedBy: '',
      initiatedByID: '',
      newReleaseDetails,
    };

    dispatch(
      updateRelease({
        releaseUUID: release.releaseUUID,
        request,
      })
    );

    closeDialog();
  }

  const children = (
    <div className="dialog-content">
      <ValidationTextField
        name="notes"
        label="Notes"
        required
        value={notes}
        setValue={updateNotes}
        type="text"
        errors={errors as unknown as ReactHookFormError}
        control={control}
        fullWidth
      />
      <ValidationTextField
        name="appVersion"
        label="App Version"
        required
        value={appVersion}
        setValue={updateAppVersion}
        type="text"
        errors={errors as unknown as ReactHookFormError}
        control={control}
        fullWidth
      />
      <div className="drop-down-group edit-release-options">
        <BuildSystemsDropDown updateStateCallback={setBuildSystem} initialValue={buildSystem} />
        <GitBranchDropDown
          updateStateCallback={setBranch}
          updateCommitOnChoiceCallback={setCommit}
          initialValue={branch}
        />
      </div>
    </div>
  );

  const actions = [
    <Button key="BUTTONS_KEYS.CANCEL" variant="text" color="default" onClick={closeDialog}>
      CANCEL
    </Button>,
    <Button key="BUTTONS_KEYS.SAVE_WITH_CONFIG" variant="contained" color="primary" onClick={onSubmit}>
      SAVE
    </Button>,
  ];

  return (
    <BaseDialog title={i18n.t('Update Release')} open={open} actions={actions}>
      {children}
    </BaseDialog>
  );
};

export default withTranslation()(EditReleaseDialog);
