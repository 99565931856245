import * as Sentry from '@sentry/react';

export const reportError = ({ message, error }: { message?: string; error?: Error }): void => {
  if (process.env.NODE_ENV === 'production') {
    if (!!message && !!error) {
      Sentry.addBreadcrumb({ message });
      Sentry.captureException(error);
    } else if (!!message) {
      Sentry.captureMessage(message);
    } else if (!!error) {
      Sentry.captureException(error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.error(message, error);
  }
};
