import './validation-text-field.scss';

import { ChangeEvent, KeyboardEvent, SetStateAction } from 'react';
import { Control, Controller } from 'react-hook-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { omit, pickBy } from 'lodash';

import ConfigSectionData from 'models/ConfigSectionData';
import MiscSectionDate from 'models/MiscSectiondata';
import ReactHookFormError from 'models/ReactHookFormError';
import TextField from '@material-ui/core/TextField';
import { Tooltip } from '@material-ui/core';
import { resolveTooltipText } from 'util/whitelabel-config.utils';

interface ValidationTextFieldProps extends WithTranslation {
  key?: number | string;
  name: string;
  setValue: (value: SetStateAction<string> | ConfigSectionData | MiscSectionDate) => void;
  errors: ReactHookFormError;
  control: Record<string, unknown>;
  type: string;
  value?: string;
  label?: string;
  fullWidth?: boolean;
  className?: string;
  autoFocus?: boolean;
  required?: boolean;
  onKeyPress?: ((event: KeyboardEvent<HTMLInputElement>) => void) | boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  rules?: Record<string, unknown>;
  lockable?: boolean;
  multiline?: boolean;
}

const ValidationTextField = ({
  key,
  name,
  setValue,
  errors,
  control,
  className = '',
  required = false,
  value = '',
  rules = {},
  t,
  lockable,
  ...attributes
}: ValidationTextFieldProps) => {
  const isFieldInvalid = Boolean(errors[name]);
  const textFieldAttributes = pickBy(omit(attributes, ['i18n', 'tReady']), castAttributeToBool);
  const textFieldClassName = `${className} validation-text-field${isFieldInvalid ? ' error' : ''}`.trim();
  const combinedRules = { ...rules, required: !required ? required : t('validators.requiredField') };

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);

    return event.target.value;
  }

  function castAttributeToBool(item: string): boolean {
    return Boolean(item);
  }

  function renderNoLockTextField() {
    return (
      <Tooltip title={resolveTooltipText(name)} arrow>
        <TextField
          error={isFieldInvalid}
          helperText={isFieldInvalid ? errors[name].message : ''}
          className={textFieldClassName}
          onChange={onChange}
          name={name}
          defaultValue={value || ''}
          {...textFieldAttributes}
          required={required}
        />
      </Tooltip>
    );
  }

  function renderCanLockTextField() {
    return (
      <Tooltip title={resolveTooltipText(name)} arrow>
        <TextField
          error={isFieldInvalid}
          value={value}
          helperText={isFieldInvalid ? errors[name].message : ''}
          className={textFieldClassName}
          onChange={onChange}
          {...textFieldAttributes}
        />
      </Tooltip>
    );
  }

  const noLock = (
    <Controller
      key={key}
      name={name}
      rules={combinedRules}
      control={control as Control<Record<string, unknown>>}
      render={renderNoLockTextField}
    />
  );

  const canLock = (
    <Controller
      key={key}
      name={name}
      rules={combinedRules}
      control={control as Control<Record<string, unknown>>}
      render={renderCanLockTextField}
    />
  );

  return lockable ? canLock : noLock;
};

export default withTranslation()(ValidationTextField);
