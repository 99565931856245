import './image-uploader.scss';

import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export interface DroppedFile extends File {
  preview: string;
}

interface ImageUploaderProps {
  setDroppedFile: (value: DroppedFile) => void;
}

// eslint-disable-next-line react/display-name
export default ({ setDroppedFile }: ImageUploaderProps): JSX.Element => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filesWithPreview = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
      setDroppedFile(filesWithPreview);
    },
    [setDroppedFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  return (
    <div className="dropzone-container" {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <span>{t('image-uploader.inactive-drag-tip')} ...</span>
      ) : (
        <span>{t('image-uploader.active-drag-tip')}</span>
      )}
    </div>
  );
};
