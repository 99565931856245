import { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

import { NotificationManager } from 'react-notifications';
import Select from './Select';
import Whitelabel from 'models/Whitelabel';
import WhitelabelOption from './WhitelabelOption';
import { getOmegaData } from 'util/release-management.utils';

interface WhitelabelDropDownProps {
  setWhitelabels: React.Dispatch<React.SetStateAction<Array<string>>>;
}

const WhitelabelDropDown = ({ setWhitelabels }: WhitelabelDropDownProps): JSX.Element => {
  const [options, setOptions] = useState<Array<WhitelabelOption>>([]);
  const [selectedOptions, setSelectedOptions] = useState<Set<string>>(new Set());

  const getWhitelabels = async () => {
    try {
      const whiteLabels = await getOmegaData<Array<Whitelabel>>('/v1/whitelabels');
      setOptions(
        whiteLabels
          .map(({ uuid, brand }) => ({
            label: brand,
            key: uuid,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    } catch (e) {
      NotificationManager.warning(e.message);
    }
  };

  useEffect(() => {
    getWhitelabels();
  }, []);

  // Called when selecting an item from the select dropdown
  function onChange(whitelabel: WhitelabelOption) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        selectedOptions.add(whitelabel.key);
      } else {
        selectedOptions.delete(whitelabel.key);
      }
      setWhitelabels(Array.from(selectedOptions));
      setSelectedOptions(new Set(selectedOptions));
    };
  }

  // Called when clicking on the checkbox
  function onCheck(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const allWhitelabelArray: Array<string> = options.map(({ key }) => key);
      setWhitelabels(allWhitelabelArray);
      setSelectedOptions(new Set(allWhitelabelArray));
    } else {
      setWhitelabels([]);
      setSelectedOptions(new Set());
    }
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const CheckBox = () => (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={selectedOptions.size === options.length} onChange={onCheck} color="primary" />}
        label="Select to use ALL whitelabels for this release"
      />
    </FormGroup>
  );

  return (
    <div>
      <CheckBox />
      <Select onChange={onChange} whitelabels={options} selected={selectedOptions} />
    </div>
  );
};

export default WhitelabelDropDown;
