import './release-apps.scss';

import { BASE_CONFIG_ID, BuildStatuses, DEFAULT_APP_VERSION, DOWNLOAD_TIME_OUT, PLATFORMS } from 'const';
import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react';
import ReleaseAppsToggleButton, { ViewType, ViewTypes } from 'components/toggle/ReleaseAppsToggle';
import { find, get, isEmpty } from 'lodash';
import { getBuildDownloadUrl, updateConfig } from 'services/appcenter/appcenter.service';
import i18next, { TFunction } from 'i18next';

import AddIcon from '@material-ui/icons/Add';
import AppCenterConfig from 'models/AppCenterConfig';
import BuildsConfig from 'models/BuildsConfig';
import Button from '@material-ui/core/Button';
import CardRelease from './card-release/CardRelease';
import CardTotalApps from './card-total-apps/CardTotalApps';
import Config from 'models/Config';
import Downloader from 'js-file-downloader';
import { NotificationManager } from 'react-notifications';
import Row from 'components/row';
import Whitelabel from 'models/Whitelabel';
import WhitelabelPreview from 'containers/WhitelabelPreview';
import WhitelabelsTable from 'components/tables/whitelabel-table';
import { getAppcenterVars } from 'util/whitelabel-config.utils';
import { storage } from 'services/local-storage.service';
import { useTranslation } from 'react-i18next';

export interface ReleaseAppsStoreProps {
  whitelabels: Array<Whitelabel>;
  configs: Array<Config>;
  releaseNotes: string;
}

type RunWhitelabelBuild = (payload: { id: number; platform: string }) => void;

export interface ReleaseAppsDispatchProps {
  runWhitelabelBuild: RunWhitelabelBuild;
  handleCreateWhitelabelModal: () => void;
}

export interface ReleaseAppsProps extends ReleaseAppsStoreProps, ReleaseAppsDispatchProps {}

export interface HandleDownloadBuildClickProps {
  event: ReactMouseEvent<HTMLElement, MouseEvent>;
  id: number;
  config: AppCenterConfig;
  platform: string;
  t: TFunction;
}

export const getConfigsVersion = (configs: Array<Config>): string => {
  const baseConfig = find(configs, (config) => config.id === BASE_CONFIG_ID);

  return get(baseConfig, 'build_data.appVersion', DEFAULT_APP_VERSION);
};

const handleDownloadBuildClick = (
  event: ReactMouseEvent<HTMLElement, MouseEvent>,
  id: number,
  config: AppCenterConfig,
  platform: string
) => {
  event.preventDefault();
  const name = config[`${platform}AppId`];

  getBuildDownloadUrl(config.owner, name, config.apiToken, id)
    .then((url: string) => {
      if (url) {
        const download = new Downloader({
          url,
          filename: `${name} - ${id}.zip`,
          timeout: DOWNLOAD_TIME_OUT,
          autoStart: false,
        });
        download
          .start()
          .then(() => {
            NotificationManager.success(i18next.t('release-apps.messages.build-download-success'));
          })
          .catch((error: string) => {
            NotificationManager.warning(i18next.t('release-apps.messages.build-download-error', { error }));
          });
      }
    })
    .catch((error: string) =>
      NotificationManager.warning(
        i18next.t('notifications.requestError', { requestName: 'getBuildDownloadUrl', error })
      )
    );
};

const handleSaveNotesError = (error: string, brand: string) =>
  NotificationManager.warning(
    i18next.t('release-apps.messages.release-notes-error', {
      error,
      brand,
    })
  );

const updateBuildsConfig = async (
  config: BuildsConfig,
  releaseNotes: string,
  owner: string,
  platform: string,
  appcenterKey: string
) => {
  if (get(config, 'toolsets.distribution', false)) {
    const configToUpdate = { ...config };
    configToUpdate.toolsets.distribution.releaseNotes = releaseNotes;

    await updateConfig(owner, platform, appcenterKey, configToUpdate);
  }
};

const saveReleaseNotes = (
  event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>,
  whitelabels: Array<Whitelabel>,
  configs: Array<Config>,
  releaseNotes: string
) => {
  event.preventDefault();
  Promise.all(
    whitelabels.map(async (whitelabel) => {
      if (!whitelabel.builds.data.error) {
        const config = configs.find(
          (item) => item.whitelabel_id === whitelabel.id && item.environment === 'production'
        );
        const { appcenterKey, owner, ios, android } = getAppcenterVars(config);

        const iosConfig = get(whitelabel, 'builds.data.iosConfig', {});
        const androidConfig = get(whitelabel, 'builds.data.androidConfig', {});

        try {
          await updateBuildsConfig(iosConfig, releaseNotes, owner, ios, appcenterKey);
          await updateBuildsConfig(androidConfig, releaseNotes, owner, android, appcenterKey);

          return Promise.resolve();
        } catch (error) {
          handleSaveNotesError(error, whitelabel.brand);
        }
      }

      return Promise.reject();
    })
  ).then((items) => {
    if (!isEmpty(items)) {
      NotificationManager.success(
        i18next.t('release-apps.messages.release-notes-success', {
          count: items.length,
        })
      );
    }
  });
};

const RUN_BUILD_REQUEST_DELAY = 10000;

const ReleaseApps = (props: ReleaseAppsProps): JSX.Element => {
  const [refreshAll, setRefreshAll] = useState<boolean>(false);
  const [viewFormat, setViewFormat] = useState<ViewType>(JSON.parse(storage.get('viewFormat')) || ViewTypes.Module);
  const [releaseNotesInputValue, setReleaseNotesInputValue] = useState<string>('');
  const { t } = useTranslation();
  const { whitelabels = [], configs, releaseNotes, handleCreateWhitelabelModal, runWhitelabelBuild } = props;
  const isFormReady = Boolean(whitelabels.length && configs && configs.length);

  function viewHandler(event: ReactMouseEvent<HTMLElement>, viewFormatValue: ViewType) {
    event.preventDefault();

    if (viewFormat) {
      storage.set('viewFormat', JSON.stringify(viewFormat));
      setViewFormat(viewFormatValue);
    }
  }

  const runBuildsWithDelay = (callback: (whitelabel: Whitelabel) => void) => {
    NotificationManager.success(
      i18next.t('release-apps.messages.builds-started', { count: whitelabels.length }),
      null,
      5000
    );

    whitelabels.forEach((whitelabel, index) => {
      setTimeout(() => {
        callback(whitelabel);
      }, RUN_BUILD_REQUEST_DELAY * index);
    });
  };

  const runAllWhitelabelBuilds = () => {
    runBuildsWithDelay((whitelabel) => {
      runWhitelabelBuild({ id: whitelabel.id, platform: PLATFORMS.ANDROID });
      runWhitelabelBuild({ id: whitelabel.id, platform: PLATFORMS.IOS });
    });
  };

  const runIosWhitelabelBuilds = () => {
    runBuildsWithDelay((whitelabel) => {
      runWhitelabelBuild({ id: whitelabel.id, platform: PLATFORMS.IOS });
    });
  };

  const runAndroidWhitelabelBuilds = () => {
    runBuildsWithDelay((whitelabel) => {
      runWhitelabelBuild({ id: whitelabel.id, platform: PLATFORMS.ANDROID });
    });
  };

  const runFailedWhitelabelBuild = (whitelabel: Whitelabel, platform: string) => {
    const status = get(whitelabel, `builds.data.${platform}Builds[0].result`, '');
    if (status === BuildStatuses.Failed) {
      runWhitelabelBuild({ id: whitelabel.id, platform });
    }
  };

  const runFailedWhitelabelBuilds = () => {
    runBuildsWithDelay((whitelabel) => {
      runFailedWhitelabelBuild(whitelabel, PLATFORMS.ANDROID);
      runFailedWhitelabelBuild(whitelabel, PLATFORMS.IOS);
    });
  };

  const totalWidgetProps = {
    currentVersion: getConfigsVersion(configs),
    totalCount: whitelabels.length,
    runAllWhitelabelBuilds: () => runAllWhitelabelBuilds(),
    runAndroidWhitelabelBuilds: () => runAndroidWhitelabelBuilds(),
    runFailedWhitelabelBuilds: () => runFailedWhitelabelBuilds(),
    runIosWhitelabelBuilds: () => runIosWhitelabelBuilds(),
    refreshHandler: () => setRefreshAll(!refreshAll),
  };

  const releaseWidgetProps = {
    releaseNotesInputValue,
    setNotesHandler: (notes: string) => setReleaseNotesInputValue(notes),
    saveNotesHandler: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
      // eslint-disable-next-line react/destructuring-assignment
      saveReleaseNotes(event, whitelabels, props.configs, releaseNotesInputValue);
    },
  };

  useEffect(() => {
    setReleaseNotesInputValue(releaseNotes);
  }, [releaseNotes]);

  return (
    <div className="release-apps app-wrapper animated slideInUpTiny animation-duration-3">
      {isFormReady && (
        <div>
          <Row>
            <div className="apps-head-row">
              <ReleaseAppsToggleButton viewHandler={viewHandler} value={viewFormat} />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateWhitelabelModal}
                className="add-whitelabel-button"
              >
                <AddIcon className="icon" />
                {t('release-apps.createWhitelabelButtonLabel')}
              </Button>
            </div>
          </Row>
          <Row>
            <div className="col-lg-5 col-md-6 col-12">
              <CardTotalApps {...totalWidgetProps} />
            </div>
            <div className="col-lg-7 col-md-6 col-12">
              <CardRelease {...releaseWidgetProps} />
            </div>
          </Row>
          <Row>
            <div
              className={`cards-wrapper animated slideInUpTiny animation-duration-3 ${
                viewFormat === ViewTypes.List ? 'hidden' : ''
              }`}
            >
              {whitelabels.map((whitelabel) => (
                <div className="col-lg-4 col-md-6 col-12" key={whitelabel.id}>
                  <WhitelabelPreview
                    uuid={whitelabel.uuid}
                    id={whitelabel.id}
                    braze_segment_id={whitelabel.braze_segment_id}
                    development_braze_segment_id={whitelabel.development_braze_segment_id}
                    handleClick={handleDownloadBuildClick}
                    refresh={refreshAll}
                  />
                </div>
              ))}
            </div>
            <WhitelabelsTable
              whitelabels={whitelabels}
              className={`${viewFormat === ViewTypes.Module ? 'hidden' : ''}`}
            />
          </Row>
        </div>
      )}
    </div>
  );
};

export default ReleaseApps;
