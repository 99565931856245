import './color-picker.scss';

import { ChromePicker } from 'react-color';

import OutsideClickHandler from 'react-outside-click-handler';
import { useState } from 'react';

interface ColorPickerProps {
  color: string;
  handleClose: () => void;
  setItemColor: (color: string) => void;
}

type cResult = {
  hex: string;
};

const ColorPicker = ({ color, handleClose, setItemColor }: ColorPickerProps): JSX.Element => {
  const [pickerColor, setPickerColor] = useState(color);

  function onChange(newColor: cResult) {
    setPickerColor(newColor.hex);
    setItemColor(newColor.hex);
  }

  return (
    <div className="color-picker-container">
      <OutsideClickHandler onOutsideClick={handleClose}>
        <ChromePicker color={pickerColor} onChange={onChange} />
      </OutsideClickHandler>
    </div>
  );
};

export default ColorPicker;
