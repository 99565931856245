import ImageDimension from 'models/ImageDimension';

export const ROUTES = {
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  DASHBOARD_MENU: '/dashboard/menu',
  RELEASE_APPS: '/dashboard/release-apps',
  RELEASE_MANAGEMENT: '/dashboard/release-management',
  RELEASE_CREATE: '/dashboard/release-management/create',
  APP_CONFIG: '/dashboard/config-info/:appId/config/:configId',
  MANAGE_WHITELABELS: '/dashboard/manage-whitelabels',
  APP_STATUS_REPORT: '/dashboard/app-status-report',
};

export const BASE_CONFIG_ID = 45;
export const HQO_WHITELABEL_ID = 3;
export const PROD_CONFIG_ENV = 'production';

export const DEFAULT_APP_VERSION = '0.0.0';

export const PLATFORM_BUILDS = {
  IOS_BULDS: 'iosBuilds',
  ANDROID_BUILDS: 'androidBuilds',
};

export const PLATFORMS = {
  IOS: 'ios',
  ANDROID: 'android',
  CIRCLE_CI: 'circleci',
};

export enum BuildStatuses {
  Failed = 'failed',
  Succeeded = 'succeeded',
  Warning = 'connection-failed',
  Loading = 'loading',
  NoBuildsFound = 'no-builds-found',
  InProgress = 'inProgress',
  Canceled = 'canceled',
}

export const DOWNLOAD_TIME_OUT = 1000000;

export const WHITELABELS = 'v1/whitelabels';
export const BUILD_WHITELABEL = 'v1/whitelabels/build';
export const WHITELABEL_CONFIGS = 'whitelabel-configs';

export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export const HTTP_METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const CONTENT_TYPES = {
  JSON: 'application/json',
  P12: 'application/x-pkcs12',
};

export const TABLE_DATE_TIME_FORMAT = 'MM/D/YYYY, h:mm:ss a';

export const IMAGE_DIMENSIONS: { [key: string]: ImageDimension } = {
  ICON_IMAGE: { width: 1024, height: 1024 },
  APP_ICON: { width: 1024, height: 1024 },
  NO_DIMENSION: { width: null, height: null },
};

export const APP_BRAND = 'WEB';
export const VERSION = 'application/x.api.v2+json';
export const HQO_VERSION = 'web';
export const HQO_UUID = 'htsn';
export const REQUEST_TYPE = 'XMLHttpRequest';

export const STATUS_CODES = {
  OK: 200,
  NO_CONTENT: 204,
  MULTIPLE_CHOICE: 300,
  FORBIDDEN: 403,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
  NETWORK_CONNECT_TIMEOUT_ERROR: 599,
};

export const CONFIG_ASSETS = {
  APP_ICON: 'app-icon.png',
  HEADER_LOGO: 'header-logo.png',
  PUSH_SAMPLE: 'push-sample.png',
  ANDROID_NOTIFICATION_ICON: 'android-push-notification-icon.png',
  LANDLORD_BRANDING: 'landlord-branding.png',
};

export const DEFAULT_IMAGE_URL = 'https://via.placeholder.com/512x512.png?text=No+image';

export const MAIN_SECTIONS_FIELDS: { [key: string]: string } = {
  VERSION: 'version',
  ENVIRONMENT: 'environment',
  ITUNES_URL: 'iTunesURL',
  APP_NAME: 'appName',
  APP_BRAND: 'appBrand',
  IOS_BUNDLE_NAME: 'iosBundleName',
  IOS_APP_KEY: 'iosAppKey',
  ANDROID_BUNDLE_NAME: 'androidBundleName',
  ANDROID_DISPLAY_NAME: 'androidDisplayName',
  API_DOMAIN: 'apiDomain',
  APPCENTER_CONFIG: 'appcenterConfig',
  ASSETS: 'assets',
  THEME: 'theme',
  PLIST: 'plist',
  UUID: 'uuid',
  IOS_CERTIFICATE: 'iosCertificate',
  ICON_IMAGE: 'iconImage',
};

export const MISC_SECTION_FIELDS: { [key: string]: string } = {
  ENGINE_API: 'engineApi',
  GOOGLE_SERVICES: 'googleServices',
  INCENTIVIO: 'incentivio',
  IOS_ENTITLEMENTS: 'iosEntitlements',
  POLICIES: 'policies',
  HEADER_IMAGE: 'headerImage',
  LAUNCH_IMAGE: 'launchImage',
  LAUNCH_BACKGROUND_COLOR: 'launchBackgroundColor',
  LIVE_SAFE_KEYS: 'livesafeKeys',
  SPREEDLY_PRODUCTION: 'spreedlyProduction',
  SPREEDLY_DEVELOPMENT: 'spreedlyDevelopment',
  SEGMENT_IOS_WRITE_KEY: 'segmentIOSWriteKey',
  SEGMENT_ANDROID_WRITE_KEY: 'segmentAndroidWriteKey',
  SEGMENT_ANDROID_WRITE_KEY_NATIVE: 'segmentAndroidWriteKeyNative',
  STRIPE_API_KEY: 'stripeApiKey',
  APP_VERSION: 'appVersion',
  LAYER_KEY: 'layerKey',
};

export const androidConfigExclusiveFields = [
  'segmentAndroidWriteKey',
  'androidAppId',
  'androidKeystore',
  'brazeAndroidApiKey',
  'brazeAndroidFireBaseSenderId',
  'fcmSenderId',
  'googleServices',
  'launchBackgroundColor',
  'DisableAndroidBackground',
  'ARTIFACTORY_URL',
];

export const iosConfigExclusiveFields = [
  'brazeApiKey',
  'CFBundleDisplayName',
  'CFBundleIdentifier',
  'CFBundleShortVersionString',
  'embraceIosKeyNative',
  'headerImage',
  'iosAppId',
  'launchImage',
  'NSCameraUsageDescription',
];

export const specialConfigFields = [
  'apiDomain',
  'apiToken',
  'appBrand',
  'appName',
  'cdnUrl',
  'developmentBaseBranch',
  'distributionBaseBranch',
  'imgUrl',
  'owner',
  'pushExampleBody',
  'pushExampleTitle',
  'zendesk',
  'spreedlyDevelopment',
  'spreedlyProduction',
  'CFBundleURLTypes',
  'UIBackgroundModes',
  'branch_key',
  'engineApi',
  'incentivio',
  'policies',
  'livesafeKeys',
  'iosEntitlements',
  'iosSection',
  'pendoKeys',
];

export const platformConfigExclusiveFields = androidConfigExclusiveFields.concat(iosConfigExclusiveFields);
export const generalConfigExcludeFields = platformConfigExclusiveFields.concat(specialConfigFields);

export const JSON_INPUT_THEME = 'light_mitsuketa_tribute';

export const CONFIG_DIALOG_DEFAULT_ENVIRONMENT = 'production';
export const CONFIG_DIALOG_DEFAULT_VERSION = '1.0.0';

export const PLIST_SECTION_FIELDS: { [key: string]: string } = {
  BRANCH_KEY: 'branch_key',
  CF_BUNDLE_URL_TYPES: 'CFBundleURLTypes',
  UI_BACKGROUND_MODES: 'UIBackgroundModes',
  ZENDESK: 'zendesk',
  SPREEDLY_PRODUCTION: 'spreedlyProduction',
  SPREEDLY_DEVELOPMENT: 'spreedlyDevelopment',
  SEGMENT_IOS_WRITE_KEY: 'segmentIOSWriteKey',
  SEGMENT_IOS_WRITE_KEY_NATIVE: 'segmentIosWriteKeyNative',
};

export const INPUT_NAMES = {
  KEY: 'key',
  VERSION: 'version',
};

export const DEFAULT_OPTION_ID = 'default';
export const SET_DEFAULT_OPTION_ID = 'set_default';
export const MASTER_OPTION_ID = 'master';
export const STAGING_OPTION_ID = 'staging';
export const PRE_PROD_OPTION_ID = 'pre_prod';
export const PRODUCTION_OPTION_ID = 'production';
export const US_REGION_OPTION_ID = 'us';
export const EU_REGION_OPTION_ID = 'eu';

export type Region = 'us' | 'eu';

export const STATUS_MESSAGES = {
  OK: 'ok',
};

export const FILE_DATA_KEYS = {
  CERTIFICATE: 'certificate',
  IMAGE: 'image',
};
