import './select-drop-down.scss';

import { ChangeEvent, useState } from 'react';
import { DEFAULT_OPTION_ID, SET_DEFAULT_OPTION_ID } from 'const';
import Spinner, { SpinnerStyle } from 'components/spinner';

import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Option from 'models/Option';
import Select from '@material-ui/core/Select';
import { isEmpty } from 'lodash';

interface SelectDropDownProps {
  defaultValue: string;
  defaultOptions?: Array<Option<string>>;
  options?: Array<Option<string>>;
  isDividerNeeded?: boolean;
  className?: string;
  initialValue?: string;
  disabled?: boolean;
  onChangeCallback?: React.Dispatch<React.SetStateAction<string>>;
}

function nop() {
  // do nothing.
}

const dropdownMaxHeight = () => {
  const HEADER_DROPDOWN_CAPACITY = 15;
  const ITEM_HEIGHT = 36;
  const LIST_PADDING = 8;

  return ITEM_HEIGHT * HEADER_DROPDOWN_CAPACITY + LIST_PADDING * 2;
};

const SelectDropDown = ({
  defaultOptions,
  options,
  defaultValue,
  isDividerNeeded,
  className,
  initialValue,
  onChangeCallback,
  disabled,
}: SelectDropDownProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<string>(initialValue || DEFAULT_OPTION_ID);

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: dropdownMaxHeight(),
      },
    },
  };

  function handleChange(event: ChangeEvent<{ value: string }>) {
    const optionId = event.target.value;
    const currentOption = defaultOptions.concat(options).find((option: Option<string>) => option.id === optionId);

    if (currentOption) {
      const value = optionId === SET_DEFAULT_OPTION_ID ? DEFAULT_OPTION_ID : optionId;
      setSelectedValue(value);
      currentOption.handleOptionClick(optionId);
      if (onChangeCallback !== null) {
        onChangeCallback(value);
      }
    }
  }

  const renderOptions = (optionsList: Array<Option<string>>) =>
    optionsList.map((option: Option<string>) => (
      <MenuItem key={option.label} value={option.id}>
        {option.label}
      </MenuItem>
    ));

  return (
    <FormControl variant="outlined">
      <Select
        disabled={disabled}
        value={selectedValue}
        onChange={handleChange}
        className={className}
        MenuProps={menuProps}
      >
        <MenuItem value={DEFAULT_OPTION_ID} hidden>
          {defaultValue}
        </MenuItem>
        {!isEmpty(defaultOptions) && renderOptions(defaultOptions)}
        {isDividerNeeded && <Divider />}
        {!isEmpty(options) ? (
          renderOptions(options)
        ) : (
          <div style={{ paddingTop: '6px' }}>
            <Spinner style={SpinnerStyle.SMALL} />
          </div>
        )}
      </Select>
    </FormControl>
  );
};

SelectDropDown.defaultProps = {
  defaultOptions: [],
  options: [],
  isDividerNeeded: false,
  className: '',
  onChangeCallback: nop,
  initialValue: '',
  disabled: false,
};

export default SelectDropDown;
