import {
  CLOSE_CREATE_CONFIG_MODAL,
  CLOSE_WHITELABEL_MODAL,
  CONFIRM_CANCEL_ALL_MODAL,
  CREATE_CONFIG_MODAL,
  CREATE_WHITELABEL_MODAL,
  DELETE_ITEM_MODAL,
  EDIT_WHITELABEL_MODAL,
} from 'constants/ActionTypes';

import { ModalsActionTypes } from '../actions/types/Modals';
import ModalsState from 'models/ModalsState';

const INITIAL_STATE: ModalsState = {
  createConfig: {
    open: false,
    whitelabelId: null,
    targetPageRoute: null,
  },
  whitelabel: {
    open: false,
    id: null,
    uuid: null,
    initialNameValue: null,
    initialBrandValue: null,
    initialDomainValue: null,
    initialBrazeSegmentIDValue: null,
    initialDevelopmentBrazeSegmentIDValue: null,
  },
  deleteItem: {
    id: null,
    uuid: null,
    actionType: null,
    open: false,
    name: null,
    targetPageRoute: null,
  },
  confirmCancelAll: {
    open: false,
  },
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action: ModalsActionTypes): ModalsState => {
  switch (action.type) {
    case CREATE_CONFIG_MODAL: {
      return {
        ...state,
        createConfig: {
          ...state.createConfig,
          whitelabelId: action.payload.whitelabelId ? action.payload.whitelabelId : state.createConfig.whitelabelId,
          open: !state.createConfig.open,
          targetPageRoute: action.payload.targetPageRoute ? action.payload.targetPageRoute : null,
        },
      };
    }
    case CLOSE_CREATE_CONFIG_MODAL: {
      return {
        ...state,
        createConfig: INITIAL_STATE.createConfig,
      };
    }
    case CREATE_WHITELABEL_MODAL: {
      return {
        ...state,
        whitelabel: {
          ...state.whitelabel,
          open: !state.whitelabel.open,
        },
      };
    }
    case EDIT_WHITELABEL_MODAL: {
      return {
        ...state,
        whitelabel: {
          ...state.whitelabel,
          ...action.payload,
          open: !state.whitelabel.open,
        },
      };
    }
    case CLOSE_WHITELABEL_MODAL: {
      return {
        ...state,
        whitelabel: INITIAL_STATE.whitelabel,
      };
    }
    case DELETE_ITEM_MODAL: {
      return {
        ...state,
        deleteItem: {
          ...state.deleteItem,
          ...action.payload,
          open: !state.deleteItem.open,
        },
      };
    }
    case CONFIRM_CANCEL_ALL_MODAL: {
      return {
        ...state,
        confirmCancelAll: {
          ...state.confirmCancelAll,
          open: !state.confirmCancelAll.open,
        },
      };
    }
    default:
      return state;
  }
};
