import { useEffect, useState } from 'react';

import { NotificationManager } from 'react-notifications';
import Option from 'models/Option';
import SelectDropDown from '../base/index';
import { getOmegaData } from '../../../util/release-management.utils';
import i18next from 'i18next';

function nop() {
  // do nothing.
}

interface BuildSystemsDropDownProps {
  updateStateCallback: React.Dispatch<React.SetStateAction<string>>;
  initialValue?: string;
}

const BuildSystemsDropDown = ({
  updateStateCallback: cb = null,
  initialValue,
}: BuildSystemsDropDownProps): JSX.Element => {
  const [options, setOptions] = useState([]);
  const defaultOptions: Array<Option<string>> = [];

  const getBuildSystems = async () => {
    try {
      const buildSystems = await getOmegaData<Array<string>>('/v1/build-systems');
      setOptions(
        buildSystems.map((name) => ({
          id: name,
          label: name,
          handleOptionClick: nop,
        }))
      );
    } catch (err) {
      NotificationManager.warning(err.message);
    }
  };

  useEffect(() => {
    getBuildSystems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectDropDown
      initialValue={initialValue}
      defaultOptions={defaultOptions}
      options={options}
      defaultValue={i18next.t('release-management.selects.buildSystem')}
      onChangeCallback={cb}
      className="select-drop-down"
    />
  );
};

BuildSystemsDropDown.defaultProps = {
  initialValue: '',
};

export default BuildSystemsDropDown;
