import { createWhitelabel, getWhitelabel, updateWhitelabel } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import AppState from 'models/AppState';
import BaseDialog from '../base/index';
import Button from '@material-ui/core/Button';
import { DEFAULT_VALIDATION_OPTIONS } from 'constants/ActionTypes';
import ReactHookFormError from 'models/ReactHookFormError';
import ValidationTextField from 'components/validation-text-field';
import { Whitelabel } from 'store/actions/types/Modals';
import { handleCloseWhitelabelModal } from 'store/actions/Modals';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface AddConfigDialogProps {
  open: boolean;
  id?: number;
  uuid?: string;
  initialNameValue?: string;
  initialBrandValue?: string;
  initialDomainValue?: string;
  initialBrazeSegmentIDValue?: string;
  initialDevelopmentBrazeSegmentIDValue?: string;
}

const BUTTONS_KEYS = {
  CANCEL: 'cancel',
  SAVE_WITH_CONFIG: 'save-with-config',
  SAVE: 'save',
};

const WhitelabelDialog = ({
  open,
  id,
  uuid,
  initialNameValue,
  initialBrandValue,
  initialDomainValue,
  initialBrazeSegmentIDValue,
  initialDevelopmentBrazeSegmentIDValue,
}: AddConfigDialogProps): JSX.Element => {
  const REQUIRED_FLAG = 1;
  const isEdit = Boolean(id);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [displayName, setDisplayName] = useState<string>();
  const [appBrand, setAppBrand] = useState<string>();
  const [domain, setDomain] = useState<string>();
  const [brazeSegmentID, setBrazeSegmentID] = useState<string>();
  const [developmentBrazeSegmentID, setDevelopmentBrazeSegmentID] = useState<string>();

  const {
    formState: { errors },
    control,
  } = useForm(DEFAULT_VALIDATION_OPTIONS);
  const nameFieldValue = displayName || initialNameValue;
  const brandFieldValue = appBrand || initialBrandValue;
  const isSaveEnabled = isEmpty(errors) && nameFieldValue && brandFieldValue;
  const whitelabels = useSelector((store: AppState) => store.whitelabels.items);

  const whitelabel: Whitelabel = {
    brand: nameFieldValue,
    is_whitelabel: REQUIRED_FLAG,
    domain,
    braze_segment_id: brazeSegmentID,
    development_braze_segment_id: developmentBrazeSegmentID,
  };

  useEffect(() => {
    setDisplayName(initialNameValue);
    setAppBrand(initialBrandValue);
    setDomain(initialDomainValue);
    setBrazeSegmentID(initialBrazeSegmentIDValue);
    setDevelopmentBrazeSegmentID(initialDevelopmentBrazeSegmentIDValue);
  }, [
    initialNameValue,
    initialBrandValue,
    initialDomainValue,
    initialBrazeSegmentIDValue,
    initialDevelopmentBrazeSegmentIDValue,
  ]);

  useEffect(() => {
    if (!open && (displayName || appBrand || domain || brazeSegmentID || developmentBrazeSegmentID)) {
      setInitialState();
    }
  }, [open, displayName, appBrand, domain, brazeSegmentID, developmentBrazeSegmentID]);

  const validateAppBrand = (appBrandValue: string) =>
    !whitelabels.find((item) => item.brand_identifier === appBrandValue) ||
    t('validators.uniqueField', { value: appBrandValue });

  const validateDomain = (domainValue: string) => {
    const filteredWhitelabels = isEdit ? whitelabels.filter((item) => item.id !== id) : whitelabels;

    return !filteredWhitelabels.find((item) => item.domain === domainValue) || !domainValue
      ? true
      : t('validators.uniqueField', { value: domainValue });
  };

  function setInitialState() {
    setDisplayName('');
    setAppBrand('');
    setDomain('');
    setBrazeSegmentID('');
    setDevelopmentBrazeSegmentID('');
  }

  function onCancel() {
    dispatch(handleCloseWhitelabelModal());
  }

  function onSubmit(isConfigDialogOpened: boolean) {
    return () => {
      if (isEdit) {
        dispatch(updateWhitelabel({ ...whitelabel, id, uuid }));
        dispatch(getWhitelabel({ uuid }));
      } else {
        dispatch(createWhitelabel({ ...whitelabel, brand_identifier: brandFieldValue, isConfigDialogOpened }));
      }
    };
  }

  function setFieldValue(fieldName: string) {
    return (value: string) => {
      // eslint-disable-next-line react/destructuring-assignment
      const trimmedValue = value.trim();
      switch (fieldName) {
        case 'displayName':
          return setDisplayName(trimmedValue);
        case 'appBrand':
          return setAppBrand(trimmedValue);
        case 'domain':
          return setDomain(trimmedValue);
        case 'brazeSegmentID':
          return setBrazeSegmentID(trimmedValue);
        case 'developmentBrazeSegmentID':
          return setDevelopmentBrazeSegmentID(trimmedValue);
        default:
          return null;
      }
    };
  }

  const createWhitelabelActions = [
    <Button key={BUTTONS_KEYS.CANCEL} variant="text" color="default" onClick={onCancel}>
      {t('buttons.cancel')}
    </Button>,
    <Button
      key={BUTTONS_KEYS.SAVE_WITH_CONFIG}
      variant="contained"
      color="primary"
      onClick={onSubmit(true)}
      disabled={!isSaveEnabled}
    >
      {t('buttons.save-n-create-config')}
    </Button>,
    <Button
      key={BUTTONS_KEYS.SAVE}
      variant="contained"
      color="primary"
      onClick={onSubmit(false)}
      disabled={!isSaveEnabled}
    >
      {t('buttons.save')}
    </Button>,
  ];

  const updateWhitelabelActions = createWhitelabelActions.filter((item) => item.key !== BUTTONS_KEYS.SAVE_WITH_CONFIG);

  return (
    <BaseDialog
      title={isEdit ? t('dialogs.editWhitelabelTitle') : t('dialogs.createWhitelabel.title')}
      open={open}
      actions={isEdit ? updateWhitelabelActions : createWhitelabelActions}
      handleClose={onCancel}
    >
      <div className="dialog-content">
        <ValidationTextField
          name="displayName"
          label={t('dialogs.createWhitelabel.fields.displayNameLabel')}
          required
          value={initialNameValue}
          setValue={setFieldValue('displayName')}
          type="text"
          errors={errors as unknown as ReactHookFormError}
          control={control}
          fullWidth
        />
        <ValidationTextField
          name="appBrand"
          label={t('dialogs.createWhitelabel.fields.appBrandLabel')}
          required
          value={initialBrandValue}
          setValue={setFieldValue('appBrand')}
          type="text"
          errors={errors as unknown as ReactHookFormError}
          control={control}
          disabled={isEdit}
          fullWidth
          rules={{ validate: validateAppBrand }}
        />
        <ValidationTextField
          name="domain"
          label={t('dialogs.createWhitelabel.fields.domainLabel')}
          value={initialDomainValue}
          setValue={setFieldValue('domain')}
          type="text"
          errors={errors as unknown as ReactHookFormError}
          control={control}
          fullWidth
          rules={{ validate: validateDomain }}
        />
        <ValidationTextField
          name="brazeSegmentID"
          label={t('dialogs.createWhitelabel.fields.brazeSegmentID')}
          value={initialBrazeSegmentIDValue}
          setValue={setFieldValue('brazeSegmentID')}
          type="text"
          errors={errors as unknown as ReactHookFormError}
          control={control}
          fullWidth
        />
        <ValidationTextField
          name="developmentBrazeSegmentID"
          label={t('dialogs.createWhitelabel.fields.developmentBrazeSegmentID')}
          value={initialDevelopmentBrazeSegmentIDValue}
          setValue={setFieldValue('developmentBrazeSegmentID')}
          type="text"
          errors={errors as unknown as ReactHookFormError}
          control={control}
          fullWidth
        />
      </div>
    </BaseDialog>
  );
};

WhitelabelDialog.defaultProps = {
  id: 0,
  uuid: '',
  initialNameValue: '',
  initialBrandValue: '',
  initialDomainValue: '',
  initialBrazeSegmentIDValue: '',
  initialDevelopmentBrazeSegmentIDValue: '',
};

export default WhitelabelDialog;
