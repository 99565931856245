import {
  CLOSE_CREATE_CONFIG_MODAL,
  CLOSE_WHITELABEL_MODAL,
  CONFIRM_CANCEL_ALL_MODAL,
  CREATE_CONFIG_MODAL,
  CREATE_WHITELABEL_MODAL,
  DELETE_ITEM_MODAL,
  EDIT_WHITELABEL_MODAL,
} from 'constants/ActionTypes';
import { HandleDeleteItemModalPayload, HandleEditWhitelabelModalPayload, ModalsActionTypes } from './types/Modals';

export const handleCreateWhitelabelModal = (): ModalsActionTypes => ({
  type: CREATE_WHITELABEL_MODAL,
});

export const handleEditWhitelabelModal = (payload: HandleEditWhitelabelModalPayload): ModalsActionTypes => ({
  type: EDIT_WHITELABEL_MODAL,
  payload,
});

export const handleCloseWhitelabelModal = (): ModalsActionTypes => ({
  type: CLOSE_WHITELABEL_MODAL,
});

export const handleCreateConfigModal = (whitelabelId: number, targetPageRoute?: string): ModalsActionTypes => ({
  type: CREATE_CONFIG_MODAL,
  payload: { whitelabelId, targetPageRoute },
});

export const handleCloseCreateConfigModal = (): ModalsActionTypes => ({
  type: CLOSE_CREATE_CONFIG_MODAL,
});

export const handleDeleteItemModal = (payload?: HandleDeleteItemModalPayload): ModalsActionTypes => ({
  type: DELETE_ITEM_MODAL,
  payload,
});

export const handleConfirmCancelAllModal = (): ModalsActionTypes => ({
  type: CONFIRM_CANCEL_ALL_MODAL,
});
