import FileCompressionOptions from 'models/FileCompressionOptions';
import { canvasToBlobPromise } from './canvas-to-blob-promise.utils';
import imageCompression from 'browser-image-compression';

const FILE_COMPRESSION_OPTIONS: FileCompressionOptions = {
  MAX_SIZE_MB: 1,
  MAX_ITERATION: 10,
};

export const compressFile = async (canvas: HTMLCanvasElement): Promise<File | Error> => {
  const options = {
    maxSizeMB: FILE_COMPRESSION_OPTIONS.MAX_SIZE_MB,
    useWebWorker: false,
    maxIteration: FILE_COMPRESSION_OPTIONS.MAX_ITERATION,
  };

  const canvasBlob = canvasToBlobPromise(canvas);

  return await canvasBlob.then((blob) => imageCompression(blob as File, options)).catch((error: Error) => error);
};
