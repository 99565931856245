import { Store, applyMiddleware, compose, createStore } from 'redux';

import createSagaMiddleware from 'redux-saga';
import { history } from './history';
import { logger } from 'redux-logger';
import reducers from './reducers';
import rootSaga from './sagas';
import { routerMiddleware } from 'connected-react-router';

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
// Enable redux-logger for development
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(): Store {
  const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)));

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../store/reducers/index', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export { history };
