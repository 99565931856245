import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const drawerWidth = 250;
const hoverBackgroundColor = 'rgba(173, 216, 230,0.4)';

export const useStyles = makeStyles((theme: Theme) => {
  const listItemStyles = {
    color: 'black',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:hover': {
      backgroundColor: hoverBackgroundColor,
      color: 'black',
      textDecoration: 'none',
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&.Mui-selected': {
      backgroundColor: hoverBackgroundColor,
      color: theme.palette.primary.main,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '&:hover': {
        backgroundColor: hoverBackgroundColor,
        textDecoration: 'none',
      },
    },
  };

  return createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    sideNavContentRoot: {
      width: '100%',
      maxWidth: 360,
    },
    listItem: listItemStyles,
    nestedItem: {
      ...listItemStyles,
      paddingLeft: theme.spacing(4),
    },
    selectedCreateDialog: {
      ...listItemStyles,
      paddingLeft: theme.spacing(4),
      color: theme.palette.primary.main,
    },
    selectedItemIcon: {
      fontSize: '1rem',
      marginRight: '0.25rem',
    },
    listItemIcon: {
      fontSize: '1.25rem',
      marginRight: '1rem',
    },
    navLink: {
      textDecoration: 'none',
    },
  });
});
