import { toggleCollapsedNav, updateWindowWidth } from '../../store/actions';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppState from 'models/AppState';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import SidenavContent from './SidenavContent';
import { UserInfo } from '../user-info/UserInfo';
import { useStyles } from './sideNavStyles';

const PERMANENT_DRAWER_MIN_SCREEN_WIDTH = 1200;

const SideNav = (): JSX.Element => {
  const { navCollapsed, width } = useSelector((store: AppState) => store.settings);
  const dispatch = useDispatch();
  const classes = useStyles({});
  const isTemporary = width < PERMANENT_DRAWER_MIN_SCREEN_WIDTH;

  const resizeHandler = useCallback(() => {
    dispatch(updateWindowWidth(window.innerWidth));
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  function onDrawerClose() {
    dispatch(toggleCollapsedNav(!navCollapsed));
  }

  return (
    <Drawer
      className={classes.drawer}
      variant={isTemporary ? 'temporary' : 'permanent'}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
      open={isTemporary ? navCollapsed : true}
      onClose={onDrawerClose}
    >
      <UserInfo />
      <Divider />
      <SidenavContent />
    </Drawer>
  );
};

export default SideNav;
