import './certificate-section.scss';

import Button from '@material-ui/core/Button';
import { ChangeEvent } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { uploadConfigCertificate } from 'store/actions/Whitelabels';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface CertificateSectionProps {
  configId: number;
  certificateUrl?: string;
}

const CertificateSection = ({ configId, certificateUrl }: CertificateSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let fileReader: FileReader;

  const handleFileRead = () => {
    const certificate = fileReader.result;
    dispatch(uploadConfigCertificate({ id: configId, certificate }));
  };
  function handleFileChosen(event: ChangeEvent<HTMLInputElement>): void {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsArrayBuffer(event.target.files[0]);
  }

  return (
    <div className="certificate-section-container">
      <div className="title">{t('release-apps.config-info.config-tab.labels.certificate')}</div>
      <div className="fields-container">
        <input
          accept=".p12"
          className="certificate-input"
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleFileChosen}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            size="large"
            component="span"
            className="button"
            startIcon={<CloudUploadIcon />}
          >
            {`${t('buttons.upload')}`}
          </Button>
        </label>
        {certificateUrl ? (
          <Button variant="outlined" size="large" className="button" href={certificateUrl}>
            {`${t('buttons.download')} .P12`}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

CertificateSection.defaultProps = {
  certificateUrl: '',
};

export default CertificateSection;
