/* eslint-disable react/sort-comp */
import './whitelabel-preview.scss';

import { BuildStatuses, DEFAULT_IMAGE_URL, PLATFORMS, PROD_CONFIG_ENV } from 'const';
import { ChangeEvent, Component, MouseEvent as ReactMouseEvent } from 'react';
import { HandleDeleteItemModalPayload, HandleEditWhitelabelModalPayload } from 'store/actions/types/Modals';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import { getBuildStatus, resolveBuildLabel } from 'util/build-status.utils';
import { isConfigValid, redirectToConfigPage, resolveDefaultConfig } from 'util/whitelabel-config.utils';

import AddIcon from '@material-ui/icons/Add';
import AndroidIcon from '@material-ui/icons/Android';
import AppCenterConfig from 'models/AppCenterConfig';
import AppleIcon from '@material-ui/icons/Apple';
import Avatar from '@material-ui/core/Avatar';
import BuildSpinner from 'components/spinner/build-spinner/BuildSpinner';
import BuildsData from 'models/BuildsData';
import BuildsList from './builds-list/BuildsList';
import Config from 'models/Config';
import { DELETE_WHITELABEL } from '../constants/ActionTypes';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Whitelabel from 'models/Whitelabel';
import WidgetCard from 'components/widget-card/WidgetCard';

export interface WhitelabelPreviewComponentProps {
  id: number;
  uuid: string;
  braze_segment_id: string;
  development_braze_segment_id: string;
  handleClick: (
    e: ReactMouseEvent<HTMLAnchorElement, MouseEvent>,
    id: number,
    config: AppCenterConfig,
    platform: string
  ) => void;
  refresh: boolean;
}

export interface WhitelabelPreviewStoreProps {
  whitelabel: Whitelabel;
  configs: Array<Config>;
}

export interface WhitelabelPreviewDispatchProps {
  setBuilds: (payload: { id: number; config: Config }) => void;
  setBuildsFailure: (payload: { id: number }) => void;
  runWhitelabelBuild: (payload: { platform: string; id: number }) => void;
  handleCreateConfigModal: (whitelabelId: number, targetPageRoute?: string) => void;
  handleEditWhitelabelModal: (payload: HandleEditWhitelabelModalPayload) => void;
  handleDeleteItemModal: (payload?: HandleDeleteItemModalPayload) => void;
}

export interface WhitelabelPreviewState {
  expanded: boolean | null | string;
}

export interface WhitelabelPreviewProps
  extends WhitelabelPreviewComponentProps,
    WhitelabelPreviewStoreProps,
    WhitelabelPreviewDispatchProps,
    RouteComponentProps,
    WithTranslation {}

export class WhitealbelPreview extends Component<WhitelabelPreviewProps, WhitelabelPreviewState> {
  constructor(props: WhitelabelPreviewProps) {
    super(props);
    this.state = {
      expanded: null,
    };
  }

  componentDidMount(): void {
    const { setBuilds, whitelabel, id, setBuildsFailure } = this.props;
    const { builds } = whitelabel;

    if (isEmpty(builds?.data) && this.defaultConfig) {
      setBuilds({ id, config: this.defaultConfig });
    } else {
      setBuildsFailure({ id });
    }
  }

  componentDidUpdate(prevProps: WhitelabelPreviewComponentProps): void {
    const { id, refresh, configs } = this.props;

    if (refresh !== prevProps.refresh) {
      this.updateBuilds(id, configs);
    }
  }

  getDefaultConfig(): Config | null {
    const { configs } = this.props;
    return resolveDefaultConfig(configs);
  }

  getASCLink = (configs: Array<Config> = []): string => {
    const productionConfig = configs.find((config) => config.environment === PROD_CONFIG_ENV);
    return productionConfig ? productionConfig.asc_link : configs[0].asc_link;
  };

  handleChange =
    (panel: string) =>
    (event: ChangeEvent<HTMLInputElement>, expanded: boolean | null | string): void => {
      event.preventDefault();

      this.setState({
        expanded: expanded ? panel : false,
      });
    };

  getGPCLink = (configs: Array<Config>): string => {
    const productionConfig = configs.find((config) => config.environment === PROD_CONFIG_ENV);
    return productionConfig ? productionConfig.gpc_link : configs[0].gpc_link;
  };

  defaultConfig = this.getDefaultConfig();

  isBuildsLoading = (status: string, loading: boolean): boolean => loading || status === BuildStatuses.Loading;

  isPanelExpanded = (platform: string): boolean => {
    const { expanded } = this.state;
    return expanded === platform;
  };

  handleDeleteWhitelabel = (): void => {
    const { id, whitelabel, handleDeleteItemModal, t, uuid } = this.props;
    handleDeleteItemModal({
      id,
      uuid,
      actionType: DELETE_WHITELABEL,
      name: t('dialogs.deleteItem.whitelabel-name', { name: whitelabel.brand }),
    });
  };

  refreshHandler = (): void => {
    const { whitelabel, id, configs } = this.props;
    const { builds } = whitelabel;

    if (!builds.loading.ios || !builds.loading.android) {
      this.updateBuilds(id, configs);
    }
  };

  render(): JSX.Element {
    const {
      whitelabel,
      runWhitelabelBuild,
      handleClick,
      id,
      uuid,
      braze_segment_id,
      development_braze_segment_id,
      handleCreateConfigModal,
      handleEditWhitelabelModal,
      history,
      configs,
      t,
    } = this.props;
    const { brand, brand_identifier, domain } = whitelabel;
    const buildsData = get(whitelabel, 'builds.data', {});
    const androidLoading = get(whitelabel, 'builds.loading.android', false);
    const iosLoading = get(whitelabel, 'builds.loading.ios', false);
    const androidBuildStatus = resolveBuildLabel(buildsData, PLATFORMS.ANDROID);
    const iosBuildStatus = resolveBuildLabel(buildsData, PLATFORMS.IOS);
    const androidBuildClass = this.resolveBuildClass(buildsData, PLATFORMS.ANDROID);
    const iosBuildClass = this.resolveBuildClass(buildsData, PLATFORMS.IOS);
    const isAndroidPanelExpanded = this.isPanelExpanded(PLATFORMS.ANDROID);
    const isIosPanelExpanded = this.isPanelExpanded(PLATFORMS.IOS);
    const isAppCenterErrorDisplayed = !!configs?.find((config) => !isConfigValid(config));
    const isExpandeIosBuildsDisabled =
      isEmpty(buildsData.iosBuilds) || this.isBuildsLoading(iosBuildStatus, iosLoading);
    const isExpandeAndroidBuildsDisabled =
      isEmpty(buildsData.androidBuilds) || this.isBuildsLoading(androidBuildStatus, androidLoading);
    const ascLink = Array.isArray(configs) && !isEmpty(configs) ? this.getASCLink(configs) : null;
    const gpcLink = Array.isArray(configs) && !isEmpty(configs) ? this.getGPCLink(configs) : null;

    function openEditWhitelabelModal() {
      handleEditWhitelabelModal({
        id,
        uuid,
        initialNameValue: brand,
        initialBrandValue: brand_identifier,
        initialDomainValue: domain,
        initialBrazeSegmentIDValue: braze_segment_id,
        initialDevelopmentBrazeSegmentIDValue: development_braze_segment_id,
      });
    }

    function handleRunWhitelabelBuildClick(platform: string, core?: number) {
      return () => runWhitelabelBuild({ id: core ?? id, platform });
    }

    function openCreateWhitelabelModal() {
      handleCreateConfigModal(id);
    }

    function handleRedirectToConfigPage(config: Config, eventType: 'click' | 'press'): (event: unknown) => void {
      const payload = { history, appId: whitelabel.id, configId: config.id };
      if (eventType === 'click') {
        return () => redirectToConfigPage(payload);
      }
      if (eventType === 'press') {
        return (event: KeyboardEvent) => {
          const { key } = event;
          if (key === 'Enter') {
            return redirectToConfigPage(payload);
          }
          return null;
        };
      }
      return () => {
        /* do nothing */
      };
    }

    return (
      <WidgetCard styleName="jr-card-metrics p-4">
        <div className="whitelabel-card">
          <div className="whitelabel-header main">
            <h2>{brand}</h2>
            <div className="small-solid-buttons">
              <Tooltip title={t('release-apps.whitelabel.options.edit')} placement="top" arrow>
                <IconButton onClick={openEditWhitelabelModal} size="small">
                  <EditIcon className="icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('release-apps.whitelabel.options.refresh')} placement="top" arrow>
                <IconButton onClick={this.refreshHandler} size="small" disabled={iosLoading || androidLoading}>
                  <RefreshIcon className="icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('release-apps.whitelabel.options.delete')} placement="top" arrow>
                <IconButton onClick={this.handleDeleteWhitelabel} size="small">
                  <DeleteIcon className="icon" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="whitelabel-header main">
            {ascLink && (
              <Link href={ascLink} target="_blank" rel="noreferrer">
                {t('release-apps.whitelabel.ascLink')}
              </Link>
            )}
            {gpcLink && (
              <Link href={gpcLink} target="_blank" rel="noreferrer">
                {t('release-apps.whitelabel.gpcLink')}
              </Link>
            )}
          </div>
          <div className="whitelabel-header main">
            {ascLink && <Link href={ascLink} data-cy={`asc-${brand}`} rel="noreferrer" />}
            {gpcLink && <Link href={gpcLink} data-cy={`gpc-${brand}`} rel="noreferrer" />}
          </div>
          <div className="whitelabel-header">
            {brand === 'HqO' ? (
              <h3 style={{ display: 'flex', alignItems: 'center' }}>{t('release-apps.whitelabel.buildHeader')} :</h3>
            ) : (
              <h3>{t('release-apps.whitelabel.buildHeader')} :</h3>
            )}
            <div className="small-solid-buttons" style={brand === 'HqO' ? { marginBottom: '12px' } : {}}>
              <Tooltip title={t('release-apps.whitelabel.options.buildIos')} placement="top" arrow>
                <IconButton
                  onClick={handleRunWhitelabelBuildClick(PLATFORMS.IOS)}
                  size="small"
                  className="icon-button"
                  disabled={iosLoading}
                >
                  <AppleIcon className="icon" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('release-apps.whitelabel.options.buildAndroid')} placement="top" arrow>
                <IconButton
                  onClick={handleRunWhitelabelBuildClick(PLATFORMS.ANDROID)}
                  size="small"
                  className="icon-button"
                  disabled={androidLoading}
                >
                  <AndroidIcon className="icon" />
                </IconButton>
              </Tooltip>
              {brand === 'HqO' && (
                <>
                  <IconButton
                    onClick={handleRunWhitelabelBuildClick(PLATFORMS.CIRCLE_CI, 1)}
                    size="small"
                    className="icon-button"
                  >
                    <div style={{ fontSize: '10px' }}>
                      Build HqO Dev w/
                      <br />
                      CircleCi
                    </div>
                  </IconButton>
                  <IconButton
                    onClick={handleRunWhitelabelBuildClick(PLATFORMS.CIRCLE_CI, 2)}
                    size="small"
                    className="icon-button"
                  >
                    <div style={{ fontSize: '10px' }}>
                      Build HqO Prod w/
                      <br />
                      CircleCi
                    </div>
                  </IconButton>
                </>
              )}
            </div>
          </div>
          <ExpansionPanel expanded={isIosPanelExpanded} onChange={this.handleChange(PLATFORMS.IOS)}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="platform-heading">{t('release-apps.whitelabel.ios')}</Typography>
              <Typography className={iosBuildClass}>{this.renderBuildStatus(iosBuildStatus, iosLoading)}</Typography>
            </ExpansionPanelSummary>
            {!isExpandeIosBuildsDisabled && (
              <ExpansionPanelDetails className="expansion-panel-details">
                <Typography component="span">
                  {t('release-apps.whitelabel.latestBuilds')}
                  <br />
                  {isIosPanelExpanded && (
                    <BuildsList
                      builds={buildsData.iosBuilds || []}
                      platform={PLATFORMS.IOS}
                      config={this.defaultConfig}
                      onDownloadBuildClick={handleClick}
                    />
                  )}
                </Typography>
              </ExpansionPanelDetails>
            )}
          </ExpansionPanel>
          <ExpansionPanel expanded={isAndroidPanelExpanded} onChange={this.handleChange(PLATFORMS.ANDROID)}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="platform-heading">{t('release-apps.whitelabel.android')}</Typography>
              <Typography className={androidBuildClass}>
                {this.renderBuildStatus(androidBuildStatus, androidLoading)}
              </Typography>
            </ExpansionPanelSummary>
            {!isExpandeAndroidBuildsDisabled && (
              <ExpansionPanelDetails className="expansion-panel-details">
                <Typography component="span">
                  {t('release-apps.whitelabel.latestBuilds')}
                  <br />
                  {isAndroidPanelExpanded && (
                    <BuildsList
                      builds={buildsData.androidBuilds || []}
                      platform={PLATFORMS.ANDROID}
                      config={this.defaultConfig}
                      onDownloadBuildClick={handleClick}
                    />
                  )}
                </Typography>
              </ExpansionPanelDetails>
            )}
          </ExpansionPanel>
          <br />
          <div className="config-header">
            <div className="small-solid-buttons title">
              <h3 className="config-title">{`${t('release-apps.whitelabel.configsHeader')}`}</h3>
              <Tooltip title={t('release-apps.whitelabel.createConfigHeader')} placement="top" arrow>
                <IconButton onClick={openCreateWhitelabelModal} className="icon-button" size="small">
                  <AddIcon className="icon" />
                </IconButton>
              </Tooltip>
            </div>
            {configs && isAppCenterErrorDisplayed && (
              <span className="warning">{t('release-apps.whitelabel.config-misconfigurations')}</span>
            )}
          </div>
          <div className="configs-section">
            {(configs || []).map((config) => (
              <div
                className="config-item"
                key={config.id}
                role="button"
                tabIndex={0}
                onClick={handleRedirectToConfigPage(config, 'click')}
                onKeyPress={handleRedirectToConfigPage(config, 'press')}
              >
                <Avatar className="config-icon" src={get(config, 'build_data.iconImage', DEFAULT_IMAGE_URL)} />
                <div className={`config-info ${isConfigValid(config) ? '' : 'warning'}`}>
                  <div>{get(config, 'build_data.appName', 'Unknown')}</div>
                  <div>{config.environment || 'Unknown'}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </WidgetCard>
    );
  }

  resolveBuildClass = (builds: BuildsData, platform: string): 'warning' | 'failed' | 'succeeded' | '' => {
    const status = getBuildStatus(builds, platform);

    switch (status) {
      case BuildStatuses.Warning:
        return 'warning';
      case BuildStatuses.Failed:
        return 'failed';
      case BuildStatuses.Succeeded:
        return 'succeeded';
      default:
        return '';
    }
  };

  renderBuildStatus = (status: string, loading: boolean): JSX.Element | string =>
    this.isBuildsLoading(status, loading) ? <BuildSpinner /> : status;

  updateBuilds(id: number, configs: Array<Config>): void {
    const { setBuildsFailure, setBuilds } = this.props;
    if (isEmpty(configs)) {
      setBuildsFailure({ id });
    } else {
      setBuilds({ id, config: this.defaultConfig });
    }
  }
}

export default withTranslation()(withRouter(WhitealbelPreview));
