import './card-total-apps.scss';

import { WithTranslation, withTranslation } from 'react-i18next';
import { changeAppsVersion, handleConfirmCancelAllModal } from '../../store/actions';

import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import InputInlineEdit from '../../components/input-inline-edit/InputInlineEdit';
import WidgetCard from '../../components/widget-card/WidgetCard';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

interface CardTotalAppsProps extends WithTranslation {
  totalCount: number;
  currentVersion: string;
  runAllWhitelabelBuilds: () => void;
  runAndroidWhitelabelBuilds: () => void;
  runIosWhitelabelBuilds: () => void;
  runFailedWhitelabelBuilds: () => void;
  refreshHandler: () => void;
}

const DEFAULT_VERSION = '0.0.0';

const CardTotalApps = ({
  totalCount,
  currentVersion,
  runAllWhitelabelBuilds,
  runAndroidWhitelabelBuilds,
  runFailedWhitelabelBuilds,
  runIosWhitelabelBuilds,
  refreshHandler,
  t,
}: CardTotalAppsProps) => {
  const dispatch = useDispatch();
  const [isEdit, setEditMode] = useState<boolean>(false);
  const [appsVersion, setAppsVersion] = useState<string>('');

  async function saveAppsVersion() {
    const version = isEmpty(appsVersion) ? DEFAULT_VERSION : appsVersion;
    dispatch(changeAppsVersion(version));
    setEditMode(false);
  }

  function changeEditMode(isEditMode: boolean) {
    return () => setEditMode(isEditMode);
  }

  function cancelAllModal() {
    dispatch(handleConfirmCancelAllModal());
  }

  return (
    <WidgetCard styleName="total-apps-card">
      <div className="header-section">
        <div className="apps-count">
          {t('release-apps.card-total.total-apps-count')}:{totalCount}
        </div>
        <div className="current-version-container">
          <div>{t('release-apps.card-total.current-version')}:</div>
          {isEdit ? (
            <InputInlineEdit
              defaultValue={currentVersion}
              handleChange={setAppsVersion}
              handleClose={setEditMode}
              handleSave={saveAppsVersion}
            />
          ) : (
            <div className="current-version">
              <span>{currentVersion}</span>
              <IconButton onClick={changeEditMode(true)} size="small" className="icon-button-edit remove-outline">
                <EditIcon className="icon" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      <div className="actions-section">
        <Button variant="contained" color="primary" onClick={runAllWhitelabelBuilds}>
          {t('release-apps.card-total.build-all')}
        </Button>
        <Button variant="contained" color="primary" onClick={runIosWhitelabelBuilds}>
          {t('release-apps.card-total.build-ios')}
        </Button>
        <Button variant="contained" color="primary" onClick={runAndroidWhitelabelBuilds}>
          {t('release-apps.card-total.build-android')}
        </Button>
        <Button variant="contained" color="primary" onClick={refreshHandler}>
          {t('release-apps.card-total.refresh')}
        </Button>
        <Button variant="contained" color="secondary" onClick={runFailedWhitelabelBuilds}>
          {t('release-apps.card-total.build-failed')}
        </Button>
        <Button variant="contained" color="secondary" onClick={cancelAllModal}>
          {t('release-apps.card-total.cancel-all')}
        </Button>
      </div>
    </WidgetCard>
  );
};

export default withTranslation()(CardTotalApps);
