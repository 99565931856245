import './config-info.scss';

import { ChangeEvent, cloneElement, useCallback, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { getConfig, updateConfig } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';

import AppState from 'models/AppState';
import { CONFIG_ASSETS } from 'const';
import Config from 'models/Config';
import ConfigTab from './config-tab/index';
import JsonTab from './json-tab/JsonTab';
import { RouteComponentProps } from 'react-router-dom';
import StaticsTab from './statics-tab/index';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { isCustomConfigUtil } from 'util/whitelabel-config.utils';
import { omit } from 'lodash';
import { selectConfigById } from 'store/selectors/whitelabel-config-selector';

export type Params = {
  appId: string;
  configId: string;
};

interface TabPanelProps {
  children: JSX.Element;
  config: Config;
}

const DEFAULT_TAB_INDEX = 0;
const JSON_TAB_ID = 2;

const TabPanel = (props: TabPanelProps) => {
  const { children, config } = props;
  return cloneElement(children, { config });
};

export interface ConfigInfoProps extends RouteComponentProps<Params>, WithTranslation {}

const ConfigInfo = ({ match, t }: ConfigInfoProps) => {
  const config = useSelector<AppState, Config>((state) =>
    selectConfigById(state as AppState, parseInt(match.params.configId, 10))
  );
  let dynamicSave = config;
  let imagesHash = config
    ? {
        ...omit(config.build_data.assets, CONFIG_ASSETS.PUSH_SAMPLE),
        headerImage: config.build_data.headerImage,
        launchImage: config.build_data.launchImage,
      }
    : {};
  let staticSave: Record<string, string> = config
    ? {
        apiDomain: config.build_data.apiDomain,
        apiToken: config.build_data.appcenterConfig.apiToken,
        appBrand: config.build_data.appBrand,
        appName: config.build_data.appName,
        cdnUrl: config.build_data.cdnUrl,
        developmentBaseBranch: config.build_data.appcenterConfig.developmentBaseBranch,
        distributionBaseBranch: config.build_data.appcenterConfig.distributionBaseBranch,
        imgUrl: config.build_data.imgUrl,
        owner: config.build_data.appcenterConfig.owner,
        pushExampleBody: config.build_data.pushExampleBody,
        pushExampleTitle: config.build_data.pushExampleTitle,
      }
    : {
        apiDomain: '',
        apiToken: '',
        appBrand: '',
        appName: '',
        cdnUrl: '',
        developmentBaseBranch: '',
        distributionBaseBranch: '',
        imgUrl: '',
        owner: '',
        pushExampleBody: '',
        pushExampleTitle: '',
      };
  const [value, setValue] = useState(DEFAULT_TAB_INDEX);
  const [loading, setLoading] = useState<boolean>(true);

  const dispatch = useDispatch();

  const dispatchSave = (c: Config) => {
    dispatch(updateConfig({ config: c, imagesHash }));
  };

  const handleChildSave = () => {
    const newConfig = {
      ...dynamicSave,
      build_data: {
        ...dynamicSave.build_data,
        apiDomain: staticSave.apiDomain,
        appBrand: staticSave.appBrand,
        appName: staticSave.appName,
        cdnUrl: staticSave.cdnUrl,
        imgUrl: staticSave.imgUrl,
        pushExampleBody: staticSave.pushExampleBody,
        pushExampleTitle: staticSave.pushExampleTitle,
        appcenterConfig: {
          ...dynamicSave.build_data.appcenterConfig,
          apiToken: staticSave.apiToken,
          developmentBaseBranch: staticSave.developmentBaseBranch,
          distributionBaseBranch: staticSave.distributionBaseBranch,
          owner: staticSave.owner,
        },
        plist: {
          ...dynamicSave.build_data.plist,
          branch_app_domain: dynamicSave.build_data.plist.branch_app_domain,
        },
      },
    };
    dispatchSave(newConfig);
  };

  function handleUpdate(type: 'dynamic' | 'image' | 'static') {
    return (valueToSave: unknown) => {
      switch (type) {
        case 'dynamic':
          dynamicSave = valueToSave as Config;
          break;
        case 'image':
          imagesHash = valueToSave;
          break;
        case 'static':
          staticSave = valueToSave as Record<string, string>;
          break;
        default:
          break;
      }
      handleChildSave();
    };
  }

  const getConfigItem = useCallback(() => {
    if (!config) {
      dispatch(getConfig({ id: Number(match.params.configId) }));
    }
    setLoading(false);
  }, [config, dispatch, match.params.configId]);

  useEffect(() => {
    setLoading(true);
    getConfigItem();
  }, [getConfigItem]);

  useEffect(() => {
    document.getElementsByClassName('app-main-content-wrapper')[0].scrollTo(0, 0);
  }, []);

  const components = [
    {
      id: 1,
      label: t('release-apps.config-info.tabs.config'),
      element: (
        <ConfigTab
          config={config}
          whitelabelId={Number(match.params.appId)}
          updateParent={handleUpdate('dynamic')}
          updateImagesHash={handleUpdate('image')}
        />
      ),
    },
    {
      id: 2,
      label: t('release-apps.config-info.tabs.static'),
      element: <StaticsTab config={config} updateParent={handleUpdate('static')} />,
    },
    {
      id: 3,
      label: t('release-apps.config-info.tabs.json'),
      element: <JsonTab config={config} />,
    },
  ];

  function handleChange(_event: ChangeEvent<unknown>, newValue: number) {
    setValue(newValue);
  }

  const resolveName = (name: string) => name || '';

  const isTabVisible = (item: Config, id: number) => {
    if (isCustomConfigUtil(item) && id === JSON_TAB_ID) {
      return { display: 'none' };
    }
    return null;
  };

  const isConfigLoaded = Boolean(!loading && config);
  const isNoConfig = Boolean(!loading && !config);

  return (
    <div className="config-page-container">
      {isConfigLoaded && (
        <>
          <div className="config-header">
            {`${resolveName(config.build_data.appName)} ${resolveName(config.environment)} ${t(
              'release-apps.config-info.config-header'
            )}`}
          </div>
          <Tabs className="tabs-labels" value={value} onChange={handleChange}>
            {components.map((component) => (
              <Tab key={`tab-${component.label}`} label={component.label} style={isTabVisible(config, component.id)} />
            ))}
          </Tabs>
          <div className="tabs-wrapper">
            {components.map((component, index) => (
              <div key={`panel-${component.label}`}>
                {value === index && <TabPanel config={config}>{component.element}</TabPanel>}
              </div>
            ))}
          </div>
        </>
      )}
      {isNoConfig && <div className="config-header">{t('release-apps.config-info.no-config-header')}</div>}
    </div>
  );
};

export default withTranslation()(ConfigInfo);
