import 'cropperjs/dist/cropper.css';
import './image-cropper.scss';

import { Button } from '@material-ui/core';
import CroppedImageOptions from '../../models/CroppedImageOptions';
import Cropper from 'react-cropper';
import CropperOptions from '../../models/CropperOptions';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface ImageCropperProps {
  previewPath: string;
  croppedImage: HTMLCanvasElement;
  imageDimension: {
    height: number;
    width: number;
  };
  setCroppedImage: (value: HTMLCanvasElement) => void;
  fillColor?: string;
}

const CROPPER_OPTIONS: CropperOptions = {
  VIEW_MODE: 1,
  MIN_CROP_BOX_WIDTH: 100,
  MIN_CROP_BOX_HEIGHT: 100,
  DRAG_MODE: 'move',
};

const CROPPED_IMAGE_OPTIONS: CroppedImageOptions = {
  MIN_WIDTH: 200,
  MIN_HEIGHT: 200,
  MAX_WIDTH: 1024,
  MAX_HEIGHT: 1024,
  FILL_COLOR: '#fff',
  IMAGE_SMOOTHING_QUALITY: 'high',
};

const ImageCropper = ({
  previewPath,
  croppedImage,
  imageDimension,
  setCroppedImage,
  fillColor,
}: ImageCropperProps): JSX.Element => {
  const { t } = useTranslation();

  const cropperRef = useRef(null);

  function cropImage() {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (typeof cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    setCroppedImage(
      cropper.getCroppedCanvas({
        width: imageDimension.width,
        height: imageDimension.height,
        minWidth: CROPPED_IMAGE_OPTIONS.MIN_WIDTH,
        minHeight: CROPPED_IMAGE_OPTIONS.MIN_HEIGHT,
        maxWidth: CROPPED_IMAGE_OPTIONS.MAX_WIDTH,
        maxHeight: CROPPED_IMAGE_OPTIONS.MAX_HEIGHT,
        fillColor: fillColor || CROPPED_IMAGE_OPTIONS.FILL_COLOR,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: CROPPED_IMAGE_OPTIONS.IMAGE_SMOOTHING_QUALITY,
      })
    );
  }

  return (
    <div className="cropper-container">
      {!croppedImage ? (
        <>
          <Cropper
            aspectRatio={imageDimension.height / imageDimension.width}
            guides={false}
            src={previewPath}
            ref={cropperRef}
            viewMode={CROPPER_OPTIONS.VIEW_MODE}
            dragMode={CROPPER_OPTIONS.DRAG_MODE}
            cropBoxMovable
            movable={false}
            zoomable={false}
            cropBoxResizable
            minCropBoxWidth={CROPPER_OPTIONS.MIN_CROP_BOX_WIDTH}
            minCropBoxHeight={CROPPER_OPTIONS.MIN_CROP_BOX_HEIGHT}
            responsive
          />
          <div className="buttons-container">
            <Button autoFocus onClick={cropImage} color="primary">
              {t('image-uploader.crop-image-button')}
            </Button>
          </div>
        </>
      ) : (
        <img className="cropped-image-preview" src={croppedImage.toDataURL()} alt="" />
      )}
    </div>
  );
};

ImageCropper.defaultProps = {
  fillColor: '',
};

export default ImageCropper;
