import './colors-section.scss';

import ColorPicker from 'components/color-picker/ColorPicker';
import MiscSectionDate from 'models/MiscSectiondata';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ColorsSectionProps {
  miscSection: MiscSectionDate;
  setMiscSection: (value: MiscSectionDate) => void;
}

const ColorsSection = ({ miscSection, setMiscSection }: ColorsSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const [isOpened, setOpened] = useState<boolean>(false);

  function setItemColor(color: string) {
    setMiscSection({ ...miscSection, launchBackgroundColor: color });
  }

  function setColorPickerOpen(isColorPickerOpen: boolean, eventType?: 'press'): (event?: unknown) => void {
    if (eventType === 'press') {
      return (event: KeyboardEvent) => {
        const { key } = event;
        if (key === 'Enter') {
          return setOpened(isColorPickerOpen);
        }
        return null;
      };
    }
    return () => setOpened(isColorPickerOpen);
  }

  return (
    <div>
      <div className="section-title">{t('release-apps.config-info.config-tab.sections.colors')}</div>
      <div className="color-previews-container">
        <div className="color-preview-container">
          <div>{`${t('release-apps.config-info.config-tab.labels.launch-background-color')}: `}</div>
          <div
            className="color-preview"
            aria-label="color-preview"
            role="button"
            tabIndex={0}
            style={{ backgroundColor: miscSection.launchBackgroundColor as string }}
            onClick={setColorPickerOpen(true)}
            onKeyPress={setColorPickerOpen(true, 'press')}
          />
        </div>
        {isOpened && (
          <ColorPicker
            color={miscSection.launchBackgroundColor as string}
            setItemColor={setItemColor}
            handleClose={setColorPickerOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default ColorsSection;
