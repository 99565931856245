import Iframe from 'react-iframe';

const AppStatusReport = (): JSX.Element => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Iframe
    url="https://hqo-delivery-test.s3.amazonaws.com/appStatus.html"
    width="100%"
    height="100%"
    id="appStatus"
    position="relative"
  />
);

export default AppStatusReport;
