import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import AppState from 'models/AppState';
import AppStatusReport from 'app-status-report/AppStatusReport';
import Config from 'models/Config';
import ConfigInfo from 'release-apps/config-info/ConfigInfo';
import ConfirmCancelAllDialog from 'components/dialogs/confirm-cancel-all';
import ConfirmCancelAllModal from 'models/ConfirmCancelAllModal';
import CreateConfigDialog from 'components/dialogs/create-config-dialog';
import CreateConfigModal from 'models/CreateConfigModal';
import CreateRelease from 'components/create-release';
import Dashboard from './Dashboard';
import DeleteItemDialog from 'components/dialogs/delete-item-dialog/DeleteItemDialog';
import DeleteItemModal from 'models/DeleteItemModal';
import Footer from 'components/footer';
import Header from 'components/header/index';
import ManageConfigs from 'manage-configs/ManageConfigs';
import NotFoundPage from 'components/not-found-page/NotFoundPage';
import { NotificationContainer } from 'react-notifications';
import { ROUTES } from 'const';
import ReleaseApps from 'containers/ReleaseAppsContainer';
import { ReleaseManagement } from 'components/release-management/release-management';
import Sidebar from 'components/side-navigation/index';
import Whitelabel from 'models/Whitelabel';
import WhitelabelDialog from 'components/dialogs/whitelabel-dialog';
import WhitelabelModal from 'models/WhitelabelModal';
import WhitelabelsForCloning from 'models/WhitelabelsForCloning';
import { getWhitelabels } from 'store/actions';
import { isEmpty } from 'lodash';

const CLASS_NAMES = {
  IOS_MOBILE_VIEW_HIGHT: 'ios-mobile-view-height',
  MINI_DRAWER: 'mini-drawer',
  FIXED_DRAWER: 'fixed-drawer',
  COLLAPSED_DRAWER: 'collapsible-drawer',
};

const getDrawerStyle = (drawerType: string): string => {
  if (drawerType.includes(FIXED_DRAWER)) {
    return CLASS_NAMES.FIXED_DRAWER;
  }
  if (drawerType.includes(COLLAPSED_DRAWER)) {
    return CLASS_NAMES.COLLAPSED_DRAWER;
  }

  return CLASS_NAMES.MINI_DRAWER;
};

const createWhitelabelsForCloning = (
  whitelabels: Array<Whitelabel>,
  configs: Array<Config>
): Array<WhitelabelsForCloning> =>
  whitelabels.map((whitelabel) => ({
    id: whitelabel.id,
    brand: whitelabel.brand,
    configs: configs.filter((config) => config.whitelabel_id === whitelabel.id),
  }));

const ApplyIosMobileSupport = () => {
  // set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add(CLASS_NAMES.IOS_MOBILE_VIEW_HIGHT);
  } else if (document.body.classList.contains(CLASS_NAMES.IOS_MOBILE_VIEW_HIGHT)) {
    document.body.classList.remove(CLASS_NAMES.IOS_MOBILE_VIEW_HIGHT);
  }
};

/* const OmegaMicroserviceLinks = () => {
    const { showOmegaMicroservice } = useFlags();
    if (showOmegaMicroservice) {
        return (
            <Route exact path={ ROUTES.RELEASE_MANAGEMENT } component={ ReleaseManagement } />
        );
    }
    return <Route exact path={ ROUTES.RELEASE_APPS } component={ ReleaseApps } />;
}; */

const App = (): JSX.Element => {
  const whitelabelModal: WhitelabelModal = useSelector((state: AppState) => state.modals.whitelabel);
  const createConfigModal: CreateConfigModal = useSelector((state: AppState) => state.modals.createConfig);
  const deleteItemModal: DeleteItemModal = useSelector((state: AppState) => state.modals.deleteItem);
  const confirmCancelAllModal: ConfirmCancelAllModal = useSelector((state: AppState) => state.modals.confirmCancelAll);
  const whitelabels: Array<Whitelabel> = useSelector((state: AppState) => state.whitelabels.items);
  const configs: Array<Config> = useSelector((state: AppState) => state.whitelabels.configs);
  const drawerType: string = useSelector((state: AppState) => state.settings.drawerType);

  const dispatch = useDispatch();
  const drawerStyle = getDrawerStyle(drawerType);
  const [whitelabelsForCloning, setWhitelabelsForCloning] = useState<Array<WhitelabelsForCloning>>([]);

  ApplyIosMobileSupport();
  useEffect(() => {
    if (isEmpty(whitelabels)) {
      dispatch(getWhitelabels());
    }
    setWhitelabelsForCloning(createWhitelabelsForCloning(whitelabels, configs));
  }, [whitelabels, configs, dispatch]);

  return (
    <div className={`app-container ${drawerStyle}`}>
      <BrowserRouter>
        <Sidebar />
        <div className="app-main-container">
          <div className="app-header app-header-horizontal">
            <Header />
          </div>
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route exact path={ROUTES.DASHBOARD_MENU} component={Dashboard} />
                <Route exact path={ROUTES.RELEASE_APPS} component={ReleaseApps} />
                <Route exact path={ROUTES.MANAGE_WHITELABELS} component={ManageConfigs} />
                <Route exact path={ROUTES.APP_CONFIG} component={ConfigInfo} />
                <Route exact path={ROUTES.APP_STATUS_REPORT} component={AppStatusReport} />
                <Route exact path={ROUTES.RELEASE_MANAGEMENT} component={ReleaseManagement} />
                <Route exact path={ROUTES.RELEASE_CREATE} component={CreateRelease} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
            <NotificationContainer />
            <WhitelabelDialog {...whitelabelModal} />
            <CreateConfigDialog
              open={createConfigModal.open}
              whitelabelId={createConfigModal.whitelabelId}
              targetPageRoute={createConfigModal.targetPageRoute}
              whitelabelsForCloning={whitelabelsForCloning}
            />
            <DeleteItemDialog {...deleteItemModal} />
            <ConfirmCancelAllDialog open={confirmCancelAllModal.open} />
            <Footer />
          </main>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
