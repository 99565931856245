import { Chip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import i18next from 'i18next';

interface ChipFailedProps {
  chipStyle: string;
}

const ChipFailed = ({ chipStyle }: ChipFailedProps): JSX.Element => (
  <Chip
    label={i18next.t('status.failed')}
    variant="outlined"
    size="small"
    icon={<ErrorIcon />}
    className={`failed ${chipStyle}`}
  />
);

export default ChipFailed;
