import './json-tab.scss';

import Config from 'models/Config';
import ReactJson from 'react-json-view';

interface JsonTabProps {
  config?: Config;
}

const reactJsonStyles = {
  width: '100%',
};

const JsonTab = ({ config }: JsonTabProps): JSX.Element => (
  <div className="config-json-wrapper">
    <ReactJson src={config} style={reactJsonStyles} />
  </div>
);

JsonTab.defaultProps = {
  config: {},
};

export default JsonTab;
