import './whitelabel-configs-table.scss';

import { MouseEvent as ReactMouseEvent, useState } from 'react';
import Row, { ConfigTableRow } from '../models/Row';
import { handleCreateConfigModal, handleDeleteItemModal } from 'store/actions/Modals';

import ActionButton from './ActionButton';
import AddIcon from '@material-ui/icons/Add';
import BaseTable from '../base/index';
import Collapse from '@material-ui/core/Collapse';
import { DELETE_CONFIG } from '../../../constants/ActionTypes';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { HandleEditWhitelabelModalPayload } from 'store/actions/types/Modals';
import HeadCell from 'components/tables/models/HeadCell';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ROUTES } from 'const';
import { handleEditWhitelabelModal } from '../../../store/actions';
import i18next from 'i18next';
import { redirectToConfigPage } from 'util/whitelabel-config.utils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ConfigsTableData {
  appName: string;
  environment: string;
  version: string;
}

interface WhitelabelConfigsTableProps {
  whitelabelId: number;
  whitelabelUuid: string;
  whitelabelName: string;
  whitelabelBrandId: string;
  brazeSegmentId: string;
  devBrazeSegmentId: string;
  domain: string;
  rows: Array<Row>;
}

const columns: Array<HeadCell<ConfigsTableData>> = [
  {
    id: 'appName',
    align: 'left',
    label: i18next.t('config-table.columns.app-name'),
  },
  {
    id: 'environment',
    align: 'right',
    label: i18next.t('config-table.columns.environment'),
  },
];

const WhitelabelConfigsTable = ({
  whitelabelId,
  whitelabelName,
  rows,
  whitelabelUuid,
  brazeSegmentId,
  devBrazeSegmentId,
  whitelabelBrandId,
  domain,
}: WhitelabelConfigsTableProps): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  function handleClick() {
    setOpen(!open);
  }

  function handleEditWhitelabelRow(event: ReactMouseEvent<unknown>, rowId: number) {
    event.preventDefault();
    redirectToConfigPage({ history, appId: whitelabelId, configId: rowId });
  }

  const options = [
    {
      label: t('manageConfigs.options.delete'),
      handleOptionClick: (row: ConfigTableRow) =>
        dispatch(
          handleDeleteItemModal({
            id: row.id,
            uuid: whitelabelUuid,
            actionType: DELETE_CONFIG,
            name: t('dialogs.deleteItem.config-name', { name: row.data.appBrand }),
          })
        ),
    },
  ];

  function addConfigHandler(event: ReactMouseEvent<unknown>) {
    event.stopPropagation();
    dispatch(handleCreateConfigModal(whitelabelId, ROUTES.MANAGE_WHITELABELS));
  }

  function editWhitelabelHandler(event: ReactMouseEvent<unknown>) {
    event.stopPropagation();
    const payload: HandleEditWhitelabelModalPayload = {
      id: whitelabelId,
      uuid: whitelabelUuid,
      initialNameValue: whitelabelName,
      initialBrazeSegmentIDValue: brazeSegmentId,
      initialDevelopmentBrazeSegmentIDValue: devBrazeSegmentId,
      initialBrandValue: whitelabelBrandId,
      initialDomainValue: domain,
    };
    payload.id = whitelabelId;
    payload.uuid = whitelabelUuid;
    dispatch(handleEditWhitelabelModal(payload));
  }

  const whitelabelNode = (
    <div className="config-item-header">
      {whitelabelName}
      <ActionButton title={t('manageConfigs.addConfig')} onClick={addConfigHandler} icon={<AddIcon />} />
      <ActionButton
        title={t('release-apps.whitelabel.options.edit')}
        onClick={editWhitelabelHandler}
        icon={<EditIcon />}
      />
    </div>
  );

  return (
    <div>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={whitelabelNode} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {rows && rows.length > 0 && (
          <BaseTable
            rows={rows}
            columns={columns}
            options={options}
            onRowClick={handleEditWhitelabelRow}
            className="nested-container click-row"
          />
        )}
      </Collapse>
    </div>
  );
};

export default WhitelabelConfigsTable;
