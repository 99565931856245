import { ReactNode } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

interface CustomScrollbarsProps {
  style?: unknown;
  className: string;
  children: ReactNode;
}

const CustomScrollbars = (scrollbarsProps: CustomScrollbarsProps): JSX.Element => {
  function renderDiv(props: unknown) {
    return (
      <div
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        {...props}
        style={{ display: 'none' }}
        className="track-horizontal"
      />
    );
  }

  return <Scrollbars {...scrollbarsProps} autoHide renderTrackHorizontal={renderDiv} />;
};

export default CustomScrollbars;
