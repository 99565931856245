import { INPUT_NAMES } from 'const';

export const VERSION_REGEXP = /\d|\./;
const KEY_REGEXP = /^\S*$/;

export const handleInput = (event: React.KeyboardEvent<HTMLInputElement>, name: string): void => {
  const regExp = name === INPUT_NAMES.VERSION ? VERSION_REGEXP : KEY_REGEXP;

  const isValid = regExp.test(event.key);
  if (!isValid) {
    event.preventDefault();
  }
};
