import { createBrowserHistory, createMemoryHistory } from 'history';

const isTest = process.env.NODE_ENV === 'test';

export const history = isTest ? createMemoryHistory({ initialEntries: ['/'] }) : createBrowserHistory();

export const redirectToPage = (targetPageRoute: string): void => {
  if (history.location.pathname !== targetPageRoute) {
    history.push(targetPageRoute);
  }
};
