import ConfigSection from 'models/ConfigSection';
import ConfigSectionData from 'models/ConfigSectionData';
import { MISC_SECTION_FIELDS } from 'const';
import MiscSectionDate from 'models/MiscSectiondata';
import Section from '../section/Section';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

interface MiscSectionProps {
  miscSection: MiscSectionDate;
  setMiscSection: (value: MiscSectionDate) => void;
  handleSave?: () => void;
  resetFields?: () => void;
}

const MiscSection = ({ miscSection, setMiscSection, handleSave, resetFields }: MiscSectionProps): JSX.Element => {
  const { t } = useTranslation();

  const sections: Array<ConfigSection> = [
    {
      sectionName: MISC_SECTION_FIELDS.ENGINE_API,
      section: miscSection.engineApi as ConfigSectionData,
      title: t('release-apps.config-info.config-tab.sections.engineApi'),
      setSection: setMiscSection,
    },
    {
      sectionName: MISC_SECTION_FIELDS.POLICIES,
      section: miscSection.policies as ConfigSectionData,
      title: t('release-apps.config-info.config-tab.sections.policies'),
      setSection: setMiscSection,
    },
    {
      sectionName: MISC_SECTION_FIELDS.LIVE_SAFE_KEYS,
      section: miscSection.livesafeKeys as ConfigSectionData,
      title: t('release-apps.config-info.config-tab.sections.livesafe-keys'),
      setSection: setMiscSection,
    },
  ];

  return (
    <div>
      {sections.map(
        (data) =>
          data.section &&
          !isEmpty(data.section) && (
            <Section
              miscSection={miscSection}
              key={data.title}
              section={data.section}
              title={data.title}
              setSection={data.setSection}
              sectionName={data.sectionName}
              handleSave={handleSave}
              resetFields={resetFields}
            />
          )
      )}
    </div>
  );
};

MiscSection.defaultProps = {
  handleSave: () => {
    /* do nothing */
  },
  resetFields: () => {
    /* do nothing */
  },
};

export default MiscSection;
