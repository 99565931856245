import * as Sentry from '@sentry/react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import configureStore, { history } from './store';

import AppContainer from './containers/AppContainer';
import { ConnectedRouter } from 'connected-react-router';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';

export const store = configureStore();

Sentry.init({
  dsn: 'https://00127a2969db4a0fa30ba622d58fa861@o379338.ingest.sentry.io/5215620',
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const MainApp = (): JSX.Element => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={AppContainer} />
        </Switch>
      </BrowserRouter>
    </ConnectedRouter>
  </Provider>
);

export default MainApp;
