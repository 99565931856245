import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const translation = require('./translation.json');

interface Resources {
  [key: string]: Record<string, unknown>;
}

const LANGUAGE_CODES = {
  ENGLISH: 'en',
};

const resources: Resources = {};

resources[LANGUAGE_CODES.ENGLISH] = { translation };

i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGE_CODES.ENGLISH,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
