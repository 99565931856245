import './image-uploader-dialog.scss';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import ImageCropper from '../../image-cropper/ImageCropper';
import ImageUploader from '../../image-uploader/ImageUploader';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { PropTypes } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ButtonVariant = 'text' | 'outlined' | 'contained';

interface ImageUploaderProps {
  isOpened: boolean;
  imageDimension: {
    height: number;
    width: number;
  };
  handleClose: () => void;
  setImage: (value: string | HTMLCanvasElement) => void;
  fillColor?: string;
}

const ImageUploaderDialog = ({
  isOpened,
  handleClose,
  setImage,
  imageDimension,
  fillColor,
}: ImageUploaderProps): JSX.Element => {
  const { t } = useTranslation();
  const [droppedFile, setDroppedFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  function resetImage() {
    setDroppedFile(null);
    setCroppedImage(null);
  }

  function closeDialog() {
    resetImage();
    handleClose();
  }

  function saveChanges() {
    setImage(croppedImage);
    closeDialog();
  }

  function onClose(_: unknown, reason: string) {
    if (reason !== 'backdropClick') {
      closeDialog();
    }
  }

  const actionButtons = [
    {
      name: t('image-uploader.reset-image-button'),
      isDisabled: !droppedFile,
      color: 'default' as PropTypes.Color,
      variant: 'text' as ButtonVariant,
      action: () => resetImage(),
    },
    {
      name: t('image-uploader.reset-cropping-button'),
      isDisabled: !croppedImage,
      color: 'default' as PropTypes.Color,
      variant: 'text' as ButtonVariant,
      action: () => setCroppedImage(null),
    },
    {
      name: t('image-uploader.save-button'),
      isDisabled: !croppedImage,
      color: 'primary' as PropTypes.Color,
      variant: 'contained' as ButtonVariant,
      action: () => saveChanges(),
    },
  ];

  return (
    <Dialog className="image-uploader-dialog" open={isOpened} onClose={onClose}>
      <div className="header">
        <h3 className="title">{t('image-uploader.title')}</h3>
        <IconButton onClick={closeDialog}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="content">
        {droppedFile ? (
          <ImageCropper
            previewPath={droppedFile.preview}
            croppedImage={croppedImage}
            setCroppedImage={setCroppedImage}
            imageDimension={imageDimension}
            fillColor={fillColor}
          />
        ) : (
          <ImageUploader setDroppedFile={setDroppedFile} />
        )}
      </div>
      <MuiDialogActions>
        {actionButtons.map((button) => (
          <Button
            key={button.name}
            onClick={button.action}
            disabled={button.isDisabled}
            color={button.color}
            variant={button.variant}
          >
            {button.name}
          </Button>
        ))}
      </MuiDialogActions>
    </Dialog>
  );
};

ImageUploaderDialog.defaultProps = {
  fillColor: '',
};

export default ImageUploaderDialog;
