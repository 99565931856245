import './input-inline-edit.scss';

import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent } from 'react';
import { Check, Close } from '@material-ui/icons';

import { INPUT_NAMES } from 'const';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { handleInput } from 'util/input.utils';

interface InputInlineEditProps {
  defaultValue: string;
  handleChange: (value: string) => void;
  handleSave: () => void;
  handleClose: (value: boolean) => void;
}

const InputInlineEdit = ({
  defaultValue,
  handleChange,
  handleSave,
  handleClose,
}: InputInlineEditProps): JSX.Element => {
  function handleKeyPress(event: ReactKeyboardEvent<HTMLInputElement>) {
    handleInput(event, INPUT_NAMES.VERSION);
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    handleChange(value);
  }

  function handleCloseClick() {
    handleClose(false);
  }

  return (
    <div className="input-inline-edit">
      <InputBase
        className="apps-version-input"
        autoFocus
        defaultValue={defaultValue}
        onKeyPress={handleKeyPress}
        onChange={handleInputChange}
      />
      <IconButton onClick={handleSave} size="small" className="icon-button-save remove-outline">
        <Check className="icon" />
      </IconButton>
      <IconButton onClick={handleCloseClick} size="small" className="icon-button-cancel remove-outline">
        <Close className="icon" />
      </IconButton>
    </div>
  );
};

export default InputInlineEdit;
