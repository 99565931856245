import { useEffect, useState } from 'react';

import { NotificationManager } from 'react-notifications';
import Option from 'models/Option';
import SelectDropDown from '../base';
import { getOmegaData } from '../../../util/release-management.utils';
import i18next from 'i18next';

interface PlatformDropDownProps {
  updateStateCallback: React.Dispatch<React.SetStateAction<string>>;
}

function nop() {
  // do nothing.
}

const PlatformsDropDown = ({ updateStateCallback: cb = null }: PlatformDropDownProps): JSX.Element => {
  const [options, setOptions] = useState([]);
  const defaultOptions: Array<Option<string>> = [];

  const getPlatforms = async () => {
    try {
      const platforms = await getOmegaData<Array<string>>('/v1/platforms');
      setOptions(
        platforms.map((name) => ({
          id: name,
          label: name,
          handleOptionClick: nop,
        }))
      );
    } catch (e) {
      NotificationManager.warning(e.message);
    }
  };

  useEffect(() => {
    getPlatforms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectDropDown
      defaultOptions={defaultOptions}
      options={options}
      defaultValue={i18next.t('release-management.selects.platform')}
      onChangeCallback={cb}
      className="select-drop-down"
    />
  );
};

export default PlatformsDropDown;
