import './images-section.scss';

import { IMAGE_DIMENSIONS, MISC_SECTION_FIELDS } from 'const';
import { lowerCase, upperFirst } from 'lodash';
import { resolveImagePreviewUrl, setDefaultImageUrl } from 'util/whitelabel-config.utils';

import ImageUploaderDialog from 'components/dialogs/image-uploader-dialog/ImageUploaderDialog';
import ImagesHash from '../../../../../models/ImagesHash';
import MiscSectionDate from 'models/MiscSectiondata';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ImagesSectionProps {
  miscSection: MiscSectionDate;
  setMiscSection: (value: MiscSectionDate) => void;
  handleSave: (ih: ImagesHash) => void;
  imagesHash: ImagesHash;
}

const ImagesSection = ({ miscSection, setMiscSection, handleSave, imagesHash }: ImagesSectionProps): JSX.Element => {
  const { t } = useTranslation();

  const [imageUploaderPayload, setImageUploaderPayload] = useState({
    isOpened: false,
    setImage: null,
  });

  function closeImageUploader() {
    setImageUploaderPayload({ isOpened: false, setImage: null });
  }

  const openImageUploader = (setImage: (value: string | HTMLCanvasElement) => void) =>
    setImageUploaderPayload({ isOpened: true, setImage });

  const imageFields = [MISC_SECTION_FIELDS.HEADER_IMAGE, MISC_SECTION_FIELDS.LAUNCH_IMAGE];

  function handleImageInteraction(imageField: string, eventType: 'click' | 'press'): (event: unknown) => void {
    const setImage = (image: string | HTMLCanvasElement) => {
      setMiscSection({ ...miscSection, [imageField]: image });
      handleSave({ ...imagesHash, [imageField]: image });
    };
    if (eventType === 'click') {
      return () => openImageUploader(setImage);
    }
    if (eventType === 'press') {
      return (event: KeyboardEvent) => {
        const { key } = event;
        if (key === 'Enter') {
          return openImageUploader(setImage);
        }
        return null;
      };
    }
    return () => {
      /* do nothing */
    };
  }

  return (
    <div>
      <div className="section-title">{t('release-apps.config-info.config-tab.sections.images')}</div>
      <div className="image-uploaders-container">
        {imageFields.map((imageField) => (
          <div key={imageField} className="image-uploader-container">
            <div className="sub-title">{upperFirst(lowerCase(imageField))}</div>
            <div
              role="button"
              tabIndex={0}
              onClick={handleImageInteraction(imageField, 'click')}
              onKeyPress={handleImageInteraction(imageField, 'press')}
            >
              <img
                src={resolveImagePreviewUrl(miscSection[imageField] as HTMLCanvasElement | string)}
                className="image-preview"
                onError={setDefaultImageUrl}
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
      <ImageUploaderDialog
        {...imageUploaderPayload}
        imageDimension={IMAGE_DIMENSIONS.NO_DIMENSION}
        handleClose={closeImageUploader}
      />
    </div>
  );
};

export default ImagesSection;
