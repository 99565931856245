import { BUILD_WHITELABEL, CONTENT_TYPES, FILE_DATA_KEYS, HTTP_METHODS, WHITELABELS, WHITELABEL_CONFIGS } from 'const';

import FilePayload from 'models/FilePayload';
import { UploadCertificatePayload } from 'store/actions/types/Whitelabels';
import WhitelabelQuery from 'models/WhitelabelQuery';
import WhitelabelsQuery from 'models/WhitelabelsQuery';
import api from './helix.api';
import { compressFile } from 'util/compress-file.utils';

export function deleteConfig(id: number): Promise<unknown> {
  return api.del(`${WHITELABEL_CONFIGS}/${id}`).catch((error: Error) => error);
}

export function getConfigs(): Promise<unknown> {
  return api.get(WHITELABEL_CONFIGS).catch((error: Error) => error);
}

export function getConfig(id: number): Promise<unknown> {
  return api.get(`${WHITELABEL_CONFIGS}/${id}`).catch((error: Error) => error);
}

export function deleteWhitelabel(uuid: string): Promise<unknown> {
  return api.del(`${WHITELABELS}/${uuid}`).catch((error: Error) => error);
}

export function getWhitelabel(uuid: string): Promise<WhitelabelQuery> {
  return api.get<WhitelabelQuery>(`${WHITELABELS}/${uuid}`);
}

export function getWhitelabels(): Promise<WhitelabelsQuery> {
  return api.get<WhitelabelsQuery>(WHITELABELS);
}

export function runCircleCiWhitelabelBuild(payload: { core: string; branch: string }): Promise<void> {
  return api.post<void>(BUILD_WHITELABEL, payload);
}

export function uploadCertificate(payload: UploadCertificatePayload): Promise<Response> {
  const { id, certificate } = payload;
  const url = `${WHITELABEL_CONFIGS}/${id}/certificate`;

  const filePayload: FilePayload = {
    key: FILE_DATA_KEYS.CERTIFICATE,
    file: new Blob([certificate], { type: CONTENT_TYPES.P12 }),
  };
  return api.uploadFile<Response>(url, HTTP_METHODS.PUT, filePayload);
}

export async function uploadImage(file: HTMLCanvasElement): Promise<{ path: string }> {
  const compressedFile = await compressFile(file);
  const url = 'images/upload';

  const imageData: FilePayload = {
    key: FILE_DATA_KEYS.IMAGE,
    file: compressedFile as Blob,
  };
  return api.uploadFile<{ path: string }>(url, HTTP_METHODS.POST, imageData);
}
