import {
  CHANGE_APPS_VERSION_SUCCESS,
  CREATE_CONFIG_SUCCESS,
  CREATE_WHITELABEL_SUCCESS,
  DELETE_CONFIG_SUCCESS,
  DELETE_WHITELABEL_SUCCESS,
  GET_CONFIG_SUCCESS,
  GET_RELEASE_NOTES_SUCCESS,
  GET_WHITELABELS_SUCCESS,
  GET_WHITELABEL_SUCCESS,
  RUN_WHITELABEL_BUILD_SUCCESS,
  SET_BUILDS,
  SET_BUILDS_FAILURE,
  SET_BUILDS_SUCCESS,
  SET_BUILD_LOADING,
  SIGNOUT_USER_SUCCESS,
  UPDATE_CONFIG_SUCCESS,
  UPDATE_WHITELABEL_SUCCESS,
} from 'constants/ActionTypes';
import {
  ChangeAppsVersionSuccessActionPayload,
  CreateWhitelabelSuccessActionPayload,
  RunWhitelabelBuildSuccessActionPayload,
  SetBuildLoadingActionPayload,
  UpdateWhitelabelSuccessActionPayload,
  WhitelabelsActionTypes,
} from '../actions/types/Whitelabels';

import BuildsData from 'models/BuildsData';
import Config from 'models/Config';
import Whitelabel from 'models/Whitelabel';
import WhitelabelsData from 'models/WhitelabelsData';
import { isString } from 'lodash';

const INITIAL_STATE: WhitelabelsData = {
  configs: [],
  items: [],
  releaseNotes: '',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getItemAndIndex = (items: any, id: number) => {
  const index = items.findIndex((item: Whitelabel | Config) => item.id === id);
  return { item: items[index], index };
};

const setWhitelabelBuilds = (items: Array<Whitelabel>, id: number, builds: BuildsData) => {
  const { item, index } = getItemAndIndex(items, id);
  const newItems = [...items];

  newItems[index] = {
    ...item,
    builds: { data: { ...builds }, loading: { ios: false, android: false } },
  };
  return newItems;
};

const updateConfig = (items: Array<Config>, config: Config) => {
  const newConfigs = [...items];
  const { item, index } = getItemAndIndex(newConfigs, config.id);

  if (item) {
    newConfigs[index] = { ...config };
  } else {
    newConfigs.push(config);
  }

  return [...newConfigs];
};

const deleteItem = (items: Array<Config | Whitelabel>, id: number) => {
  const { index } = getItemAndIndex(items, id);
  const newItems = [...items];

  newItems.splice(index, 1);

  return newItems;
};

const createWhitelabel = (items: Array<Whitelabel>, payload: CreateWhitelabelSuccessActionPayload) => {
  const newWhitelabels = [...items];
  const { id, brand, uuid, builds, brand_identifier, domain, braze_segment_id, development_braze_segment_id } = payload;
  const whitelabel = {
    id,
    brand,
    uuid,
    builds,
    brand_identifier,
    domain,
    braze_segment_id,
    development_braze_segment_id,
  };

  newWhitelabels.push(whitelabel);

  return [...newWhitelabels];
};

const updateWhitelabel = (items: Array<Whitelabel>, payload: UpdateWhitelabelSuccessActionPayload) => {
  const { id, brand, domain } = payload;
  const { item, index } = getItemAndIndex(items, id);
  const newItems = [...items];

  newItems[index] = { ...item, brand, domain };

  return newItems;
};

const startLoadWhitelabelBuilds = (items: Array<Whitelabel>, id: number) => {
  const { item, index } = getItemAndIndex(items, id);
  const newItems = [...items];

  newItems[index] = {
    ...item,
    builds: {
      ...item.builds,
      loading: {
        ios: true,
        android: true,
      },
    },
  };
  return newItems;
};

const endLoadWhitelabelBuilds = (items: Array<Whitelabel>, id: number) => {
  const { item, index } = getItemAndIndex(items, id);
  const newItems = [...items];

  newItems[index] = {
    ...item,
    builds: {
      ...item.builds,
      loading: {
        ios: false,
        android: false,
      },
    },
  };
  return newItems;
};

const addWhiteLabelBuild = (items: Array<Whitelabel>, payload: RunWhitelabelBuildSuccessActionPayload) => {
  const { item, index } = getItemAndIndex(items, payload.id);
  const builds = item.builds.data[payload.platformBuilds];
  const isBuildsError = isString(builds) || !builds;
  const updatedBuilds = isBuildsError ? [payload.build] : [payload.build, ...builds];
  const newItems = [...items];

  newItems[index] = {
    ...item,
    builds: {
      data: {
        ...item.builds.data,
        [payload.platformBuilds]: [...updatedBuilds],
      },
      loading: {
        ...item.builds.loading,
        [payload.platform]: false,
      },
    },
  };
  return newItems;
};

const setAppsVersion = (configs: Array<Config>, payload: ChangeAppsVersionSuccessActionPayload) => {
  const { index } = getItemAndIndex(configs, payload.configId);
  const newConfigs = [...configs];
  newConfigs[index] = payload.config;
  return newConfigs;
};

const setBuildLoading = (items: Array<Whitelabel>, payload: SetBuildLoadingActionPayload) => {
  const { item, index } = getItemAndIndex(items, payload.id);
  const newItems = [...items];
  newItems[index] = {
    ...item,
    builds: {
      ...item.builds,
      loading: {
        ...item.builds.loading,
        [payload.platform]: payload.isLoading,
      },
    },
  };
  return newItems;
};

const updateWhitelabels = (items: Array<Whitelabel>, whitelabel: Whitelabel) => {
  const { item, index } = getItemAndIndex(items, whitelabel.id);
  const newItems = [...items];
  if (item) {
    newItems[index] = { ...item, ...whitelabel };
  } else {
    newItems.push({ ...whitelabel });
  }
  return newItems;
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action: WhitelabelsActionTypes): WhitelabelsData => {
  switch (action.type) {
    case GET_WHITELABELS_SUCCESS:
      return {
        ...state,
        configs: action.payload.configs,
        items: action.payload.items,
      };
    case GET_WHITELABEL_SUCCESS:
      return {
        ...state,
        items: updateWhitelabels(state.items, action.payload.item),
      };
    case SET_BUILDS:
      return {
        ...state,
        items: startLoadWhitelabelBuilds(state.items, action.payload.id),
      };
    case SET_BUILDS_SUCCESS:
      return {
        ...state,
        items: setWhitelabelBuilds(state.items, action.payload.id, action.payload.builds),
      };
    case SET_BUILDS_FAILURE:
      return {
        ...state,
        items: endLoadWhitelabelBuilds(state.items, action.payload.id),
      };
    case GET_RELEASE_NOTES_SUCCESS:
      return {
        ...state,
        releaseNotes: action.payload,
      };
    case CHANGE_APPS_VERSION_SUCCESS:
      return {
        ...state,
        configs: setAppsVersion(state.configs, action.payload),
      };
    case RUN_WHITELABEL_BUILD_SUCCESS:
      return {
        ...state,
        items: addWhiteLabelBuild(state.items, action.payload),
      };
    case SET_BUILD_LOADING:
      return {
        ...state,
        items: setBuildLoading(state.items, action.payload),
      };
    case CREATE_CONFIG_SUCCESS:
    case UPDATE_CONFIG_SUCCESS:
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        configs: updateConfig(state.configs, action.payload.config as Config),
      };
    case DELETE_CONFIG_SUCCESS:
      return {
        ...state,
        configs: deleteItem(state.configs, action.payload) as Array<Config>,
      };
    case CREATE_WHITELABEL_SUCCESS:
      return {
        ...state,
        items: createWhitelabel(state.items, action.payload),
      };
    case UPDATE_WHITELABEL_SUCCESS:
      return {
        ...state,
        items: updateWhitelabel(state.items, action.payload),
      };
    case DELETE_WHITELABEL_SUCCESS:
      return {
        ...state,
        items: deleteItem(state.items, action.payload) as Array<Whitelabel>,
      };
    case SIGNOUT_USER_SUCCESS:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
