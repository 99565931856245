import './delete-item-dialog.scss';

import BaseDialog from '../base/index';
import Button from '@material-ui/core/Button';
import { DEFAULT_VALIDATION_OPTIONS } from 'constants/ActionTypes';
import ReactHookFormError from 'models/ReactHookFormError';
import ValidationTextField from '../../validation-text-field';
import { handleDeleteItemModal } from 'store/actions/Modals';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DeleteItemDialogProps {
  open: boolean;
  id: number;
  uuid: string;
  name: string;
  actionType: string;
  targetPageRoute?: string;
}

const CONFIRM_WORD = 'delete';

const DeleteItemDialog = ({
  open,
  id,
  name,
  actionType,
  targetPageRoute,
  uuid,
}: DeleteItemDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
  } = useForm(DEFAULT_VALIDATION_OPTIONS);

  const [confirmWord, setConfirmWord] = useState<string>('');

  const isDeleteEnabled = isEmpty(errors) && confirmWord && confirmWord.toLocaleLowerCase() === CONFIRM_WORD;

  function onCancel() {
    dispatch(handleDeleteItemModal());
    setConfirmWord('');
  }

  function onSubmit() {
    dispatch({ type: actionType, payload: { id, targetPageRoute, uuid } });
    onCancel();
  }

  const actions = [
    <Button key="cancel" variant="text" color="default" onClick={onCancel}>
      {t('buttons.cancel')}
    </Button>,
    <Button key="delete" variant="contained" color="secondary" onClick={onSubmit} disabled={!isDeleteEnabled}>
      {t('buttons.confirm-delete')}
    </Button>,
  ];

  return (
    <BaseDialog title={t('dialogs.deleteItem.title')} open={open} actions={actions} handleClose={onCancel}>
      <div className="dialog-content">
        <div className="dialog-message">
          {`${t('dialogs.deleteItem.caution')} `} <span className="emphasized">{name}</span>{' '}
          {`.
                ${t('dialogs.deleteItem.procedure-irreversible')}`}
        </div>
        <div className="dialog-message">
          {`${t('dialogs.deleteItem.requirement')} `} <span className="emphasized">{CONFIRM_WORD}</span>
        </div>
        <ValidationTextField
          name="confirmWord"
          required
          setValue={setConfirmWord}
          type="text"
          errors={errors as unknown as ReactHookFormError}
          control={control}
          fullWidth
        />
      </div>
    </BaseDialog>
  );
};

DeleteItemDialog.defaultProps = {
  targetPageRoute: '',
};

export default DeleteItemDialog;
