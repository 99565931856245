import { DEFAULT_IMAGE_URL, PLATFORMS, PLATFORM_BUILDS, ROUTES } from 'const';
import { get, isNil } from 'lodash';

import Config from 'models/Config';
import HTMLImageEvent from 'models/HTMLImageEvent';
import { History } from 'history';
import { generatePath } from 'react-router';
import i18next from 'i18next';

interface RedirectToConfigPageProps {
  appId: number;
  configId: number;
  history: History;
}

export const resolveDefaultConfig = (configs: Array<Config>): Config | null =>
  configs?.find((config) => {
    if (config.build_data.appcenterConfig) {
      const { owner, iosAppId, androidAppId, apiToken } = config.build_data.appcenterConfig;

      return owner && iosAppId && androidAppId && apiToken;
    }
    return null;
  });

export const isConfigValid = (config: Config): string | boolean => {
  const appCenterConfig = get(config, 'build_data.appcenterConfig');

  if (appCenterConfig) {
    const { owner, iosAppId, androidAppId, apiToken }: { [key: string]: string } = appCenterConfig;

    return owner && iosAppId && androidAppId && apiToken;
  }

  return !!appCenterConfig;
};

export const resolveImagePreviewUrl = (image: string | HTMLCanvasElement): string => {
  // Because of how JS handles string literals, must be of this format
  if (typeof image === 'string') {
    return image as unknown as string;
  }

  if (image instanceof HTMLCanvasElement) {
    return image.toDataURL();
  }

  return DEFAULT_IMAGE_URL;
};

export const convertObjectToArray = (object: { [key: string]: string }): Array<string> =>
  Object.keys(object).map((key) => object[key]);

export const setDefaultImageUrl = (event: HTMLImageEvent): void => {
  const { target } = event;
  target.src = DEFAULT_IMAGE_URL;
};

export const getAppcenterVars = (
  config: Config
): { appcenterKey: string; owner: string; ios: string; android: string } => {
  const appcenterKey: string = get(config, 'build_data.appcenterConfig.apiToken', '');
  const owner: string = get(config, 'build_data.appcenterConfig.owner', 'hqo');
  const ios: string = get(config, 'build_data.appcenterConfig.iosAppId', '');
  const android: string = get(config, 'build_data.appcenterConfig.androidAppId', '');

  return { appcenterKey, owner, ios, android };
};

export const getPlatformData = (
  platform: string,
  config: Config
): { platform: string; platformBuilds: string } | null => {
  const { ios, android } = getAppcenterVars(config);

  switch (platform) {
    case PLATFORMS.IOS:
      return {
        platform: ios,
        platformBuilds: PLATFORM_BUILDS.IOS_BULDS,
      };
    case PLATFORMS.ANDROID:
      return {
        platform: android,
        platformBuilds: PLATFORM_BUILDS.ANDROID_BUILDS,
      };
    default:
      return null;
  }
};

export const redirectToConfigPage = ({ appId, configId, history }: RedirectToConfigPageProps): void => {
  const pathname = generatePath(ROUTES.APP_CONFIG, { appId, configId });

  history.replace({
    pathname,
    state: {
      previousLocation: history.location.pathname,
    },
  });
};

export const isCustomConfigUtil = (config: Config): boolean => {
  const { plist, assets, theme, appcenterConfig } = config.build_data;

  return isNil(plist) || isNil(assets) || isNil(theme) || isNil(appcenterConfig);
};

export const resolveJsonInputStyle = (
  height: string,
  width: string,
  fontSize: string
): {
  outerBox: { height: string; width: string };
  container: { height: string; width: string };
  body: { fontSize: string };
  labelColumn: { width: 'inherit' };
} => {
  const jsonInputSize = {
    height,
    width,
  };

  return {
    labelColumn: { width: 'inherit' },
    body: { fontSize },
    outerBox: { ...jsonInputSize },
    container: { ...jsonInputSize },
  };
};

export const resolveTooltipText = (name: string): string => {
  const nameNoSpaces = name.replace(/\s+/g, name);
  const identifier = `tooltip.${nameNoSpaces}`;
  const text = i18next.t(identifier);
  if (text !== identifier) {
    return text;
  }
  return '';
};
