import BuildData from '../models/BuildData';

const defaultBuildData: Partial<BuildData> = {
  androidBundleName: '',
  androidDisplayName: '',
  androidKeystore: '',
  apiDomain: '',
  appBrand: '',
  appName: '',
  appcenterConfig: {
    androidAppId: '',
    apiToken: '',
    certificatePassword: '',
    developmentBaseBranch: '',
    distributionBaseBranch: '',
    iosAppId: '',
    owner: '',
  },
  assets: {},
  cdnUrl: '',
  brazeAndroidApiKey: '',
  brazeAndroidFireBaseSenderId: '',
  brazeSdkEndpoint: '',
  engineApi: {
    id: '',
    key: '',
  },
  environment: '',
  fcmSenderId: '',
  googlePayMode: '',
  googleServices: {
    client: [
      {
        api_key: [
          {
            current_key: '',
          },
        ],
        client_info: {
          android_client_info: {
            package_name: '',
          },
          mobilesdk_app_id: '',
        },
        oauth_client: [
          {
            client_id: '',
            client_type: 0,
          },
        ],
        services: {
          appinvite_service: {
            other_platform_oauth_client: [
              {
                client_id: '',
                client_type: 0,
              },
            ],
          },
        },
      },
    ],
    configuration_version: '',
    project_info: {
      firebase_url: '',
      project_id: '',
      project_number: '',
      storage_bucket: '',
    },
  },
  headerImage: '',
  iTunesURL: '',
  iconImage: '',
  imgUrl: '',
  incentivio: {
    baseUrl: '',
    clientId: '',
  },
  iosAppKey: '',
  iosBundleName: '',
  iosCertificate: {
    file: '',
    password: '',
  },
  iosEntitlements: {},
  iosProvisioningProfile: '',
  launchBackgroundColor: '',
  launchImage: '',
  mixpanelToken: '',
  plist: {
    embraceIosKeyNative: '',
    brazeApiKey: '',
    zendesk: {
      url: '',
      app_id: '',
      client_id: '',
      email: '',
    },
    CFBundleDisplayName: '',
    CFBundleIdentifier: '',
    CFBundleShortVersionString: '',
    CFBundleURLTypes: [
      {
        CFBundleTypeRole: '',
        CFBundleURLName: '',
        CFBundleURLSchemes: ['', ''],
      },
    ],
    UIBackgroundModes: ['', ''],
    branch_app_domain: '',
    branch_key: {
      live: '',
      test: '',
    },
    moduleName: '',
    stripeApiKey:
      'pk_test_51DHHdpCn82YsUDVhK4vu6s7vUvfR3l7aAayDtSOerkh9dmIuWdlFczQdq4xfCrSR6E9JjzBEMCPS3vYqvjrMTkUW00fKZEmCb4',
    spreedlyProduction: {
      spreedlyAccessSecret: 'iYLtSOzlwofetmNZdWKKPuHdtx4Btw7vkBUerzvLpHz4hKvV8yZVkgHhyMsFR7AM',
      spreedlyEnvKey: 'J54HquyXhpXoLu4BVOxP5FvgVDt',
    },
    spreedlyDevelopment: {
      spreedlyAccessSecret: 'O4ptxQ44tLISQkTAPLo4I2n16IKdn755O0mVPZnBc7WmwTlVpqv2axhiMBpw7bHc',
      spreedlyEnvKey: 'K6Fz2XZk8Ot1EMe8zSw4QS0epuG',
    },
    NSCameraUsageDescription: '',
  },
  policies: {
    EULA: '',
    privacyPolicy: '',
    DELETE_YOUR_ACCOUNT: '',
  },
  pushExampleBody: '',
  pushExampleTitle: '',
  rollbarKey: '',
  stripePublishKey: '',
  theme: {
    fonts: null,
    $accent1: '',
    $accent2: '',
    $accent3: '',
    $accent4: '',
    $primary1: '',
    $primary2: '',
    $theme: '',
  },
  uuid: '',
  version: '',
  appVersion: '',
  embraceAndroidKeyNative: '',
  launchDarklyMobileKey: '',
  launchDarklySdkKey: '',
  launchDarklyClientId: '',
  stripeApiKey:
    'pk_test_51DHHdpCn82YsUDVhK4vu6s7vUvfR3l7aAayDtSOerkh9dmIuWdlFczQdq4xfCrSR6E9JjzBEMCPS3vYqvjrMTkUW00fKZEmCb4',
  spreedlyProduction: {
    spreedlyAccessSecret: 'iYLtSOzlwofetmNZdWKKPuHdtx4Btw7vkBUerzvLpHz4hKvV8yZVkgHhyMsFR7AM',
    spreedlyEnvKey: 'J54HquyXhpXoLu4BVOxP5FvgVDt',
  },
  spreedlyDevelopment: {
    spreedlyAccessSecret: 'O4ptxQ44tLISQkTAPLo4I2n16IKdn755O0mVPZnBc7WmwTlVpqv2axhiMBpw7bHc',
    spreedlyEnvKey: 'K6Fz2XZk8Ot1EMe8zSw4QS0epuG',
  },
};

export default defaultBuildData;
