import { HIDE_MESSAGE, INIT_URL, SHOW_MESSAGE, SIGNIN_USER_SUCCESS, SIGNOUT_USER_SUCCESS } from 'constants/ActionTypes';

import { AuthActionTypes } from 'store/actions/types/Auth';
import AuthState from '../../models/AuthState';
import { ROUTES } from '../../const';

const INITIAL_STATE: AuthState = {
  alertMessage: '',
  isMessageVisible: false,
  initURL: '',
  authUser: JSON.parse(localStorage.getItem('user')),
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: ROUTES.DASHBOARD_MENU,
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        isMessageVisible: true,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        isMessageVisible: false,
      };
    }
    default:
      return state;
  }
};
