import './create-release.scss';

import { useCallback, useState } from 'react';
import { ArrowBack } from '@material-ui/icons';
import BuildSystemsDropDown from 'components/select-drop-down/build-systems';
import { Button } from '@material-ui/core';
import { DEFAULT_VALIDATION_OPTIONS } from 'constants/ActionTypes';
import GitBranchDropDown from 'components/select-drop-down/git-branch';
import { NotificationManager } from 'react-notifications';
import PlatformsDropDown from 'components/select-drop-down/platform';
import ReactHookFormError from 'models/ReactHookFormError';
import ReleaseWhitelabel from '../../models/ReleaseWhitelabel';
import ValidationTextField from 'components/validation-text-field';
import WhitelabelDropDown from '../select-drop-down/whitelabels/index';
import { createRelease } from 'store/actions/Releases';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CreateRelease = (): JSX.Element => {
  const {
    formState: { errors },
    control,
  } = useForm(DEFAULT_VALIDATION_OPTIONS);
  const [notes, setNotes] = useState('');
  const [appVersion, setAppVersion] = useState('');
  const [buildSystem, setBuildSystem] = useState('');
  const [branch, setBranch] = useState('');
  const [commit, setCommit] = useState('');
  const [platform, setPlatform] = useState('');
  const [whitelabels, setWhitelabels] = useState<Array<string>>([]);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const changePlatform = useCallback(
    (nextPlatform) => {
      // each new platform change must invalidate current selected branch
      setBranch('');
      setPlatform(nextPlatform);
    },
    [setBranch, setPlatform]
  );

  function onCancel() {
    history.goBack();
  }

  function clearState() {
    setNotes('');
    setAppVersion('');
    setBuildSystem('');
    setBranch('');
    setCommit('');
    setPlatform('');
  }

  function onSubmit() {
    const userUUID = localStorage.getItem('user_uuid');

    // Actual UUID is set in the Saga, as that's the only place we can grab that bit of data
    const releaseObj: ReleaseWhitelabel = {
      Notes: notes,
      AppVersion: appVersion,
      BuildSystem: buildSystem,
      UserUUID: userUUID,
      Branch: branch,
      Commit: commit,
      Platform: platform,
      Whitelabels: whitelabels,
    };

    if (
      releaseObj.BuildSystem === '' ||
      releaseObj.Commit === '' ||
      releaseObj.Whitelabels.length === 0 ||
      releaseObj.Notes === '' ||
      releaseObj.AppVersion === ''
    ) {
      NotificationManager.warning('Please fill out all sections before submission');
    } else {
      clearState();
      dispatch(createRelease(releaseObj));
      onCancel();
    }
  }

  function setNotesValue(value: string) {
    setNotes(value.trim());
  }

  function setAppVersionValue(value: string) {
    setAppVersion(value.trim());
  }

  return (
    <div className="create-page">
      <Button variant="text" onClick={onCancel}>
        <ArrowBack /> Manage Releases
      </Button>
      <div className="create-page__title">
        {t('Create New Release')}
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Save
        </Button>
      </div>

      <div>
        <ValidationTextField
          name="notes"
          label="Notes"
          required
          value={notes}
          setValue={setNotesValue}
          type="text"
          errors={errors as unknown as ReactHookFormError}
          control={control}
          fullWidth
          multiline
        />
        <ValidationTextField
          name="appVersion"
          label="App Version"
          required
          value={appVersion}
          setValue={setAppVersionValue}
          type="text"
          errors={errors as unknown as ReactHookFormError}
          control={control}
          disabled={false}
          fullWidth
        />
        <div className="drop-down-group create-release-options">
          <BuildSystemsDropDown updateStateCallback={setBuildSystem} />
          <PlatformsDropDown updateStateCallback={changePlatform} />
          <GitBranchDropDown
            key={platform}
            platform={platform}
            updateStateCallback={setBranch}
            updateCommitOnChoiceCallback={setCommit}
          />
          <WhitelabelDropDown setWhitelabels={setWhitelabels} />
        </div>
      </div>
    </div>
  );
};

export default CreateRelease;
