import ConfigSectionData from 'models/ConfigSectionData';
import GeneralSection from '../../general-section/GeneralSection';
import { PlistData } from 'models/PlistData';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';

interface BranchKeySectionProps {
  plistSection: PlistData;
  setPlistSection: (value: PlistData) => void;
  handleSave?: () => void;
  resetFields?: () => void;
}

const BranchKeySection = ({
  plistSection,
  setPlistSection,
  handleSave,
  resetFields,
}: BranchKeySectionProps): JSX.Element => {
  const { t } = useTranslation();

  function setSection(key: string, value: string) {
    setPlistSection({
      ...plistSection,
      branch_key: { ...(plistSection.branch_key as ConfigSectionData), [key]: value },
    });
  }

  function deleteField(key: string) {
    setPlistSection({ ...plistSection, branch_key: { ...omit(plistSection.branch_key as ConfigSectionData, key) } });
  }

  return (
    <GeneralSection
      section={plistSection.branch_key as ConfigSectionData}
      title={t('release-apps.config-info.config-tab.sections.branch-key')}
      setSection={setSection}
      deleteField={deleteField}
      fieldsToExclude={null}
      isDynamic
      handleSave={handleSave}
      resetFields={resetFields}
    />
  );
};

BranchKeySection.defaultProps = {
  handleSave: () => {
    /* do nothing */
  },
  resetFields: () => {
    /* do nothing */
  },
};

export default BranchKeySection;
