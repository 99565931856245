import { CREATE_RELEASE, UPDATE_RELEASE } from '../../constants/ActionTypes';
import { ReleaseActionTypes, UpdateReleasePayload } from './types/Releases';
import ReleaseWhitelabel from 'models/ReleaseWhitelabel';

export const createRelease = (payload: ReleaseWhitelabel): ReleaseActionTypes => ({
  type: CREATE_RELEASE,
  payload,
});

export const updateRelease = (payload: UpdateReleasePayload): ReleaseActionTypes => ({
  type: UPDATE_RELEASE,
  payload,
});
