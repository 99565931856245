import { NotificationManager } from 'react-notifications';
import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import i18n from 'i18next';
import releasesSagas from './Releases';
import whitelabelsSagas from './Whitelabels';

export default function* rootSaga(): Generator {
  try {
    yield all([authSagas(), whitelabelsSagas(), releasesSagas()]);
  } catch (error) {
    NotificationManager.warning(i18n.t('notifications.sagaError', { sagaName: 'root', error: error.message }));
  }
}
