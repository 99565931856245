import { MAIN_SECTIONS_FIELDS, ROUTES } from 'const';
import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Config from 'models/Config';
import ConfigSectionData from 'models/ConfigSectionData';
import { DELETE_CONFIG } from '../../../constants/ActionTypes';
import Paper from '@material-ui/core/Paper';
import Section from '../config-tab/section/Section';
import StaticsTabData from 'models/StaticsTabData';
import Toolbar from '@material-ui/core/Toolbar';
import { handleDeleteItemModal } from 'store/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface StaticsTabProps {
  config: Config;
  updateParent: (s: StaticsTabData) => void;
}

const StaticsTab = ({ config, updateParent }: StaticsTabProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [genSection, setGenSection] = useState<ConfigSectionData>({
    apiDomain: config.build_data.apiDomain,
    apiToken: config.build_data.appcenterConfig.apiToken,
    appBrand: config.build_data.appBrand,
    appName: config.build_data.appName,
    cdnUrl: config.build_data.cdnUrl,
    developmentBaseBranch: config.build_data.appcenterConfig.developmentBaseBranch,
    distributionBaseBranch: config.build_data.appcenterConfig.distributionBaseBranch,
    imgUrl: config.build_data.imgUrl,
    owner: config.build_data.appcenterConfig.owner,
    pushExampleBody: config.build_data.pushExampleBody,
    pushExampleTitle: config.build_data.pushExampleTitle,
  });

  const newConfig = {
    apiDomain: genSection.apiDomain,
    apiToken: genSection.apiToken,
    appBrand: genSection.appBrand,
    appName: genSection.appName,
    cdnUrl: genSection.cdnUrl,
    developmentBaseBranch: genSection.developmentBaseBranch,
    distributionBaseBranch: genSection.distributionBaseBranch,
    imgUrl: genSection.imgUrl,
    owner: genSection.owner,
    pushExampleBody: genSection.pushExampleBody,
    pushExampleTitle: genSection.pushExampleTitle,
  };

  const locationState = history.location.state as { previousLocation: string };

  function handleSave() {
    updateParent(newConfig);
  }

  function handleDelete() {
    dispatch(
      handleDeleteItemModal({
        id: config.id,
        actionType: DELETE_CONFIG,
        targetPageRoute: locationState ? locationState.previousLocation : ROUTES.MANAGE_WHITELABELS,
        name: t('dialogs.deleteItem.config-name', { name: config.build_data.appBrand }),
      })
    );
  }

  function resetFields() {
    setGenSection({
      apiDomain: config.build_data.apiDomain,
      apiToken: config.build_data.appcenterConfig.apiToken,
      appBrand: config.build_data.appBrand,
      appName: config.build_data.appName,
      cdnUrl: config.build_data.cdnUrl,
      developmentBaseBranch: config.build_data.appcenterConfig.developmentBaseBranch,
      distributionBaseBranch: config.build_data.appcenterConfig.distributionBaseBranch,
      imgUrl: config.build_data.imgUrl,
      owner: config.build_data.appcenterConfig.owner,
      pushExampleBody: config.build_data.pushExampleBody,
      pushExampleTitle: config.build_data.pushExampleTitle,
    });
  }

  function goToPreviousPage() {
    history.push(locationState ? locationState.previousLocation : ROUTES.RELEASE_APPS);
  }

  return (
    <div className="config-tab-container">
      <AppBar position="sticky">
        <Toolbar className="config-tab-toolbar">
          <Button className="back-button" onClick={goToPreviousPage}>
            <ArrowBackIcon />
            {t('buttons.back')}
          </Button>
          <Button className="delete-button" variant="text" color="secondary" onClick={handleDelete}>
            {t('buttons.delete')}
          </Button>
        </Toolbar>
      </AppBar>
      <Paper className="config-tab-content">
        <Section
          key={0}
          section={genSection}
          setSection={setGenSection}
          title={t('release-apps.config-info.config-tab.sections.general')}
          sorted
          handleSave={handleSave}
          resetFields={resetFields}
          requiredFields={[MAIN_SECTIONS_FIELDS.APP_BRAND]}
        />
      </Paper>
    </div>
  );
};

export default StaticsTab;
