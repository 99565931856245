import AppState from 'models/AppState';
import Config from 'models/Config';
import config from '../../config';

export const selectWhitelabelConfigByBranch = (state: AppState, id: number): Config =>
  state.whitelabels.configs
    .filter((configItem) => configItem.whitelabel_id === id)
    .find((configItem) => configItem.build_data.environment === config.env);

export const selectWhitelabelConfigs = (state: AppState, id: number): Array<Config> =>
  state.whitelabels.configs.filter((item) => item.whitelabel_id === id);

export const selectConfigById = (state: AppState, id: number): Config =>
  state.whitelabels.configs.find((item) => item.id === id);
