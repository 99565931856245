import './whitelabel-table.scss';

import { BuildStatuses, PLATFORMS, TABLE_DATE_TIME_FORMAT } from 'const';
import { runWhitelabelBuild, setBuilds } from 'store/actions/Whitelabels';

import BaseTable from '../base/index';
import BuildSpinner from '../../spinner/build-spinner/BuildSpinner';
import Builds from 'models/Builds';
import ChipFailed from '../../chip-failed/ChipFailed';
import ColumnClassNames from '../models/ColumnClassNames';
import HeadCell from '../models/HeadCell';
import Option from 'models/Option';
import Row from '../models/Row';
import Whitelabel from 'models/Whitelabel';
import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import { resolveBuildLabel } from 'util/build-status.utils';
import { useDispatch } from 'react-redux';

interface WhitelabelTableData {
  appName: string;
  lastIosBuildTime: string;
  lastIosBuildStatus: string;
  lastAndroidBuildTime: string;
  lastAndroidBuildStatus: string;
}

interface WhitelabelTableProps {
  whitelabels: Array<Whitelabel>;
  className: string;
}

const columns: Array<HeadCell<WhitelabelTableData>> = [
  {
    id: 'appName',
    align: 'left',
    label: i18next.t('whitelabel-table.columns.appName'),
  },
  {
    id: 'lastIosBuildTime',
    align: 'right',
    label: i18next.t('whitelabel-table.columns.lastIosBuildTime'),
  },
  {
    id: 'lastIosBuildStatus',
    align: 'right',
    label: i18next.t('whitelabel-table.columns.lastIosBuildStatus'),
  },
  {
    id: 'lastAndroidBuildTime',
    align: 'right',
    label: i18next.t('whitelabel-table.columns.lastAndroidBuildTime'),
  },
  {
    id: 'lastAndroidBuildStatus',
    align: 'right',
    label: i18next.t('whitelabel-table.columns.lastAndroidBuildStatus'),
  },
];

const getOptions = (
  buildHandler: (id: number, platform: string) => void,
  refreshHandler: (id: number) => void
): Array<Option<Row>> => [
  {
    label: i18next.t('release-apps.whitelabel.options.buildIos'),
    handleOptionClick: (row: Row) => buildHandler(row.id, PLATFORMS.IOS),
  },
  {
    label: i18next.t('release-apps.whitelabel.options.buildAndroid'),
    handleOptionClick: (row: Row) => buildHandler(row.id, PLATFORMS.ANDROID),
  },
  {
    label: i18next.t('release-apps.whitelabel.options.refresh'),
    handleOptionClick: (row: Row) => refreshHandler(row.id),
  },
];

const formatTimeDateString = (value: string): string => (value ? moment(value).format(TABLE_DATE_TIME_FORMAT) : '');

const resolveBuildLabelList = (builds: Builds, platform: string) => {
  const label = resolveBuildLabel(builds?.data, platform);
  const platformLoading = get(builds, `loading.${platform}`, false);

  if (platformLoading || label === BuildStatuses.Loading) {
    return <BuildSpinner containerStyle="loading-status" />;
  }

  if (label === BuildStatuses.Failed) {
    return <ChipFailed chipStyle="failed-chip" />;
  }

  return label;
};

const resolveLastBuildTime = (builds: Builds, platform: string) => {
  const platformLoading = get(builds, `loading.${platform}`, false);
  return !platformLoading ? formatTimeDateString(get(builds, `data.${platform}Builds[0].finishTime`, '')) : '';
};

const parseData = (whitelabels: Array<Whitelabel>) =>
  whitelabels.map((whitelabel: Whitelabel) => {
    const { brand, builds, id } = whitelabel;
    const lastIosBuildTime = resolveLastBuildTime(builds, PLATFORMS.IOS);
    const lastAndroidBuildTime = resolveLastBuildTime(builds, PLATFORMS.ANDROID);
    const lastIosBuildStatus = resolveBuildLabelList(builds, PLATFORMS.IOS);
    const lastAndroidBuildStatus = resolveBuildLabelList(builds, PLATFORMS.ANDROID);

    return {
      id,
      data: {
        appName: brand,
        lastIosBuildTime,
        lastIosBuildStatus,
        lastAndroidBuildTime,
        lastAndroidBuildStatus,
      },
    };
  });

const getBuildStatusClassName = (value: string) => {
  switch (value) {
    case i18next.t('status.succeeded'):
      return 'succeeded';
    case i18next.t('status.warning'):
      return 'warning';
    default:
      return '';
  }
};

const columnClassNames: ColumnClassNames = {
  lastIosBuildStatus: getBuildStatusClassName,
  lastAndroidBuildStatus: getBuildStatusClassName,
};

const WhitelabelsTable = ({ whitelabels, className }: WhitelabelTableProps): JSX.Element => {
  const dispatch = useDispatch();
  const buildHandler = (id: number, platform: string) => dispatch(runWhitelabelBuild({ id, platform }));
  const refreshHandler = (id: number) => dispatch(setBuilds({ id }));
  const options = getOptions(buildHandler, refreshHandler);
  const rows = parseData(whitelabels);
  const tableProps = {
    options,
    columns,
    rows,
    columnClassNames,
    className,
  };

  return (
    <div className="whitelabel-table col-12">
      <BaseTable {...tableProps} />
    </div>
  );
};

export default WhitelabelsTable;
