import './not-found-page.scss';

import { WithTranslation, withTranslation } from 'react-i18next';

import Link from '@material-ui/core/Link';

const NotFoundPage = ({ t }: WithTranslation) => (
  <div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
    <div className="page-error-content">
      <div className="error-code mb-4 animated zoomInDown">404</div>
      <div className="error-message bounceIn animation-delay-10 animated">{t('not-found-page.message')}</div>
      <p className="text-center zoomIn animation-delay-20 animated">
        <Link href="/">{t('not-found-page.button-home')}</Link>
      </p>
    </div>
  </div>
);

export default withTranslation()(NotFoundPage);
