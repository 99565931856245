import { AddBox, Apps, Dashboard, FiberManualRecord, Settings } from '@material-ui/icons';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { MouseEvent as ReactMouseEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppState from 'models/AppState';
import CustomScrollbars from 'util/CustomScrollbars';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ROUTES } from 'const';
import { handleCreateWhitelabelModal } from 'store/actions/Modals';
import { useStyles } from './sideNavStyles';
import { useTranslation } from 'react-i18next';

const SidenavContent = ({ location }: RouteComponentProps<Record<string, string>>) => {
  const { t } = useTranslation();
  const classes = useStyles({});
  const dispatch = useDispatch();

  const isCreateDialogOpened = useSelector((store: AppState) => store.modals.whitelabel.open);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const dashboardLinks = useMemo(
    () => [
      {
        id: 1,
        name: 'sidenav.dashboard',
        path: ROUTES.DASHBOARD_MENU,
        className: classes.listItem,
        icon: <Dashboard className={classes.listItemIcon} />,
      },
      {
        id: 2,
        name: 'sidenav.create-whitelabel',
        path: '',
        className: isCreateDialogOpened ? classes.selectedCreateDialog : classes.nestedItem,
        icon: <AddBox className={classes.listItemIcon} />,
        handleClick: (event: ReactMouseEvent<HTMLElement>) => {
          event.preventDefault();
          dispatch(handleCreateWhitelabelModal());
        },
      },
      {
        id: 3,
        name: 'sidenav.manage-whitelabels',
        path: ROUTES.MANAGE_WHITELABELS,
        className: classes.nestedItem,
        icon: <Settings className={classes.listItemIcon} />,
      },
      {
        id: 4,
        name: 'sidenav.release-apps',
        path: ROUTES.RELEASE_APPS,
        className: classes.nestedItem,
        icon: <Apps className={classes.listItemIcon} />,
      },
      {
        id: 5,
        name: 'sidenav.app-status-report',
        path: ROUTES.APP_STATUS_REPORT,
        className: classes.nestedItem,
        icon: <Apps className={classes.listItemIcon} />,
      },
      {
        id: 6,
        name: 'sidenav.release-management',
        path: ROUTES.RELEASE_MANAGEMENT,
        className: classes.nestedItem,
        icon: <Settings className={classes.listItemIcon} />,
      },
    ],
    [
      classes.listItem,
      classes.listItemIcon,
      classes.nestedItem,
      classes.selectedCreateDialog,
      dispatch,
      isCreateDialogOpened,
    ]
  );

  useEffect(() => {
    dashboardLinks.forEach((link) => {
      if (location.pathname === link.path) {
        setSelectedIndex(link.id);
      }
    });
    return () => {
      setSelectedIndex(null);
    };
  }, [dashboardLinks, location.pathname]);

  return (
    <CustomScrollbars className="scrollbar">
      <List>
        {dashboardLinks.map((link) => (
          <ListItem
            key={link.id}
            component={NavLink}
            selected={selectedIndex === link.id}
            to={link.path}
            className={link.className}
            onClick={link.handleClick ? link.handleClick : undefined}
          >
            {link.icon}
            <ListItemText primary={t(link.name)} />
            {selectedIndex === link.id && <FiberManualRecord className={classes.selectedItemIcon} />}
          </ListItem>
        ))}
      </List>
    </CustomScrollbars>
  );
};
export default withRouter(SidenavContent);
