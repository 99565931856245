import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { MouseEvent as ReactMouseEvent } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

interface ActionButtonProps {
  title: string;
  onClick: (event: ReactMouseEvent<unknown>) => void;
  icon: JSX.Element;
}

const ActionButton = ({ title, onClick, icon }: ActionButtonProps): JSX.Element => (
  <ListItemSecondaryAction className="add-action">
    <Tooltip title={title} placement="top" arrow>
      <IconButton className="add-button" onClick={onClick}>
        {icon}
      </IconButton>
    </Tooltip>
  </ListItemSecondaryAction>
);

export default ActionButton;
