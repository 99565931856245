import './login.scss';

import { EU_REGION_OPTION_ID, ROUTES, Region, US_REGION_OPTION_ID } from 'const';
import { FormEvent, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { getHelixRegion, setHelixRegion } from '../../services/helix/helix.api';
import { hideMessage, userSignIn } from 'store/actions';
import { selectAlertMessage, selectAuthUser, selectIsMessageVisible } from 'store/selectors/auth.selector';
import { useDispatch, useSelector } from 'react-redux';

import AppState from '../../models/AppState';
import Button from '@material-ui/core/Button';
import { DEFAULT_VALIDATION_OPTIONS } from 'constants/ActionTypes';
import HelixRegionDropDown from '../select-drop-down/helix-region';
import LogoPng from 'assets/images/logo.png';
import ReactHookFormError from 'models/ReactHookFormError';
import User from 'models/User';
import ValidationTextField from '../validation-text-field';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Login = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authUser = useSelector<AppState, User>(selectAuthUser);
  const alertMessage = useSelector<AppState, string>(selectAlertMessage);
  const isMessageVisible = useSelector<AppState, boolean>(selectIsMessageVisible);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [region, setRegion] = useState<Region>(getHelixRegion() === 'us' ? US_REGION_OPTION_ID : EU_REGION_OPTION_ID);
  const {
    formState: { errors },
    control,
  } = useForm(DEFAULT_VALIDATION_OPTIONS);
  const isSignInEnabled = isEmpty(errors) && email && password;

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    setHelixRegion(region);
    dispatch(userSignIn({ email, password }));
  }

  useEffect(() => {
    if (isMessageVisible) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 500);
      NotificationManager.error(alertMessage);
    }
  }, [isMessageVisible, dispatch, alertMessage]);

  if (authUser) {
    return <Redirect to={ROUTES.DASHBOARD_MENU} />;
  }

  return (
    <div className="login-container animated slideInUpTiny animation-duration-3">
      <div className="login-main-content">
        <div className="logo-content">
          <Link to="/">
            <img src={LogoPng} alt="" className="logo-img" />
          </Link>
        </div>
        <div className="login-content">
          <h1 className="mb-sm-4">{t('login.header')}</h1>
          <form onSubmit={handleSubmit}>
            <HelixRegionDropDown handleOptionClick={setRegion} defaultIndex={region === 'us' ? 0 : 1} />
            <ValidationTextField
              name="email"
              setValue={setEmail}
              errors={errors as unknown as ReactHookFormError}
              control={control}
              type="email"
              fullWidth
              required
              data-cy="email"
              lockable={false}
            />
            <ValidationTextField
              name="password"
              setValue={setPassword}
              errors={errors as unknown as ReactHookFormError}
              control={control}
              type="password"
              label={t('login.password')}
              fullWidth
              required
              data-cy="pw"
              lockable={false}
            />

            <Button type="submit" variant="contained" color="primary" disabled={!isSignInEnabled} data-cy="submit">
              {t('login.sign-in')}
            </Button>
          </form>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
};

export default Login;
