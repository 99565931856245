import './card-release.scss';

import { ChangeEvent, MouseEvent as ReactMouseEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import WidgetCard from '../../components/widget-card/WidgetCard';

interface CardReleaseProps extends WithTranslation {
  releaseNotesInputValue: string;
  setNotesHandler: (notes: string) => void;
  saveNotesHandler: (event: ReactMouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>) => void;
}

const CardRelease = ({ releaseNotesInputValue, setNotesHandler, saveNotesHandler, t }: CardReleaseProps) => {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setNotesHandler(value);
  }

  return (
    <WidgetCard styleName="release-notes-card">
      <div className="notes-wrapper">
        <TextField
          classes={{ root: 'notes-input' }}
          label={t('release-apps.card-release.input-placeholder', { branch: process.env.REACT_APP_CENTER_BRANCH })}
          multiline
          maxRows="2"
          value={releaseNotesInputValue}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
      </div>
      <div>
        <Button onClick={saveNotesHandler} variant="contained" color="primary">
          {t('release-apps.card-release.button-save-notes')}
        </Button>
      </div>
    </WidgetCard>
  );
};

export default withTranslation()(CardRelease);
