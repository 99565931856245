import { PRE_PROD_OPTION_ID, PRODUCTION_OPTION_ID, SET_DEFAULT_OPTION_ID } from 'const';

import Option from 'models/Option';
import SelectDropDown from '../base/index';
import i18n from 'i18next';

const HelixEnvironmentDropDown = (): JSX.Element => {
  const defaultOptions: Array<Option<string>> = [
    {
      id: SET_DEFAULT_OPTION_ID,
      label: i18n.t('options.helix.setDefault'),
      handleOptionClick: () => {
        /* do nothing */
      },
    },
  ];
  const options: Array<Option<string>> = [
    {
      id: PRODUCTION_OPTION_ID,
      label: i18n.t('options.helix.production'),
      handleOptionClick: () => {
        /* do nothing */
      },
    },
    {
      id: PRE_PROD_OPTION_ID,
      label: i18n.t('options.helix.pre-prod'),
      handleOptionClick: () => {
        /* do nothing */
      },
    },
  ];

  return (
    <SelectDropDown
      defaultOptions={defaultOptions}
      options={options}
      defaultValue={i18n.t('options.helix.default')}
      className="select-drop-down"
    />
  );
};

export default HelixEnvironmentDropDown;
