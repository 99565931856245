import { NotificationManager } from 'react-notifications';
import config from 'config';

export const { omegaMicroserviceBaseURL } = config;

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

// Resolve Omega backend query
export async function resolveOmegaQuery<Payload>(
  url: string,
  payload?: Payload,
  method: Method = 'GET'
): Promise<Response | undefined> {
  try {
    return await fetch(`${omegaMicroserviceBaseURL}${url}`, {
      method,
      body: payload ? JSON.stringify(payload) : undefined,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  } catch (e) {
    NotificationManager.warning(e.message);
  }

  return undefined;
}

export async function getOmegaData<Result>(
  url: string,
  defaultResult: Result = [] as unknown as Result
): Promise<Result> {
  const result = await resolveOmegaQuery(url);
  return result ? ((await result.json()) as Result) : defaultResult;
}

export async function resolveOmegaAction<Payload>(
  url: string,
  payload?: Payload,
  method: Method = 'POST'
): Promise<boolean> {
  const result = await resolveOmegaQuery(url, payload, method);
  return result ? result.ok : false;
}
