import 'assets/vendors/style';

import { BrowserRouter, Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom';
import Spinner, { SpinnerStyle } from '../components/spinner';

import AppLocale from '../language-provider';
import AppState from 'models/AppState';
import { Component } from 'react';
import { IntlProvider } from 'react-intl';
import Locale from 'models/Locale';
import Login from '../components/login/Login';
import MainApp from '../dashboard';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import NotFoundPage from '../components/not-found-page/NotFoundPage';
import { ROUTES } from '../const';
import { ThemeProvider } from '@material-ui/styles';
import User from 'models/User';
import blueTheme from '../themes/blueTheme';
import { connect } from 'react-redux';
import { createMuiTheme } from '@material-ui/core/styles';
import { setInitUrl } from '../store/actions';
import { usePromiseTracker } from 'react-promise-tracker';

interface IRestrictedRoute extends RouteProps {
  authUser: User;
}

interface AppContainerProps extends RouteComponentProps {
  locale: Locale;
  authUser: User;
  initURL: string;
  setInitUrl: (value: string) => void;
}

const RestrictedRoute = ({ component: RestrictedComponent, authUser, ...rest }: IRestrictedRoute) => {
  function renderComponent(props: RouteComponentProps) {
    return authUser ? (
      <RestrictedComponent {...props} />
    ) : (
      <Redirect
        to={{
          pathname: ROUTES.LOGIN,
          state: { from: props.location },
        }}
      />
    );
  }
  return <Route {...rest} render={renderComponent} />;
};

const LoadingIndicator = () => {
  const { promiseInProgress: isPromiseInProgress } = usePromiseTracker();
  const spinnerProps = { style: SpinnerStyle.GLOBAL };
  return isPromiseInProgress && <Spinner {...spinnerProps} />;
};

class AppContainer extends Component<AppContainerProps> {
  componentDidMount() {
    const { props } = this;
    const { initURL, history } = props;
    if (initURL === '') {
      props.setInitUrl(history.location.pathname);
    }
  }

  render() {
    const { location, locale, authUser, initURL } = this.props;
    const theme = createMuiTheme(blueTheme);

    if (location.pathname === '/') {
      if (authUser === null) {
        return <Redirect to={ROUTES.LOGIN} />;
      }
      if (initURL === '' || initURL === '/' || initURL === ROUTES.LOGIN) {
        return <Redirect to={ROUTES.DASHBOARD_MENU} />;
      }
      return <Redirect to={initURL} />;
    }

    const currentAppLocale = AppLocale[locale.locale];

    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <div className="app-main">
              <BrowserRouter>
                <Switch>
                  <RestrictedRoute path={ROUTES.DASHBOARD} authUser={authUser} component={MainApp} />
                  <Route path={ROUTES.LOGIN} component={Login} />
                  <Route component={NotFoundPage} />
                </Switch>
              </BrowserRouter>
              <LoadingIndicator />
            </div>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }: AppState) => {
  const { themeColor, locale } = settings;
  const { authUser, initURL } = auth;

  return { themeColor, locale, authUser, initURL };
};

export default connect(mapStateToProps, { setInitUrl })(AppContainer);
