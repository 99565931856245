import './helix-region-drop-down.scss';

import { EU_REGION_OPTION_ID, Region, US_REGION_OPTION_ID } from 'const';

import Option from 'models/Option';
import SelectDropDown from '../base/index';
import i18n from 'i18next';

interface HelixRegionDropDownProps {
  handleOptionClick: (argument: Region) => void;
  defaultIndex: number;
}

const HelixRegionDropDown = ({ handleOptionClick, defaultIndex }: HelixRegionDropDownProps): JSX.Element => {
  const options: Array<Option<string>> = [
    {
      id: US_REGION_OPTION_ID,
      label: i18n.t('options.region.us'),
      handleOptionClick,
    },
    {
      id: EU_REGION_OPTION_ID,
      label: i18n.t('options.region.eu'),
      handleOptionClick,
    },
  ];

  return (
    <div className="helix-region-container">
      <SelectDropDown options={options} defaultValue={options[defaultIndex].label} className="helix-region-drop-down" />
    </div>
  );
};

export default HelixRegionDropDown;
