export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const WINDOW_WIDTH = 'window-width';

export const INSIDE_THE_HEADER = 'inside_the_header';

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';

interface ValidationOptions {
  mode: 'onBlur' | 'onChange' | 'onSubmit';
  reValidateMode: 'onBlur' | 'onChange' | 'onSubmit';
}

export const DEFAULT_VALIDATION_OPTIONS: ValidationOptions = {
  mode: 'onBlur',
  reValidateMode: 'onChange',
};

/* Whitelabels actions */

export const CREATE_WHITELABEL = 'create_whitelabel';
export const CREATE_WHITELABEL_SUCCESS = 'create_whitelabel_success';
export const UPDATE_WHITELABEL = 'update_whitelabel';
export const UPDATE_WHITELABEL_SUCCESS = 'update_whitelabel_success';
export const DELETE_WHITELABEL = 'delete_whitelabels';
export const DELETE_WHITELABEL_SUCCESS = 'delete_whitelabels_success';
export const GET_WHITELABELS = 'get_whitelabels';
export const GET_WHITELABELS_SUCCESS = 'get_whitelabels_success';
export const GET_WHITELABEL = 'get_whitelabel';
export const GET_WHITELABEL_SUCCESS = 'get_whitelabel_success';
export const SET_BUILDS = 'set_builds';
export const SET_BUILDS_SUCCESS = 'set_builds_success';
export const SET_BUILDS_FAILURE = 'set_builds_failure';
export const GET_RELEASE_NOTES = 'get_release_notes';
export const GET_RELEASE_NOTES_SUCCESS = 'get_release_notes_success';
export const UPLOAD_CONFIG_CERTIFICATE = 'upload_config_certificate';

export const CHANGE_APPS_VERSION = 'change_apps_version';
export const CHANGE_APPS_VERSION_SUCCESS = 'change_apps_version_success';
export const RUN_WHITELABEL_BUILD = 'run_whitelabel_build';
export const RUN_WHITELABEL_BUILD_SUCCESS = 'run_whitelabel_build_success';
export const CANCEL_WHITELABEL_BUILD = 'cancel_whitelabel_build';
export const CANCEL_WHITELABEL_BUILD_SUCCESS = 'cancel_whitelabel_build_success';

export const SET_BUILD_LOADING = 'set_build_loading';

export const GET_CONFIG = 'get_config';
export const GET_CONFIG_SUCCESS = 'get_config_success';
export const UPDATE_CONFIG = 'update_config';
export const UPDATE_CONFIG_SUCCESS = 'update_config_success';
export const CREATE_CONFIG = 'create_config';
export const CREATE_CONFIG_SUCCESS = 'create_config_success';
export const DELETE_CONFIG = 'delete_config';
export const DELETE_CONFIG_SUCCESS = 'delete_config_success';

/* Modals actions */
export const CREATE_CONFIG_MODAL = 'create_config_modal';
export const CLOSE_CREATE_CONFIG_MODAL = 'close_create_config_modal';
export const CREATE_WHITELABEL_MODAL = 'create_whitelabel_modal';
export const EDIT_WHITELABEL_MODAL = 'edit_whitelabel_modal';
export const CLOSE_WHITELABEL_MODAL = 'close_whitelabel_modal';
export const DELETE_ITEM_MODAL = 'delete_item_modal';
export const CONFIRM_CANCEL_ALL_MODAL = 'confirm_cancel_all_modal';

export const CREATE_RELEASE_MODAL = 'create_release_modal';
export const CLOSE_RELEASE_MODAL = 'close_release_modal';

/* Release actions */
export const CREATE_RELEASE = 'create_release';
export const UPDATE_RELEASE = 'update_release';
