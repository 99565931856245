import { useCallback, useEffect, useState } from 'react';

import GitBranchServerResponse from '../../../models/GitBranchServerResponse';
import { NotificationManager } from 'react-notifications';
import Option from 'models/Option';
import SelectDropDown from '../base/index';
import { getOmegaData } from '../../../util/release-management.utils';
import i18next from 'i18next';

interface GitBranchDropDownProps {
  updateStateCallback: React.Dispatch<React.SetStateAction<string>>;
  updateCommitOnChoiceCallback: React.Dispatch<React.SetStateAction<string>>;
  initialValue?: string;
  platform?: string;
}

const GitBranchDropDown = ({
  updateStateCallback: cb = null,
  updateCommitOnChoiceCallback: ccb = null,
  initialValue,
  platform,
}: GitBranchDropDownProps): JSX.Element => {
  const [options, setOptions] = useState([]);
  const defaultOptions: Array<Option<string>> = [];

  const getGitBranches = useCallback(async () => {
    try {
      const query = platform === 'IOS' ? 'ios' : 'android';
      const uri = `/v1/branches?repo=tenant-${query}`;
      const branches = await getOmegaData<Array<GitBranchServerResponse>>(uri);
      setOptions(
        branches.map(({ name, commit }) => ({
          id: name,
          label: name,
          handleOptionClick: () => ccb(commit.sha),
        }))
      );
    } catch (e) {
      NotificationManager.warning(e.message);
    }
  }, [platform, ccb]);

  useEffect(() => {
    if (platform) {
      getGitBranches();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [platform, getGitBranches]);

  return (
    <SelectDropDown
      disabled={!platform}
      initialValue={initialValue}
      defaultOptions={defaultOptions}
      options={options}
      defaultValue={i18next.t('release-management.selects.branch')}
      onChangeCallback={cb}
      className="select-drop-down"
    />
  );
};

GitBranchDropDown.defaultProps = {
  initialValue: '',
  platform: '',
};

export default GitBranchDropDown;
