import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Check, Close } from '@material-ui/icons';
import { INPUT_NAMES, MAIN_SECTIONS_FIELDS } from 'const';
import { isEmpty, lowerCase, upperFirst } from 'lodash';

import { Button } from '@material-ui/core';
import ConfigSection from 'models/ConfigSection';
import ConfigSectionData from 'models/ConfigSectionData';
import { DEFAULT_VALIDATION_OPTIONS } from 'constants/ActionTypes';
import EditIcon from '@material-ui/icons/Edit';
import MiscSectionDate from 'models/MiscSectiondata';
import ReactHookFormError from 'models/ReactHookFormError';
import ValidationTextField from 'components/validation-text-field';
import { handleInput } from 'util/input.utils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface SectionProps extends ConfigSection {
  miscSection?: MiscSectionDate;
  sorted?: boolean;
  handleSave?: () => void;
  resetFields?: () => void;
}

const Section = ({
  section,
  sectionName,
  title,
  setSection,
  requiredFields = [],
  miscSection,
  sorted,
  handleSave,
  resetFields,
}: SectionProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    setError,
    clearErrors,
  } = useForm(DEFAULT_VALIDATION_OPTIONS);
  const isSaveEnabled = isEmpty(errors);
  const [isEdit, setEditMode] = useState<boolean>(false);

  const resolveLabel = (field: string) =>
    field === MAIN_SECTIONS_FIELDS.ITUNES_URL
      ? t('release-apps.config-info.config-tab.labels.i-tunes-url')
      : upperFirst(lowerCase(field));

  function setValue(name: string) {
    return (value: string) => {
      if (sectionName) {
        return setSection({
          ...miscSection,
          [sectionName]: {
            ...(miscSection[sectionName] as ConfigSectionData),
            [name]: value,
          },
        });
      }

      return setSection({ ...section, [name]: value });
    };
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => handleInput(event, INPUT_NAMES.VERSION);
  let keys = Object.keys(section);
  if (sorted) {
    keys = keys.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, required, value } = event.target;
    if (required && isEmpty(value)) {
      setError(name, { type: 'custom', message: 'This field is required.' });
      return;
    }

    clearErrors(name);
  };

  function handleSectionSave() {
    handleSave();
    setEditMode(!isEdit);
  }

  function resetSection() {
    resetFields();
    setEditMode(!isEdit);
  }

  function handleEditModeChange() {
    setEditMode(!isEdit);
  }

  return (
    <div className={miscSection ? '' : 'section-container'}>
      <div className={miscSection ? 'section-title' : 'title'}>
        {title}
        {isEdit ? (
          <span>
            <Button onClick={handleSectionSave} size="small" disabled={!isSaveEnabled}>
              <Check />
            </Button>
            <Button onClick={resetSection} size="small">
              <Close />
            </Button>
          </span>
        ) : (
          <Button onClick={handleEditModeChange} size="small">
            <EditIcon />
          </Button>
        )}
      </div>
      <div className="fields-container">
        {keys.map((name) => {
          const isRequired = requiredFields.includes(name);
          return (
            <ValidationTextField
              key={name}
              className="text-field"
              label={resolveLabel(name)}
              name={name}
              setValue={setValue(name)}
              errors={errors as unknown as ReactHookFormError}
              control={control}
              type="text"
              required={isRequired}
              value={section[name] || ''}
              disabled={!isEdit}
              onChange={handleFieldChange}
              onKeyPress={name === MAIN_SECTIONS_FIELDS.VERSION && handleKeyPress}
            />
          );
        })}
      </div>
    </div>
  );
};

Section.defaultProps = {
  miscSection: null,
  sorted: false,
  handleSave: () => {
    /* do nothing */
  },
  resetFields: () => {
    /* do nothing */
  },
};

export default Section;
