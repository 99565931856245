import './toggle.scss';

import { MouseEvent } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

export enum ViewTypes {
  Module = 'module',
  List = 'list',
}

export type ViewType = ViewTypes.Module | ViewTypes.List;

interface ReleaseAppsToggleButtonProps {
  viewHandler: (event: MouseEvent<HTMLElement>, viewFormat: ViewType) => void;
  value: ViewType;
}

const MODULE_BUTTON_ID = 1;
const LIST_BUTTON_ID = 2;

// eslint-disable-next-line react/function-component-definition
export default function ReleaseAppsToggleButton({ viewHandler, value }: ReleaseAppsToggleButtonProps): JSX.Element {
  const children = [
    <ToggleButton key={MODULE_BUTTON_ID} value={ViewTypes.Module}>
      <ViewModuleIcon />
    </ToggleButton>,
    <ToggleButton key={LIST_BUTTON_ID} value={ViewTypes.List}>
      <ViewListIcon />
    </ToggleButton>,
  ];

  return (
    <ToggleButtonGroup size="small" value={value} exclusive onChange={viewHandler} className="toggle-group">
      {children}
    </ToggleButtonGroup>
  );
}
