import './theme-section.scss';

import { Button, TextField } from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import { cloneDeep, omit } from 'lodash';

import { Add } from '@material-ui/icons';
import AddFont from 'components/dialogs/add-font/AddFont';
import ColorPicker from 'components/color-picker/ColorPicker';
import Config from 'models/Config';
import FontElement from './FontElement';
import Theme from 'models/Theme';
import { useTranslation } from 'react-i18next';

type ThemeColor = '$primary1' | '$primary2' | '$accent1' | '$accent2' | '$accent3' | '$accent4';
interface ThemeSectionProps {
  themeSection: Theme;
  setThemeSection: (value: Theme) => void;
  saveConfig: (c: Config) => void;
  newConfig: Config;
}

const ThemeSection = ({ themeSection, setThemeSection, saveConfig, newConfig }: ThemeSectionProps): JSX.Element => {
  const fontList = (themeSection.fonts && Object.keys(themeSection.fonts).map((x) => x.trim())) || [];
  const { t } = useTranslation();
  const [colorPickerPayload, setColorPickerPayload] = useState({
    color: null,
    setItemColor: null,
  });
  const [isOpened, setOpened] = useState<boolean>(false);
  const [isAddFontOpened, setAddFontOpened] = useState<boolean>(false);

  const openColorPicker = (color: string, setItemColor: (itemColor: string) => void) => {
    setColorPickerPayload({ color, setItemColor });
    setOpened(true);
  };

  function openAddFont() {
    setAddFontOpened(true);
  }

  function closeAddFont() {
    setAddFontOpened(false);
  }

  function closeColorPicker() {
    setColorPickerPayload({ color: null, setItemColor: null });
    setOpened(false);
  }

  const colorsHash = omit(themeSection, '$theme');

  function updateConfig(newThemeSection: Theme) {
    const configToSave = cloneDeep(newConfig);
    configToSave.build_data.theme = newThemeSection;
    saveConfig(configToSave);
    setThemeSection(newThemeSection);
  }

  function handleChangeTheme(event: ChangeEvent<HTMLInputElement>) {
    setThemeSection({ ...themeSection, $theme: event.target.value });
  }

  function handleBlurTheme() {
    saveConfig(newConfig);
  }

  // eslint-disable-next-line max-len
  function handleColorPickerInteraction(
    themeColor: ThemeColor,
    eventType: 'click' | 'press'
  ): (event: unknown) => void {
    const currentColor = themeSection[themeColor];
    const setItemColor = (color: string) => updateConfig({ ...themeSection, [themeColor]: color });
    if (eventType === 'click') {
      return () => openColorPicker(currentColor, setItemColor);
    }
    if (eventType === 'press') {
      return (event: KeyboardEvent) => {
        const { key } = event;
        if (key === 'Enter') {
          return openColorPicker(currentColor, setItemColor);
        }
        return null;
      };
    }
    return () => {
      /* do nothing */
    };
  }

  function handleSaveFont(placement: string, url: string, fontFamily: string) {
    if (fontList?.includes(placement)) {
      return;
    }
    updateConfig({
      ...themeSection,
      fonts: {
        ...themeSection.fonts,
        [placement]: {
          url,
          font_family: fontFamily,
        },
      },
    });
  }

  return (
    <div className="theme-section-container">
      <div className="title">{t('release-apps.config-info.config-tab.sections.theme')}</div>
      <div className="fields-container">
        <TextField
          className="text-field"
          label={t('release-apps.config-info.config-tab.labels.theme-title')}
          value={themeSection.$theme}
          onChange={handleChangeTheme}
          onBlur={handleBlurTheme}
          autoComplete="off"
        />
      </div>
      <div className="color-previews-container">
        {Object.keys(colorsHash)
          .filter((x) => x !== 'fonts')
          .map((themeColor: ThemeColor) => (
            <div className="color-preview-container" key={themeColor}>
              <div>{`${themeColor}:`}</div>
              <div
                className="color-preview"
                aria-label="color-preview"
                tabIndex={0}
                role="button"
                style={{ backgroundColor: themeSection[themeColor] }}
                onClick={handleColorPickerInteraction(themeColor, 'click')}
                onKeyPress={handleColorPickerInteraction(themeColor, 'press')}
              />
            </div>
          ))}
        {isOpened && <ColorPicker {...colorPickerPayload} handleClose={closeColorPicker} />}
      </div>
      <div className="fonts-container">
        <div className="subtitle">
          {t('release-apps.config-info.config-tab.sections.fonts')}
          <Button onClick={openAddFont}>
            <Add />
          </Button>
          <AddFont open={isAddFontOpened} onClose={closeAddFont} onSubmit={handleSaveFont} fontList={fontList} />
        </div>
        {fontList.length
          ? fontList.map((placement) => (
              <FontElement
                key={placement}
                initialPlacement={placement}
                themeSection={themeSection}
                changeTheme={updateConfig}
                otherFonts={fontList.filter((x) => x !== placement.trim())}
              />
            ))
          : t('release-apps.fonts.empty')}
      </div>
    </div>
  );
};

export default ThemeSection;
