import './google-services-section.scss';

import { Check, Close } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GoogleServices from 'models/GoogleServices';
import JSONInput from 'react-json-editor-ajrm';
import JSONInputValue from 'models/JSONInputValue';
import { JSON_INPUT_THEME } from 'const';
import MiscSectionDate from 'models/MiscSectiondata';
import _ from 'lodash';
import locale from 'react-json-editor-ajrm/locale/en';
import { resolveJsonInputStyle } from 'util/whitelabel-config.utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface GoogleServicesSectionProps {
  miscSection: MiscSectionDate;
  setMiscSection: (value: MiscSectionDate) => void;
  handleSave?: () => void;
  resetFields?: () => void;
}

const GoogleServicesSection = ({
  miscSection,
  setMiscSection,
  handleSave,
  resetFields,
}: GoogleServicesSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const [isEdit, setEditMode] = useState<boolean>(false);

  function handleSectionSave() {
    handleSave();
    setEditMode(!isEdit);
  }

  function resetSection() {
    resetFields();
    setEditMode(!isEdit);
  }

  function handleChangeEditMode() {
    setEditMode(!isEdit);
  }

  function handleJsonChange(value: JSONInputValue<GoogleServices>) {
    const newServices = _.cloneDeep(value.jsObject);
    setMiscSection({
      ...miscSection,
      googleServices: { ...newServices },
    });
  }

  return (
    <div className="google-services-container">
      <div className="section-title">
        {t('release-apps.config-info.config-tab.sections.google-services')}
        {isEdit ? (
          <span>
            <Button onClick={handleSectionSave} size="small">
              <Check />
            </Button>
            <Button onClick={resetSection} size="small">
              <Close />
            </Button>
          </span>
        ) : (
          <Button onClick={handleChangeEditMode} size="small">
            <EditIcon />
          </Button>
        )}
      </div>
      <JSONInput
        id="googleServices"
        placeholder={miscSection.googleServices}
        theme={JSON_INPUT_THEME}
        locale={locale}
        style={resolveJsonInputStyle('500px', '100%', '13px')}
        viewOnly={!isEdit}
        onChange={handleJsonChange}
      />
    </div>
  );
};

GoogleServicesSection.defaultProps = {
  handleSave: () => {
    /* do nothing */
  },
  resetFields: () => {
    /* do nothing */
  },
};

export default GoogleServicesSection;
