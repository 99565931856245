import './footer.scss';

import AppState from 'models/AppState';
import moment from 'moment';
import { useSelector } from 'react-redux';

const Footer = (): JSX.Element => {
  const companyName = useSelector((store: AppState) => store.settings.companyName);
  return (
    <footer className="app-footer">
      <span className="d-inline-block">
        Copyright&nbsp;
        {companyName} &copy;
        {moment().format('Y')}
      </span>
    </footer>
  );
};

export default Footer;
