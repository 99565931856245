const config = {
  apiURL: window.localStorage.farm ? `${JSON.parse(window.localStorage.farm).url}/api/` : process.env.REACT_APP_API_URL,
  env: process.env.REACT_APP_API_ENV,
  layerKey: process.env.REACT_APP_LAYER_KEY,
  appcenterBranch: process.env.REACT_APP_CENTER_BRANCH,
  appcenterUrl: process.env.REACT_APP_APPCENTER_URL,
  appcenterUiUrl: process.env.REACT_APP_APPCENTER_UI_URL,
  imgixDomain: process.env.REACT_APP_IMGIX_DOMAIN,
  omegaMicroserviceBaseURL: process.env.REACT_APP_OMEGA_SERVICE_URL,
};

export const euConfig = {
  apiUrl: window.localStorage.eufarm
    ? `${JSON.parse(window.localStorage.eufarm).url}/api/`
    : process.env.REACT_APP_EU_API_URL,
};

export default config;
