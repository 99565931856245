import './select.scss';

import { ChangeEvent, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import WhitelabelOption from './WhitelabelOption';

interface SelectProps {
  onChange: (whitelabel: WhitelabelOption) => (event: ChangeEvent<HTMLInputElement>) => void;
  whitelabels: Array<WhitelabelOption>;
  selected: Set<string>;
}
const Select = ({ whitelabels, selected, onChange }: SelectProps): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const filteredWhitelabels = whitelabels.filter((whitelabel) =>
    whitelabel.label.toLowerCase().includes(search.toLowerCase())
  );

  function onSearchChange(event: ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  return (
    <>
      <input
        className="select-search"
        value={search}
        onChange={onSearchChange}
        placeholder="Type anything to search..."
      />
      <div className="check-select">
        {filteredWhitelabels.map((whitelabel) => (
          <label
            className="check-select__option"
            key={whitelabel.key}
            htmlFor={whitelabel.key}
            title={whitelabel.label}
          >
            <Checkbox
              id={whitelabel.key}
              checked={selected.has(whitelabel.key)}
              onChange={onChange(whitelabel)}
              color="primary"
            />
            <p className="check-select__option__text">{whitelabel.label}</p>
          </label>
        ))}
      </div>
    </>
  );
};

export default Select;
