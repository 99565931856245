import './user-info.scss';

import { KeyboardEvent as ReactKeyboardEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppState from 'models/AppState';
import Avatar from '@material-ui/core/Avatar';
import IntlMessages from 'util/IntlMessages';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import User from 'models/User';
import { getHelixRegion } from '../../services/helix/helix.api';
import { userSignOut } from '../../store/actions';

export const UserInfo = (): JSX.Element => {
  const helixRegion: string = getHelixRegion().toUpperCase();
  const dispatch = useDispatch();
  const authUser = useSelector((store: AppState): User => store.auth.authUser);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const menuAnchor = useRef<HTMLDivElement>(null);

  function handleClick() {
    setIsOpened(true);
  }

  function handleKeyPress(event: ReactKeyboardEvent<HTMLHeadElement>) {
    const { key } = event;
    if (key === 'Enter') {
      handleClick();
    }
  }

  function handleRequestClose() {
    setIsOpened(false);
  }

  function handleLogOut() {
    handleRequestClose();
    dispatch(userSignOut());
  }

  return (
    <div className="user-profile" ref={menuAnchor}>
      <Avatar className="bg-warning m-1">
        <h1 className="m-0 text-white">{authUser.first_name[0]}</h1>
      </Avatar>
      <div className="user-detail" role="button" tabIndex={0} onClick={handleClick} onKeyPress={handleKeyPress}>
        <h5 className="user-name">
          {authUser.first_name} {authUser.last_name} <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
        </h5>
        <h6 className="region-name">{helixRegion} Region</h6>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        open={isOpened}
        anchorEl={menuAnchor.current}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <MenuItem className="d-none" onClick={handleRequestClose}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.profile" />
        </MenuItem>
        <MenuItem className="d-none" onClick={handleRequestClose}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.setting" />
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Menu>
    </div>
  );
};
