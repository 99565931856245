import './config-tab.scss';

import { CONFIG_ASSETS, JSON_INPUT_THEME, MAIN_SECTIONS_FIELDS, ROUTES, generalConfigExcludeFields } from 'const';
import { cloneDeep, omit } from 'lodash';
import { convertObjectToArray, isCustomConfigUtil, resolveJsonInputStyle } from 'util/whitelabel-config.utils';

import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Assets from 'models/Assets';
import AssetsSection from './assets-section/AssetsSecton';
import BranchKeySection from './plist-section/branch-key-section/BranchKeySection';
import Button from '@material-ui/core/Button';
import CertificateSection from './certificate-section/CertificateSection';
import ColorsSection from './misc-section/colors-section/ColorsSection';
import Config from 'models/Config';
import ConfigSection from 'models/ConfigSection';
import ConfigSectionData from 'models/ConfigSectionData';
import { DELETE_CONFIG } from 'constants/ActionTypes';
import GoogleServicesSection from './misc-section/google-services-section/GoogleServicesSection';
import ImagesHash from 'models/ImagesHash';
import ImagesSection from './misc-section/images-section/ImagesSection';
import IosEntitSection from './misc-section/ios-entit-section/IosEntitSection';
import JSONInput from 'react-json-editor-ajrm';
import JSONInputValue from 'models/JSONInputValue';
import MiscSection from './misc-section/MiscSection';
import MiscSectionDate from 'models/MiscSectiondata';
import ModesSection from './plist-section/modes-section/ModesSection';
import Paper from '@material-ui/core/Paper';
import { PlistData } from 'models/PlistData';
import Section from './section/Section';
import SpreedlyDevelopmentSection from './plist-section/spreedly-development-section/SpreedlyDevelopmentSection';
import SpreedlyProductionSection from './plist-section/spreedly-production-section/SpreedlyProductionSection';
import Theme from 'models/Theme';
import ThemeSection from './theme-section/ThemeSection';
import Toolbar from '@material-ui/core/Toolbar';
import TypesSection from './plist-section/types-section/TypesSection';
import ZendeskSection from './plist-section/zendesk-section/ZendeskSection';
import defaultBuildData from 'constants/defaultBuildData';
import { handleDeleteItemModal } from 'store/actions';
import locale from 'react-json-editor-ajrm/locale/en';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfigTabProps {
  config: Config;
  whitelabelId: number;
  updateParent: (c: Config) => void;
  updateImagesHash: (cd: ImagesHash) => void;
}

const ConfigTab = ({ config, whitelabelId, updateParent, updateImagesHash }: ConfigTabProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const isCustomConfig = isCustomConfigUtil(config);

  const [customConfig, setCustomConfig] = useState<unknown>(config);

  const zendesk = {
    ...(defaultBuildData.plist.zendesk as ConfigSectionData),
    ...(config.build_data.plist?.zendesk as ConfigSectionData),
  };

  const [plistSection, setPlistSection] = useState<PlistData>({
    ...defaultBuildData.plist,
    ...config.build_data.plist,
    zendesk,
  });

  const pSection = plistSection as ConfigSectionData;

  const [miscSection, setMiscSection] = useState<MiscSectionDate>({
    ...(omit(
      {
        ...defaultBuildData,
        ...config.build_data,
      },
      convertObjectToArray(MAIN_SECTIONS_FIELDS)
    ) as MiscSectionDate),
    policies: {
      ...config.build_data.policies,
      DELETE_YOUR_ACCOUNT: config.build_data.policies.DELETE_YOUR_ACCOUNT || '',
    },
  });

  const mSection = miscSection as ConfigSectionData;

  const [genSection, setGenSection] = useState<ConfigSectionData>({
    environment: config.environment,
    ...omit(config.build_data.appcenterConfig, generalConfigExcludeFields),
    ...omit(pSection, generalConfigExcludeFields),
    ...omit(mSection, generalConfigExcludeFields),
    proxySDK: config.build_data.proxySDK,
    openpathSDK: config.build_data.openpathSDK,
    mixpanelToken: config.build_data.mixpanelToken,
    moduleName: config.build_data.plist?.moduleName?.toString() || '',
    launchDarklyMobileKey: config.build_data.launchDarklyMobileKey,
    launchDarklySdkKey: config.build_data.launchDarklySdkKey,
    launchDarklyClientId: config.build_data.launchDarklyClientId,
  });

  const [iosSection, setIosSection] = useState<ConfigSectionData>({
    iTunesURL: config.build_data.iTunesURL,
    iosBundleName: config.build_data.iosBundleName,
    ascLink: config.asc_link,
    brazeApiKey: config.build_data.plist?.brazeApiKey?.toString() || '',
    CFBundleDisplayName: config.build_data.plist?.CFBundleDisplayName?.toString() || '',
    CFBundleIdentifier: config.build_data.plist?.CFBundleIdentifier?.toString() || '',
    CFBundleShortVersionString: config.build_data.plist?.CFBundleShortVersionString?.toString() || '',
    embraceIosKeyNative: config.build_data.plist?.embraceIosKeyNative?.toString() || '',
    iosAppId: config.build_data.appcenterConfig.iosAppId,
    iosAppKey: config.build_data.iosAppKey,
    NSCameraUsageDescription: config.build_data.plist?.NSCameraUsageDescription?.toString() || '',
  });

  const [androidSection, setAndroidSection] = useState<ConfigSectionData>({
    androidBundleName: config.build_data.androidBundleName,
    androidDisplayName: config.build_data.androidDisplayName,
    gpcLink: config.gpc_link,
    segmentAndroidWriteKey: config.build_data.segmentAndroidWriteKey,
    androidKeystore: config.build_data.androidKeystore,
    brazeAndroidApiKey: config.build_data.brazeAndroidApiKey,
    brazeAndroidFireBaseSenderId: config.build_data.brazeAndroidFireBaseSenderId,
    fcmSenderId: config.build_data.fcmSenderId,
    launchBackgroundColor: config.build_data.launchBackgroundColor,
    androidAppId: config.build_data.appcenterConfig.androidAppId,
    disableAndroidBackground: config.build_data.plist?.DisableAndroidBackground?.toString() || '',
    artifactoryURL: config.build_data.ARTIFACTORY_URL,
  });

  const [assetsSection, setAssetsSection] = useState<Assets>(omit(config.build_data.assets, CONFIG_ASSETS.PUSH_SAMPLE));

  const [themeSection, setThemeSection] = useState<Theme>(config.build_data.theme);

  const deepCopyConfig = cloneDeep(config);
  const deepCopyMisc = cloneDeep(miscSection);
  const deepCopyPlist = cloneDeep(plistSection);
  const artifactoryURL = 'https://hqo.jfrog.io/artifactory';

  const newConfig = {
    ...deepCopyConfig,
    whitelabel_id: whitelabelId,
    version: genSection.version,
    environment: genSection.environment,
    build_data: {
      ...deepCopyConfig.build_data,
      ...deepCopyMisc,
      appName: genSection.appName,
      appBrand: genSection.appBrand,
      apiDomain: genSection.apiDomain,
      androidBundleName: androidSection.androidBundleName,
      androidDisplayName: androidSection.androidDisplayName || genSection.appName,
      segmentAndroidWriteKey: androidSection.segmentAndroidWriteKey,
      androidKeystore: androidSection.androidKeystore,
      brazeAndroidApiKey: androidSection.brazeAndroidApiKey,
      brazeAndroidFireBaseSenderId: androidSection.brazeAndroidFireBaseSenderId,
      fcmSenderId: androidSection.fcmSenderId,
      launchBackgroundColor: androidSection.launchBackgroundColor,
      theme: themeSection,
      brazeSdkEndpoint: genSection.brazeSdkEndpoint,
      proxySDK: genSection.proxySDK,
      openpathSDK: genSection.openpathSDK,
      iosBundleName: iosSection.iosBundleName,
      mixpanelToken: genSection.mixpanelToken,
      iosAppKey: iosSection.iosAppKey,
      launchDarklyMobileKey: genSection.launchDarklyMobileKey,
      launchDarklySdkKey: genSection.launchDarklySdkKey,
      launchDarklyClientId: genSection.launchDarklyClientId,
      ARTIFACTORY_URL: artifactoryURL,
      appcenterConfig: {
        ...deepCopyConfig.build_data.appcenterConfig,
        androidAppId: androidSection.androidAppId,
        iosAppId: iosSection.iosAppId,
        apiToken: genSection.apiToken,
        certificatePassword: genSection.certificatePassword,
        developmentBaseBranch: genSection.developmentBaseBranch,
        distributionBaseBranch: genSection.distributionBaseBranch,
        owner: genSection.owner,
        baseBuildBranch: genSection.baseBuildBranch,
      },
      plist: {
        ...deepCopyPlist,
        brazeApiKey: iosSection.brazeApiKey,
        CFBundleDisplayName: iosSection.CFBundleDisplayName,
        CFBundleIdentifier: iosSection.CFBundleIdentifier,
        CFBundleShortVersionString: iosSection.CFBundleShortVersionString,
        embraceIosKeyNative: iosSection.embraceIosKeyNative,
        DisableAndroidBackground: androidSection.disableAndroidBackground,
        branch_app_domain: genSection.branch_app_domain,
        moduleName: genSection.moduleName,
        NSCameraUsageDescription: iosSection.NSCameraUsageDescription,
      },
    },
    asc_link: iosSection.ascLink,
    gpc_link: androidSection.gpcLink,
    pendoKeys: {
      // eslint-disable-next-line max-len
      ios: 'eyJhbGciOiJSUzI1NiIsImtpZCI6IiIsInR5cCI6IkpXVCJ9.eyJkYXRhY2VudGVyIjoidXMiLCJrZXkiOiJhZmE2YzU3NjM3NTQxOTRkMTg0ZDkwYTcyYWM1ZTlkNTFiZTQ1ZTA4YjVmNjE0OTMyNDJjMWY3Mzc2NjU1MGEyOWYyYzk3NDhjMGQ3Y2EwYTJmODNkZjI0OTQ3MTljZDAxMjliNzU4MjlmMGNiMTFmNTcyNDQyODI0ZWZmZGY1MC4zMDFlNzhmNTc3NDI0MWMzZjM0ODZhMmM5ZjZiMmYyMi5lMjZjZWMwZDNhMDVkNGUyMGM4NDM4YjEzMzc0ZDkyY2YzNTdjM2JjYTE1YjIwYTlmZjQ1NzQ1Yjc1MmQ0ODQyIn0.Gc83_V7hSWAMzm3MjgSRDhc9-YS5_qCnSr6scjIsIv-T6UfgSJt4x2-YKCVpsmExtobfVCMTwz0Wbd9kuLSJ7sO3oRQvDyeGA-wV0PTiALnpZHgbjlTFDT_YKTw1jhcqRmaLld7plkyPUtyHGmGCHhLYO_bpH2VfxMwCsvRcqAI',
      // eslint-disable-next-line max-len
      android:
        'eyJhbGciOiJSUzI1NiIsImtpZCI6IiIsInR5cCI6IkpXVCJ9.eyJkYXRhY2VudGVyIjoidXMiLCJrZXkiOiJkNDFjZTg4ZGYwMGY0M2I0ZmY3Mzc3OThmZTE1OWQyMDg1YmRjMGY5YzFmN2FiYzI4YmM5ZWU4NjM4ZDllNmE2ODFiNTY0MWE3MWIwYTE5OTM0M2ZkOWUwY2EyNjk0NDVhM2E2YmRiMjE5NDZhMmI5Mjk3MWJkMjIzY2E4ZWFhMzFiMGIzNWY3OTI3YmM1OWMwOGY0NmZhYTllOTY3NGI5Ljc0NjljZGRmNDQ4NzkxM2Q2ZDM3NmMwZGYwNDAzOGRkLjZiZTQ1YjhiMDdjNDg2OWU0Mjg4MDI1ZjdiY2Q5MzhmNTM3ZTQzYTg4OWExMWUwMzg4YjRhMmNkMjQ4YTA1NzcifQ.aI7jdfGFqOwcKHeiabudcHfdU4SF_0aaPldYzrqqlIT_YGW2bvqjhZAshI7Lusjds5meITaAqO2MurmSqe3i6w_ZPq0IBG0q7IYJQVCWO8qcv6TV7YMBFErmBQrihyficFRNoQzzyRdWaej45BAyniWnyi3ypEO90lwQNQYrGB8',
    },
  };

  const imagesHash: ImagesHash = {
    ...assetsSection,
    headerImage: miscSection.headerImage as string,
    launchImage: miscSection.launchImage as string,
  };

  const locationState = history.location.state as { previousLocation: string };

  function handleSave() {
    setGenSection(genSection);
    updateParent(newConfig);
  }

  function handleDelete() {
    dispatch(
      handleDeleteItemModal({
        id: config.id,
        actionType: DELETE_CONFIG,
        targetPageRoute: locationState ? locationState.previousLocation : ROUTES.MANAGE_WHITELABELS,
        name: t('dialogs.deleteItem.config-name', { name: config.build_data.appBrand }),
      })
    );
  }

  function resetFields() {
    if (isCustomConfig) {
      setCustomConfig(config);
    } else {
      setGenSection({
        environment: config.environment,
        ...omit(config.build_data.appcenterConfig, generalConfigExcludeFields),
        ...omit(pSection, generalConfigExcludeFields),
        ...omit(mSection, generalConfigExcludeFields),
      });
      setIosSection({
        iTunesURL: config.build_data.iTunesURL,
        iosBundleName: config.build_data.iosBundleName,
        ascLink: config.asc_link,
        brazeApiKey: config.build_data.plist.brazeApiKey.toString(),
        CFBundleDisplayName: String(config.build_data.plist.CFBundleDisplayName),
        CFBundleIdentifier: String(config.build_data.plist.CFBundleIdentifier),
        CFBundleShortVersionString: String(config.build_data.plist.CFBundleShortVersionString),
        embraceIosKeyNative: String(config.build_data.plist.embraceIosKeyNative),
        iosAppId: config.build_data.appcenterConfig.iosAppId,
        NSCameraUsageDescription: String(config.build_data.plist.NSCameraUsageDescription),
      });
      setAndroidSection({
        androidBundleName: config.build_data.androidBundleName,
        androidDisplayName: config.build_data.androidDisplayName,
        gpcLink: config.gpc_link,
        segmentAndroidWriteKey: config.build_data.segmentAndroidWriteKey,
        androidKeystore: config.build_data.androidKeystore,
        brazeAndroidApiKey: config.build_data.brazeAndroidApiKey,
        brazeAndroidFireBaseSenderId: config.build_data.brazeAndroidFireBaseSenderId,
        fcmSenderId: config.build_data.fcmSenderId,
        launchBackgroundColor: config.build_data.launchBackgroundColor,
        androidAppId: config.build_data.appcenterConfig.androidAppId,
      });
      setAssetsSection(omit(config.build_data.assets, CONFIG_ASSETS.PUSH_SAMPLE));
      setThemeSection(config.build_data.theme);
      setMiscSection({
        ...omit(config.build_data, convertObjectToArray(MAIN_SECTIONS_FIELDS)),
      } as MiscSectionDate);
      setPlistSection(config.build_data.plist);
    }
  }

  function goToPreviousPage() {
    history.push(locationState ? locationState.previousLocation : ROUTES.RELEASE_APPS);
  }

  function handleJsonChange(value: JSONInputValue<unknown>) {
    setCustomConfig({ ...(value.jsObject as Record<string, unknown>) });
  }

  const sections: Array<ConfigSection> = [
    {
      section: genSection,
      setSection: setGenSection,
      title: t('release-apps.config-info.config-tab.sections.app-section'),
      requiredFields: [MAIN_SECTIONS_FIELDS.ENVIRONMENT],
    },
    {
      section: iosSection,
      setSection: setIosSection,
      title: t('release-apps.config-info.config-tab.sections.ios'),
    },
    {
      section: androidSection,
      setSection: setAndroidSection,
      title: t('release-apps.config-info.config-tab.sections.android'),
    },
  ];

  return (
    <div className="config-tab-container">
      <AppBar position="sticky">
        <Toolbar className="config-tab-toolbar">
          <Button className="back-button" onClick={goToPreviousPage}>
            <ArrowBackIcon />
            {t('buttons.back')}
          </Button>
          <Button className="delete-button" variant="text" color="secondary" onClick={handleDelete}>
            {t('buttons.delete')}
          </Button>
        </Toolbar>
      </AppBar>
      {isCustomConfig ? (
        <JSONInput
          id="googleServices"
          placeholder={customConfig}
          theme={JSON_INPUT_THEME}
          locale={locale}
          style={resolveJsonInputStyle('550px', '100%', '13px')}
          onChange={handleJsonChange}
        />
      ) : (
        <Paper className="config-tab-content">
          <Section
            key={0}
            section={sections[0].section}
            setSection={sections[0].setSection}
            title={sections[0].title}
            requiredFields={sections[0].requiredFields}
            sorted
            handleSave={handleSave}
            resetFields={resetFields}
          />
          <div className="plist-section-container">
            <MiscSection
              miscSection={miscSection}
              setMiscSection={setMiscSection}
              handleSave={handleSave}
              resetFields={resetFields}
            />
            <ZendeskSection
              plistSection={plistSection}
              setPlistSection={setPlistSection}
              handleSave={handleSave}
              resetFields={resetFields}
            />
            <SpreedlyProductionSection
              plistSection={plistSection}
              setPlistSection={setPlistSection}
              handleSave={handleSave}
              resetFields={resetFields}
            />
            <SpreedlyDevelopmentSection
              plistSection={plistSection}
              setPlistSection={setPlistSection}
              handleSave={handleSave}
              resetFields={resetFields}
            />
            <BranchKeySection
              plistSection={plistSection}
              setPlistSection={setPlistSection}
              handleSave={handleSave}
              resetFields={resetFields}
            />
            <ModesSection
              plistSection={plistSection}
              setPlistSection={setPlistSection}
              handleSave={handleSave}
              resetFields={resetFields}
            />
            <TypesSection
              plistSection={plistSection}
              setPlistSection={setPlistSection}
              handleSave={handleSave}
              resetFields={resetFields}
            />
          </div>
          <div className="plist-section-container">
            <Section
              key={1}
              section={sections[1].section}
              setSection={sections[1].setSection}
              title={sections[1].title}
              requiredFields={sections[1].requiredFields}
              sorted
              handleSave={handleSave}
              resetFields={resetFields}
            />
            <IosEntitSection
              miscSection={miscSection}
              setMiscSection={setMiscSection}
              resetFields={resetFields}
              handleSave={handleSave}
            />
          </div>
          <div className="misc-section-container">
            <Section
              key={2}
              section={sections[2].section}
              setSection={sections[2].setSection}
              title={sections[2].title}
              requiredFields={sections[2].requiredFields}
              sorted
              handleSave={handleSave}
              resetFields={resetFields}
            />
            <ImagesSection
              miscSection={miscSection}
              setMiscSection={setMiscSection}
              handleSave={updateImagesHash}
              imagesHash={imagesHash}
            />
            <GoogleServicesSection
              miscSection={miscSection}
              setMiscSection={setMiscSection}
              handleSave={handleSave}
              resetFields={resetFields}
            />
            <ColorsSection miscSection={miscSection} setMiscSection={setMiscSection} />
          </div>
          <AssetsSection
            assetsSection={assetsSection}
            setAssetsSection={setAssetsSection}
            handleSave={updateImagesHash}
            imagesHash={imagesHash}
          />
          <ThemeSection
            themeSection={themeSection}
            setThemeSection={setThemeSection}
            saveConfig={updateParent}
            newConfig={newConfig}
          />
          <CertificateSection configId={config.id} certificateUrl={config.base_certificate} />
        </Paper>
      )}
    </div>
  );
};

export default ConfigTab;
