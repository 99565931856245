import { BackgroundModes, PlistData } from 'models/PlistData';

import FieldsContainer from '../../fields-container/FieldsContainer';
import { useTranslation } from 'react-i18next';

interface ModesSectionProps {
  plistSection: PlistData;
  setPlistSection: (value: PlistData) => void;
  handleSave?: () => void;
  resetFields?: () => void;
}

const ModesSection = ({ plistSection, setPlistSection, handleSave, resetFields }: ModesSectionProps): JSX.Element => {
  const { t } = useTranslation();

  const modes = plistSection.UIBackgroundModes as BackgroundModes;

  function setField(value: string, index: number) {
    setPlistSection({
      ...plistSection,
      UIBackgroundModes: modes.map((item, itemIndex) => (itemIndex === index ? value : item)),
    });
  }

  function addField() {
    setPlistSection({ ...plistSection, UIBackgroundModes: [...modes, ''] });
  }

  function deleteField(index: number) {
    setPlistSection({ ...plistSection, UIBackgroundModes: modes.filter((_item, itemIndex) => itemIndex !== index) });
  }

  return (
    <FieldsContainer
      title={t('release-apps.config-info.config-tab.sections.ui-background-modes')}
      titleClassName="section-title"
      items={modes}
      setField={setField}
      addField={addField}
      deleteField={deleteField}
      description={t('description.modes')}
      handleSave={handleSave}
      resetFields={resetFields}
    />
  );
};

ModesSection.defaultProps = {
  handleSave: () => {
    /* do nothing */
  },
  resetFields: () => {
    /* do nothing */
  },
};

export default ModesSection;
