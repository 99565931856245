import FieldsContainer from '../../fields-container/FieldsContainer';
import IosEntit from 'models/IosEntit';
import MiscSectionDate from 'models/MiscSectiondata';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

interface IosEntitSectionProps {
  miscSection: MiscSectionDate;
  setMiscSection: (value: MiscSectionDate) => void;
  handleSave?: () => void;
  resetFields?: () => void;
}

const IosEntitSection = ({
  miscSection,
  setMiscSection,
  handleSave,
  resetFields,
}: IosEntitSectionProps): JSX.Element => {
  const { t } = useTranslation();

  const iosEntitlements = miscSection.iosEntitlements as IosEntit;
  const iosEntitClone = cloneDeep(iosEntitlements);

  function setField(key: string) {
    return (value: string, index: number) => {
      iosEntitClone[key][index] = value;
      setMiscSection({ ...miscSection, iosEntitlements: { ...iosEntitClone } });
    };
  }

  function addField(key: string) {
    return () => {
      iosEntitClone[key].push('');
      setMiscSection({ ...miscSection, iosEntitlements: { ...iosEntitClone } });
    };
  }

  function deleteField(key: string) {
    return (index: number) => {
      iosEntitClone[key].splice(index, 1);
      setMiscSection({ ...miscSection, iosEntitlements: { ...iosEntitClone } });
    };
  }

  return (
    <div>
      <div className="section-title">{t('release-apps.config-info.config-tab.sections.ios-entitlements')}</div>
      {Object.keys(miscSection.iosEntitlements).map((key) => (
        <FieldsContainer
          key={key}
          title={key}
          titleClassName="sub-title"
          items={(miscSection.iosEntitlements as IosEntit)[key]}
          setField={setField(key)}
          addField={addField(key)}
          deleteField={deleteField(key)}
          description={t(`description.${key.split('.').join('')}`)}
          handleSave={handleSave}
          resetFields={resetFields}
        />
      ))}
    </div>
  );
};

IosEntitSection.defaultProps = {
  handleSave: () => {
    /* do nothing */
  },
  resetFields: () => {
    /* do nothing */
  },
};

export default IosEntitSection;
