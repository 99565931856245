import './assets-section.scss';

import { CONFIG_ASSETS, IMAGE_DIMENSIONS } from 'const';
import { resolveImagePreviewUrl, setDefaultImageUrl } from 'util/whitelabel-config.utils';

import Assets from 'models/Assets';
import { Avatar } from '@material-ui/core';
import ImageDimension from 'models/ImageDimension';
import ImageUploaderDialog from 'components/dialogs/image-uploader-dialog/ImageUploaderDialog';
import ImagesHash from 'models/ImagesHash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AssetsSectionProps {
  assetsSection: Assets;
  setAssetsSection: (value: Assets) => void;
  handleSave: (ih: ImagesHash) => void;
  imagesHash: ImagesHash;
}

interface ImageUploaderPayload {
  isOpened: boolean;
  setImage: (value: string | HTMLCanvasElement) => void;
  imageDimension: ImageDimension;
  fillColor?: string;
}

const AssetsSection = ({
  assetsSection,
  setAssetsSection,
  handleSave,
  imagesHash,
}: AssetsSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const [imageUploaderPayload, setImageUploaderPayload] = useState<ImageUploaderPayload>({
    isOpened: false,
    setImage: null,
    imageDimension: IMAGE_DIMENSIONS.NO_DIMENSION,
  });

  function closeImageUploader() {
    setImageUploaderPayload({
      isOpened: false,
      setImage: null,
      imageDimension: IMAGE_DIMENSIONS.NO_DIMENSION,
      fillColor: null,
    });
  }

  const openImageUploader = (
    setImage: (value: string | HTMLCanvasElement) => void,
    imageDimension: ImageDimension,
    fillColor?: string
  ) => setImageUploaderPayload({ isOpened: true, setImage, imageDimension, fillColor });

  const updateImage = (fieldName: string, image: string | HTMLCanvasElement) => {
    setAssetsSection({
      ...assetsSection,
      [fieldName]: image,
    });
    handleSave({ ...imagesHash, [fieldName]: image });
  };

  function handleOpenImageUploader(
    fieldName: string,
    imageDimensions: ImageDimension,
    eventType: 'click' | 'press',
    fillColor?: string
  ): (event: unknown) => void {
    const setImage = (image: string | HTMLCanvasElement) => updateImage(fieldName, image);
    if (eventType === 'click') {
      return () => openImageUploader(setImage, imageDimensions, fillColor);
    }
    if (eventType === 'press') {
      return (event: KeyboardEvent) => {
        const { key } = event;
        if (key === 'Enter') {
          return openImageUploader(setImage, imageDimensions, fillColor);
        }
        return null;
      };
    }
    return () => {
      /* do nothing */
    };
  }

  return (
    <div className="assets-section-container">
      <div className="title">{t('release-apps.config-info.config-tab.sections.assets')}</div>
      <div className="image-uploader-container">
        <div className="sub-title">{t('release-apps.config-info.config-tab.labels.icon-image')}</div>
        <Avatar
          className="app-icon-preview"
          src={resolveImagePreviewUrl(assetsSection[CONFIG_ASSETS.APP_ICON])}
          onError={setDefaultImageUrl}
          onClick={handleOpenImageUploader(CONFIG_ASSETS.APP_ICON, IMAGE_DIMENSIONS.APP_ICON, 'click')}
        />
      </div>
      <div className="image-uploader-container">
        <div className="sub-title">{t('release-apps.config-info.config-tab.labels.header-logo')}</div>
        <div
          role="button"
          tabIndex={0}
          onClick={handleOpenImageUploader(CONFIG_ASSETS.HEADER_LOGO, IMAGE_DIMENSIONS.NO_DIMENSION, 'click')}
          onKeyPress={handleOpenImageUploader(CONFIG_ASSETS.HEADER_LOGO, IMAGE_DIMENSIONS.NO_DIMENSION, 'press')}
        >
          <img
            src={resolveImagePreviewUrl(assetsSection[CONFIG_ASSETS.HEADER_LOGO])}
            className="header-logo-preview"
            onError={setDefaultImageUrl}
            alt=""
          />
        </div>
      </div>
      <div className="image-uploader-container">
        <div className="sub-title">{t('release-apps.config-info.config-tab.labels.notification-icon')}</div>
        <Avatar
          className="app-icon-preview bg-checkered"
          src={resolveImagePreviewUrl(assetsSection[CONFIG_ASSETS.ANDROID_NOTIFICATION_ICON])}
          onError={setDefaultImageUrl}
          onClick={handleOpenImageUploader(
            CONFIG_ASSETS.ANDROID_NOTIFICATION_ICON,
            IMAGE_DIMENSIONS.APP_ICON,
            'click',
            'transparent'
          )}
        />
      </div>
      <div className="image-uploader-container">
        <div className="sub-title">{t('release-apps.config-info.config-tab.labels.landlord-branding')}</div>
        <div
          role="button"
          tabIndex={0}
          onClick={handleOpenImageUploader(CONFIG_ASSETS.LANDLORD_BRANDING, IMAGE_DIMENSIONS.NO_DIMENSION, 'click')}
          onKeyPress={handleOpenImageUploader(CONFIG_ASSETS.LANDLORD_BRANDING, IMAGE_DIMENSIONS.NO_DIMENSION, 'press')}
        >
          <img
            src={resolveImagePreviewUrl(assetsSection[CONFIG_ASSETS.LANDLORD_BRANDING])}
            className="header-logo-preview"
            onError={setDefaultImageUrl}
            alt=""
          />
        </div>
      </div>
      <ImageUploaderDialog {...imageUploaderPayload} handleClose={closeImageUploader} />
    </div>
  );
};

export default AssetsSection;
