import ReleaseApps, { ReleaseAppsDispatchProps, ReleaseAppsStoreProps } from 'release-apps/ReleaseApps';
import { handleCreateWhitelabelModal, runWhitelabelBuild } from 'store/actions';

import AppState from 'models/AppState';
import { connect } from 'react-redux';

const mapStateToProps = ({ whitelabels }: AppState): ReleaseAppsStoreProps => {
  const { items, releaseNotes, configs } = whitelabels;

  return {
    whitelabels: items,
    releaseNotes,
    configs,
  };
};

const mapDispatchToProps: ReleaseAppsDispatchProps = {
  runWhitelabelBuild,
  handleCreateWhitelabelModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseApps);
