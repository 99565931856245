import './dashboard.scss';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { KeyboardEvent } from 'react';
import { ROUTES } from 'const';
import { handleCreateWhitelabelModal } from 'store/actions/Modals';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Dashboard = ({ history }: RouteComponentProps<unknown>): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cards = [
    {
      title: t('dashboard.titles.create-whitelabel'),
      handleClick: () => dispatch(handleCreateWhitelabelModal()),
      handlePress: (e: KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && dispatch(handleCreateWhitelabelModal()),
    },
    {
      title: t('dashboard.titles.manage-whitelabels'),
      handleClick: () => history.push(ROUTES.MANAGE_WHITELABELS),
      handlePress: (e: KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && history.push(ROUTES.MANAGE_WHITELABELS),
    },
    {
      title: t('dashboard.titles.release-apps'),
      handleClick: () => history.push(ROUTES.RELEASE_APPS),
      handlePress: (e: KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && history.push(ROUTES.RELEASE_APPS),
    },
    {
      title: t('dashboard.titles.app-status-report'),
      handleClick: () => history.push(ROUTES.APP_STATUS_REPORT),
      handlePress: (e: KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && history.push(ROUTES.APP_STATUS_REPORT),
    },
    {
      title: t('dashboard.titles.release-management'),
      handleClick: () => history.push(ROUTES.RELEASE_MANAGEMENT),
      handlePress: (e: KeyboardEvent<HTMLDivElement>) => e.key === 'Enter' && history.push(ROUTES.RELEASE_MANAGEMENT),
    },
  ];

  return (
    <div className="cards-container animated slideInUpTiny animation-duration-3">
      {cards.map((card) => (
        <div
          key={card.title}
          className="card"
          role="button"
          tabIndex={0}
          onClick={card.handleClick}
          onKeyPress={card.handlePress}
        >
          <div className="card-content">
            <h3 className="card-title">{card.title}</h3>
          </div>
        </div>
      ))}
    </div>
  );
};

export default withRouter(Dashboard);
