import { Button, IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import { Check, Close } from '@material-ui/icons';
import { convertObjectToArray, resolveTooltipText } from 'util/whitelabel-config.utils';
import { lowerCase, omit, upperFirst } from 'lodash';

import AddIcon from '@material-ui/icons/Add';
import AddSectionFieldDialog from 'components/dialogs/add-section-field-dialog/AddSectionFieldDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';

interface GeneralSectionProps {
  section: { [key: string]: unknown };
  title: string;
  fieldsToExclude: { [key: string]: string };
  isDynamic: boolean;
  setSection: (key: string, value: string) => void;
  deleteField?: (key: string) => void;
  handleSave?: () => void;
  resetFields?: () => void;
}

const GeneralSection = ({
  section,
  title,
  isDynamic,
  fieldsToExclude,
  setSection,
  deleteField,
  handleSave,
  resetFields,
}: GeneralSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [isEdit, setEditMode] = useState<boolean>(false);

  const resolveGeneralSection = () => {
    if (section) {
      return Object.keys(fieldsToExclude ? omit(section, convertObjectToArray(fieldsToExclude)) : section);
    }
    return [];
  };

  function addField(key: string, value: string) {
    setSection(key, value);
    setOpen(false);
  }

  function handleSectionSave() {
    handleSave();
    setEditMode(!isEdit);
  }

  function resetSection() {
    resetFields();
    setEditMode(!isEdit);
  }

  function handleChangeOpen(isOpen: boolean) {
    return () => setOpen(isOpen);
  }

  function changeEditMode() {
    setEditMode(!isEdit);
  }

  function handleFieldChange(field: string) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSection(field, value);
    };
  }

  function handleDeleteField(field: string) {
    return () => deleteField(field);
  }

  return (
    <>
      <div className="title-container">
        <span className="section-title">{title}</span>
        {isDynamic && (
          <>
            <AddSectionFieldDialog open={open} handleClose={handleChangeOpen(false)} handleSubmit={addField} />
            {isEdit ? (
              <span>
                <Tooltip title={t('release-apps.config-info.config-tab.add-field')} placement="top" arrow>
                  <Button onClick={handleChangeOpen(true)} size="small">
                    <AddIcon />
                  </Button>
                </Tooltip>
                <Button onClick={handleSectionSave} size="small">
                  <Check />
                </Button>
                <Button onClick={resetSection} size="small">
                  <Close />
                </Button>
              </span>
            ) : (
              <Button onClick={changeEditMode} size="small">
                <EditIcon />
              </Button>
            )}
          </>
        )}
      </div>
      <div className="fields-container">
        {resolveGeneralSection().map((field) => (
          <Tooltip title={resolveTooltipText(field)} key={field} arrow>
            <TextField
              key={field}
              className="text-field"
              label={upperFirst(lowerCase(field))}
              value={section[field]}
              disabled={!isEdit}
              onChange={handleFieldChange(field)}
              InputProps={{
                endAdornment: isDynamic && isEdit && (
                  <InputAdornment position="end">
                    <IconButton onClick={handleDeleteField(field)} size="small">
                      <DeleteIcon className="delete-icon" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        ))}
      </div>
    </>
  );
};

GeneralSection.defaultProps = {
  deleteField: () => {
    /* do nothing */
  },
  handleSave: () => {
    /* do nothing */
  },
  resetFields: () => {
    /* do nothing */
  },
};

export default GeneralSection;
