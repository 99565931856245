import './base.scss';

import { MouseEvent as ReactMouseEvent, useState } from 'react';

import Column from '../models/Column';
import ColumnClassNames from '../models/ColumnClassNames';
import Option from 'models/Option';
import Options from '../Options/index';
import Order from '../models/Order';
import Paper from '@material-ui/core/Paper';
import Row from '../models/Row';
import RowData from '../models/RowData';
import { SORT_ORDER } from 'const';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

interface TableHeadProps {
  columns: Array<Column>;
  order: Order;
  orderBy: string;
  onRequestSort: (event: ReactMouseEvent<unknown>, property: string) => void;
}

interface TableBodyProps {
  rows: Array<Row>;
  order: Order;
  orderBy: string;
  options: Array<Option<Row>>;
  leftColumn: string;
  columnClassNames?: ColumnClassNames;
  onRowClick?: (event: ReactMouseEvent<unknown>, rowId: number) => void;
}

interface TableProps {
  rows: Array<Row>;
  columns: Array<Column>;
  className?: string;
  options?: Array<Option<Row>>;
  columnClassNames?: ColumnClassNames;
  onRowClick?: (event: ReactMouseEvent<unknown>, rowId: number) => void;
}

function sortDescending(a: RowData, b: RowData, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array: Array<Row>, cmp: (a: RowData, b: RowData) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [Row, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0].data, b[0].data);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order: Order, orderBy: string): (a: RowData, b: RowData) => number {
  return order === SORT_ORDER.DESC ? (a, b) => sortDescending(a, b, orderBy) : (a, b) => -sortDescending(a, b, orderBy);
}

const renderBaseTableHead = ({ columns, order, orderBy, onRequestSort }: TableHeadProps) => {
  const createSortHandler = (property: string) => (event: ReactMouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column: Column) => (
          <TableCell key={column.id} align={column.align} sortDirection={orderBy === column.id ? order : false}>
            <TableSortLabel active={orderBy === column.id} direction={order} onClick={createSortHandler(column.id)}>
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const renderBaseTableBody = ({
  rows,
  order,
  orderBy,
  options,
  leftColumn,
  columnClassNames,
  onRowClick,
}: TableBodyProps) => (
  <TableBody>
    {stableSort(rows, getSorting(order, orderBy)).map((row: Row) => {
      const { data } = row;
      const keys = Object.keys(data);
      const optionsProps = { options, row };

      const createRowClickHandler = (rowId: number) => (event: ReactMouseEvent<unknown>) => {
        onRowClick(event, rowId);
      };

      return (
        <TableRow key={row.id} onClick={createRowClickHandler(row.id)} className="click-row">
          {keys.map((key) => {
            const columnClassName = columnClassNames[key];
            const className = columnClassName ? columnClassName(row.data[key]) : '';
            return (
              <TableCell key={key} align={key === leftColumn ? 'left' : 'right'} className={className}>
                {row.data[key]}
              </TableCell>
            );
          })}
          {options && (
            <TableCell className="options-menu">
              {' '}
              <Options {...optionsProps} />{' '}
            </TableCell>
          )}
        </TableRow>
      );
    })}
  </TableBody>
);

// eslint-disable-next-line react/function-component-definition
export default function BaseTable({
  rows,
  columns,
  options,
  className,
  columnClassNames,
  onRowClick,
}: TableProps): JSX.Element {
  const [order, setOrder] = useState<Order>(SORT_ORDER.ASC);
  // by default we set only first column as left align
  const leftColumn = columns[0].id;
  const [orderBy, setOrderBy] = useState(leftColumn);

  const handleRequestSort = (event: ReactMouseEvent<unknown>, property: string) => {
    event.preventDefault();

    const isDesc = orderBy === property && order === SORT_ORDER.DESC;
    setOrder(isDesc ? SORT_ORDER.ASC : SORT_ORDER.DESC);
    setOrderBy(property);
  };

  const handleRowClick = (event: ReactMouseEvent<unknown>, rowId: number) => {
    event.preventDefault();

    onRowClick(event, rowId);
  };

  const headProps = {
    columns,
    order,
    orderBy,
    onRequestSort: handleRequestSort,
  };
  const bodyProps = {
    rows,
    order,
    orderBy,
    options,
    leftColumn,
    columnClassNames,
    onRowClick: handleRowClick,
  };

  return (
    <div className={`animated slideInUpTiny animation-duration-3 ${className}`}>
      <Paper className="responsive-table">
        <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
          {renderBaseTableHead(headProps)}
          {renderBaseTableBody(bodyProps)}
        </Table>
      </Paper>
    </div>
  );
}

BaseTable.defaultProps = {
  className: '',
  options: [],
  columnClassNames: {},
  onRowClick: () => {
    /* do nothing */
  },
};
