import './create-config-dialog.scss';

import { CONFIG_DIALOG_DEFAULT_ENVIRONMENT, CONFIG_DIALOG_DEFAULT_VERSION, INPUT_NAMES, JSON_INPUT_THEME } from 'const';
import { KeyboardEvent as ReactKeyboardEvent, useState } from 'react';

import BaseDialog from '../base/index';
import BuildData from 'models/BuildData';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import { DEFAULT_VALIDATION_OPTIONS } from 'constants/ActionTypes';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import JSONInput from 'react-json-editor-ajrm';
import JSONInputValue from 'models/JSONInputValue';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ReactHookFormError from 'models/ReactHookFormError';
import RuntimeData from 'models/RuntimeData';
import ValidationTextField from '../../validation-text-field';
import WhitelabelsForCloning from 'models/WhitelabelsForCloning';
import { createConfig } from 'store/actions/Whitelabels';
import defaultBuildData from 'constants/defaultBuildData';
import defaultRuntimeData from './defaultRuntimeData';
import { handleCloseCreateConfigModal } from 'store/actions/Modals';
import { handleInput } from 'util/input.utils';
import { isEmpty } from 'lodash';
import locale from 'react-json-editor-ajrm/locale/en';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useStyles } from '../../side-navigation/sideNavStyles';
import { useTranslation } from 'react-i18next';

interface AddConfigDialogProps {
  open: boolean;
  whitelabelId: number;
  targetPageRoute?: string;
  whitelabelsForCloning: Array<WhitelabelsForCloning>;
}

interface OpenItem {
  [field: string]: boolean | number | string;
}

interface SelectedItem {
  brandId?: number;
  configId?: number;
  selectedBuildData?: Partial<BuildData>;
}

const CreateConfigDialog = ({
  open,
  whitelabelId,
  targetPageRoute,
  whitelabelsForCloning,
}: AddConfigDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles({});
  const {
    formState: { errors },
    control,
  } = useForm(DEFAULT_VALIDATION_OPTIONS);

  const [environment, setEnvironment] = useState<string>(CONFIG_DIALOG_DEFAULT_ENVIRONMENT);
  const [version, setVersion] = useState<string>(CONFIG_DIALOG_DEFAULT_VERSION);
  const [buildData, setBuildData] = useState<Partial<BuildData>>(defaultBuildData);
  const [runtimeData, setRuntimeData] = useState<RuntimeData>(defaultRuntimeData);
  const [openItem, setOpenItem] = useState<OpenItem>({});
  const [selectedItem, setSelectedItem] = useState<SelectedItem>({});
  const [isConfigApplied, setIsConfigApplied] = useState<boolean>(false);
  const [isCloneConfigScreenOpen, setIsCloneConfigScreenOpen] = useState<boolean>(false);

  const isSaveEnabled = isEmpty(errors) && version && environment;
  const config = {
    whitelabel_id: whitelabelId,
    environment,
    version,
    build_data: buildData,
    runtime_data: runtimeData,
  };

  function handleClickOpenItemDropDown(brand: string): () => void {
    return () => setOpenItem({ ...openItem, [brand]: !openItem[brand] });
  }

  function handleClickSelectItemDropDown(
    brandId: number,
    configId: number,
    selectedBuildData: Partial<BuildData>
  ): () => void {
    return () => setSelectedItem({ brandId, configId, selectedBuildData });
  }

  function handleClickSwitchScreen(): void {
    setIsCloneConfigScreenOpen(!isCloneConfigScreenOpen);
  }

  function handleClickClearCloneConfig(): void {
    setBuildData(defaultBuildData);
    setSelectedItem({});
    setOpenItem({});
    setIsConfigApplied(false);
    handleClickSwitchScreen();
  }

  function handleClickApplyCloneConfig(): void {
    setBuildData(selectedItem.selectedBuildData);
    setIsConfigApplied(true);
    handleClickSwitchScreen();
  }

  function setInitialState() {
    setEnvironment(CONFIG_DIALOG_DEFAULT_ENVIRONMENT);
    setVersion(CONFIG_DIALOG_DEFAULT_VERSION);
    setIsConfigApplied(false);
    setSelectedItem({});
    setOpenItem({});
    setBuildData(defaultBuildData);
    setRuntimeData(defaultRuntimeData);
  }

  function onCancel() {
    dispatch(handleCloseCreateConfigModal());
    setInitialState();
  }

  async function onSubmit() {
    dispatch(createConfig({ config, targetPageRoute }));
    onCancel();
  }

  function getDisplayText(name: string, env: string) {
    let newName = name;
    if (!name) {
      newName = '';
    }
    return `${newName}(${env.toUpperCase()})`;
  }

  function handleJsonInputChange(inputName: string) {
    switch (inputName) {
      case 'buildData':
        return (value: JSONInputValue<BuildData>) => setBuildData(value.jsObject);
      case 'runtimeData':
        return (value: JSONInputValue<RuntimeData>) => setRuntimeData(value.jsObject);
      default:
        return null;
    }
  }

  function handleKeyPress(event: ReactKeyboardEvent<HTMLInputElement>) {
    handleInput(event, INPUT_NAMES.VERSION);
  }

  const createNewConfig = (
    <div className="dialog-content">
      <ValidationTextField
        name="environment"
        label={t('release-apps.config-info.labels.environment')}
        value={CONFIG_DIALOG_DEFAULT_ENVIRONMENT}
        required
        setValue={setEnvironment}
        type="text"
        errors={errors as unknown as ReactHookFormError}
        control={control}
        fullWidth
      />
      <ValidationTextField
        name="version"
        label={t('release-apps.config-info.labels.version')}
        value={CONFIG_DIALOG_DEFAULT_VERSION}
        onKeyPress={handleKeyPress}
        required
        setValue={setVersion}
        type="text"
        errors={errors as unknown as ReactHookFormError}
        control={control}
        fullWidth
      />
      <div className="json-header">
        {t('dialogs.createConfig.fields.buildDataLabel')}
        <Button className="clone-config-button" variant="contained" color="primary" onClick={handleClickSwitchScreen}>
          {isConfigApplied ? t('buttons.applied') : t('buttons.clone-config')}
        </Button>
      </div>
      <JSONInput
        id="buildData"
        placeholder={buildData}
        theme={JSON_INPUT_THEME}
        locale={locale}
        height="200px"
        onChange={handleJsonInputChange('buildData')}
      />
      <div className="json-header">{t('dialogs.createConfig.fields.runtimeDataLabel')}</div>
      <JSONInput
        id="runtimeData"
        placeholder={defaultRuntimeData}
        theme={JSON_INPUT_THEME}
        locale={locale}
        height="100px"
        onChange={handleJsonInputChange('runtimeData')}
      />
    </div>
  );

  const cloneConfig = (
    <div className="dialog-content">
      <List>
        {whitelabelsForCloning.map((item) => (
          <div key={item.id}>
            {item.configs.length !== 0 ? (
              <div key={item.id}>
                <ListItem
                  button
                  key={item.id}
                  className={classes.listItem}
                  onClick={handleClickOpenItemDropDown(item.brand)}
                >
                  <ListItemText primary={item.brand.toUpperCase()} />
                  {openItem[item.brand] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse key={item.id} component="li" in={Boolean(openItem[item.brand])} timeout="auto" unmountOnExit>
                  <List disablePadding>
                    {item.configs.map((conf) => (
                      <ListItem
                        button
                        key={conf.id}
                        className={classes.nestedItem}
                        selected={selectedItem && selectedItem.brandId === item.id && selectedItem.configId === conf.id}
                        onClick={handleClickSelectItemDropDown(item.id, conf.id, conf.build_data)}
                      >
                        <ListItemText
                          key={conf.id}
                          primary={getDisplayText(conf.build_data.appName, conf.environment)}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>{' '}
              </div>
            ) : null}
          </div>
        ))}
      </List>
    </div>
  );

  const createNewConfigActions = [
    <Button key="cancel" variant="text" color="default" onClick={onCancel}>
      {t('buttons.cancel')}
    </Button>,
    <Button key="save" variant="contained" color="primary" onClick={onSubmit} disabled={!isSaveEnabled}>
      {t('buttons.save')}
    </Button>,
  ];

  const cloneConfigActions = [
    <Button key="clear" variant="contained" color="secondary" onClick={handleClickClearCloneConfig}>
      {t('buttons.clear')}
    </Button>,
    <Button
      key="apply"
      variant="contained"
      color="primary"
      onClick={handleClickApplyCloneConfig}
      disabled={Object.keys(selectedItem).length === 0}
    >
      {t('buttons.apply')}
    </Button>,
  ];

  const dialogProps = isCloneConfigScreenOpen
    ? {
        title: t('dialogs.cloneConfig.title'),
        actions: cloneConfigActions,
        open,
        children: cloneConfig,
        handleClose: handleClickClearCloneConfig,
      }
    : {
        title: t('dialogs.createConfig.title'),
        actions: createNewConfigActions,
        open,
        children: createNewConfig,
        handleClose: onCancel,
      };

  return <BaseDialog {...dialogProps} />;
};

CreateConfigDialog.defaultProps = {
  targetPageRoute: '',
};

export default CreateConfigDialog;
