import { get, post, put } from './appcenter.api';

import AppCenterConfig from 'models/AppCenterConfig';
import Build from 'models/Build';
import BuildsConfig from 'models/BuildsConfig';
import globalConfig from 'config';

export function getBuildDownloadUrl(owner: string, name: string, token: string, id: number): Promise<string> {
  const path = `/apps/${owner}/${name}/builds/${id}/downloads/build`;

  return get(path, token).then((data: { uri: string }) => data.uri);
}

export function getConfig(owner: string, platform: string, token: string): Promise<BuildsConfig> {
  const path = `/apps/${owner}/${platform}/branches/${globalConfig.appcenterBranch}/config`;

  return get(path, token).then((data) => data as BuildsConfig);
}

export function getBuilds(owner: string, platform: string, token: string): Promise<Array<Build>> {
  const path = `/apps/${owner}/${platform}/branches/${globalConfig.appcenterBranch}/builds`;

  return get(path, token).then((data) => data as Array<Build>);
}

export function updateConfig(
  owner: string,
  platform: string,
  token: string,
  config: BuildsConfig
): Promise<BuildsConfig> {
  const path = `/apps/${owner}/${platform}/branches/${globalConfig.appcenterBranch}/config`;

  return put(path, token, config).then((data) => data as BuildsConfig);
}

export function runWhitelabelBuild(owner: string, platform: string, branch: string, token: string): Promise<unknown> {
  const path = `/apps/${owner}/${platform}/branches/${branch}/builds`;

  return post(path, token);
}

export function generateBuildLink(config: AppCenterConfig, platform: string, id: number): string {
  const name = config[`${platform}AppId`];

  // eslint-disable-next-line max-len
  return `${globalConfig.appcenterUiUrl}/orgs/${config.owner}/apps/${name}/build/branches/${globalConfig.appcenterBranch}/builds/${id}`;
}

export async function getBranchesList(owner: string, platform: string, token: string): Promise<unknown> {
  const path = `/apps/${owner}/${platform}/branches`;

  return await get(path, token);
}
