import AppState from 'models/AppState';
import Config from 'models/Config';
import List from '@material-ui/core/List';
import WhitelabelConfigsTable from '../components/tables/whitelabel-configs-table';
import { omit } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

interface Rows {
  id: number;
  whitelabel_id: number;
  data: { [key: string]: string };
}

const ManageConfigs = (): JSX.Element => {
  const configs = useSelector((store: AppState) => store.whitelabels.configs);
  const whitelabels = useSelector((store: AppState) => store.whitelabels.items);

  useEffect(() => {
    document.getElementsByClassName('app-main-content-wrapper')[0].scrollTo(0, 0);
  }, []);

  const parseData = () =>
    configs.map((config: Config) => {
      const { environment, version, id, whitelabel_id } = config;
      const { appName } = config.build_data;
      const whitelabel = whitelabels.find((item) => item.id === config.whitelabel_id);

      return {
        id,
        whitelabel_id,
        data: {
          appName,
          appBrand: whitelabel ? whitelabel.brand : null,
          environment,
          version,
        },
      };
    });

  const rows = parseData().sort((a, b) => a.data?.appBrand?.localeCompare(b.data?.appBrand) || 0) as Array<Rows>;

  return (
    <List>
      {whitelabels.map((whitelabel) => {
        const tableRows = rows.filter((row) => row.whitelabel_id === whitelabel.id);

        return (
          <WhitelabelConfigsTable
            whitelabelId={whitelabel.id}
            whitelabelName={whitelabel.brand}
            whitelabelUuid={whitelabel.uuid}
            whitelabelBrandId={whitelabel.brand_identifier}
            brazeSegmentId={whitelabel.braze_segment_id}
            devBrazeSegmentId={whitelabel.development_braze_segment_id}
            domain={whitelabel.domain}
            rows={tableRows.map((row) => ({ ...row, data: omit(row.data, ['appBrand']) }))}
            key={whitelabel.id}
          />
        );
      })}
    </List>
  );
};

export default ManageConfigs;
