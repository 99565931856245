import './header.scss';

import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import AppCenterBranchDropDown from '../select-drop-down/app-center-branch/index';
import HelixEnvironmentDropDown from '../select-drop-down/helix-environment/index';
import IconButton from '@material-ui/core/IconButton';
import JamboPng from 'assets/images/logo.png';
import OmegaEnvironmentDropDown from '../select-drop-down/omega-environment/index';
import Toolbar from '@material-ui/core/Toolbar';
import { toggleCollapsedNav } from '../../store/actions';
import { useDispatch } from 'react-redux';

interface HeaderProps extends RouteComponentProps {
  navCollapsed?: boolean;
}

const Header = (props: HeaderProps): JSX.Element => {
  const dispatch = useDispatch();
  function onToggleCollapsedNav() {
    // eslint-disable-next-line react/destructuring-assignment
    const val = !props.navCollapsed;
    dispatch(toggleCollapsedNav(val));
  }

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <div className="left-side-content">
          <IconButton className="jr-menu-icon mr-3 d-block d-xl-none" aria-label="Menu" onClick={onToggleCollapsedNav}>
            <span className="menu-icon" />
          </IconButton>

          <Link className="app-logo mr-2 d-none d-sm-block" to="/">
            <img src={JamboPng} alt="Jambo" title="Jambo" />
          </Link>
        </div>
        <div className="drop-down-group">
          <OmegaEnvironmentDropDown />
          <HelixEnvironmentDropDown />
          <AppCenterBranchDropDown />
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.defaultProps = {
  navCollapsed: false,
};

export default withRouter(Header);
