import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface BaseDialogProps {
  title: string;
  open: boolean;
  text?: string;
  children?: JSX.Element | null;
  actions?: Array<JSX.Element>;
  handleClose?: () => void;
}

interface DialogProps {
  open: boolean;
  scroll: 'body' | 'paper';
  onClose?: () => void;
}

const BaseDialog = ({ title, open, text, children, handleClose, actions }: BaseDialogProps): JSX.Element => {
  const dialogProps: DialogProps = {
    open,
    scroll: 'body',
    onClose: handleClose,
  };

  return (
    <div>
      <Dialog {...dialogProps} aria-labelledby="form-dialog-title">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {text && <DialogContentText>{text}</DialogContentText>}
          {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
          {children && <>{children}</>}
        </DialogContent>
        <DialogActions>{actions.map((action: JSX.Element) => action)}</DialogActions>
      </Dialog>
    </div>
  );
};

BaseDialog.defaultProps = {
  text: '',
  children: null,
  actions: [],
  handleClose: () => {
    /* do nothing */
  },
};

export default BaseDialog;
