import { get, isEmpty, isString, lowerCase, startCase } from 'lodash';

import Build from 'models/Build';
import { BuildStatuses } from '../const';
import BuildsConfig from 'models/BuildsConfig';
import BuildsData from 'models/BuildsData';
import i18n from 'i18next';

export const getBuildStatus = (builds: BuildsData, platform: string): string => {
  const platformConfig: BuildsConfig = get(builds, `${platform}Config`);
  const platformBuilds: Array<Build> = get(builds, `${platform}Builds`);
  const buildResult: string = get(builds, `${platform}Builds[0].result`);
  const appCenterBuildStatus: string = get(builds, `${platform}Builds[0].status`);
  const result: string = buildResult || appCenterBuildStatus;

  if ((platformBuilds && isEmpty(platformBuilds)) || isEmpty(builds)) {
    return BuildStatuses.NoBuildsFound;
  }

  if (isString(platformBuilds) || (platformConfig === null && isEmpty(platformBuilds))) {
    return BuildStatuses.Warning;
  }

  return result || BuildStatuses.Loading;
};

export const resolveBuildLabel = (builds: BuildsData, platform: string): string => {
  const status = getBuildStatus(builds, platform);

  switch (status) {
    case BuildStatuses.Failed:
      return i18n.t('status.failed');
    case BuildStatuses.Succeeded:
      return i18n.t('status.succeeded');
    case BuildStatuses.Warning:
      return i18n.t('status.warning');
    case BuildStatuses.Loading:
      return i18n.t('status.loading');
    case BuildStatuses.NoBuildsFound:
      return i18n.t('status.noBuildsFound');
    case BuildStatuses.InProgress:
      return i18n.t('status.inProgress');
    case BuildStatuses.Canceled:
      return i18n.t('status.canceled');
    default:
      return lowerCase(startCase(status));
  }
};
