import './spinner.scss';

import CircularProgress from '@material-ui/core/CircularProgress';

export const SPINNER_SIZES = {
  small: 16,
  medium: 32,
  large: 48,
  global: 48,
};

export enum SpinnerStyle {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  GLOBAL = 'global',
}

interface SpinnerProps {
  style: SpinnerStyle;
}

const Spinner = ({ style = SpinnerStyle.LARGE }: SpinnerProps): JSX.Element => (
  <div className={`spinner-container ${style}`}>
    <CircularProgress size={SPINNER_SIZES[style]} />
  </div>
);

export default Spinner;
