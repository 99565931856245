import './widget-card.scss';

import { Card, CardTitle } from 'reactstrap';

import { ReactNode } from 'react';

interface WidgetCardProps {
  children: ReactNode;
  title?: string;
  styleName?: string;
}

const WidgetCard = ({ children, styleName, title }: WidgetCardProps): JSX.Element => (
  <Card className={`widget-card ${styleName || ''}`}>
    {title ? <CardTitle>{title}</CardTitle> : null}
    {children}
  </Card>
);

WidgetCard.defaultProps = {
  title: '',
  styleName: '',
};

export default WidgetCard;
