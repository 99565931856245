import './theme-section.scss';

import { Button, TextField } from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import { Check, Close, Delete, Edit } from '@material-ui/icons';

import Theme from 'models/Theme';
import { useTranslation } from 'react-i18next';

interface Props {
  initialPlacement: string;
  themeSection: Theme;
  changeTheme: (value: Theme) => void;
  otherFonts: Array<string>;
}

const FontElement = ({ initialPlacement, themeSection, changeTheme, otherFonts }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState<boolean>(false);
  const [placement, setPlacement] = useState<string>(initialPlacement);
  const [url, setUrl] = useState<string>(themeSection.fonts[initialPlacement]?.url || '');
  const [fontFamily, setFontFamily] = useState<string>(themeSection.fonts[initialPlacement]?.font_family || '');
  const saveDisabled = !placement.trim() || !url.trim() || !fontFamily.trim() || otherFonts.includes(placement.trim());

  function setValue(setFunction: (value: string) => void) {
    return (event: ChangeEvent<HTMLInputElement>) => setFunction(event.target.value);
  }

  function startEdit() {
    setEdit(true);
  }

  function closeEdit() {
    setEdit(false);
    setPlacement(initialPlacement);
    setUrl(themeSection.fonts[initialPlacement].url);
    setFontFamily(themeSection.fonts[initialPlacement].font_family);
  }

  function deleteFont() {
    const newFonts = { ...themeSection.fonts };
    delete newFonts[initialPlacement];
    changeTheme({ ...themeSection, fonts: newFonts });
  }

  function saveFont() {
    const newFonts = { ...themeSection.fonts };
    newFonts[placement] = {
      url,
      font_family: fontFamily,
    };
    if (placement !== initialPlacement) {
      delete newFonts[initialPlacement];
    }
    changeTheme({ ...themeSection, fonts: newFonts });
    setEdit(false);
  }

  return (
    <div className="font-element-container">
      <div className="font-element-header">
        {initialPlacement}
        {edit ? (
          <>
            <Button onClick={saveFont} disabled={saveDisabled}>
              <Check />
            </Button>
            <Button onClick={closeEdit}>
              <Close />
            </Button>
          </>
        ) : (
          <Button onClick={startEdit}>
            <Edit />
          </Button>
        )}
        <Button onClick={deleteFont}>
          <Delete />
        </Button>
      </div>
      {otherFonts.includes(placement.trim()) && (
        <div className="placement-error">{t('dialogs.addFont.placement-error')}</div>
      )}
      <TextField
        className="text-field"
        label={t('release-apps.fonts.placement')}
        value={placement}
        onChange={setValue(setPlacement)}
        autoComplete="off"
        disabled={!edit}
        hidden={!edit}
      />
      <TextField
        className="text-field"
        label={t('release-apps.fonts.url')}
        value={url}
        onChange={setValue(setUrl)}
        autoComplete="off"
        disabled={!edit}
      />
      <TextField
        className="text-field"
        label={t('release-apps.fonts.font-family')}
        value={fontFamily}
        onChange={setValue(setFontFamily)}
        autoComplete="off"
        disabled={!edit}
      />
    </div>
  );
};

export default FontElement;
