import {
  BASE_CONFIG_ID,
  CONFIG_ASSETS,
  HQO_WHITELABEL_ID,
  PLATFORMS,
  ROUTES,
  STATUS_MESSAGES,
  WHITELABELS,
  WHITELABEL_CONFIGS,
} from 'const';
import {
  CHANGE_APPS_VERSION,
  CREATE_CONFIG,
  CREATE_WHITELABEL,
  DELETE_CONFIG,
  DELETE_WHITELABEL,
  GET_CONFIG,
  GET_WHITELABEL,
  GET_WHITELABELS,
  RUN_WHITELABEL_BUILD,
  SET_BUILDS,
  UPDATE_CONFIG,
  UPDATE_WHITELABEL,
  UPLOAD_CONFIG_CERTIFICATE,
} from 'constants/ActionTypes';
import {
  ChangeAppsVersion,
  CreateConfig,
  CreateWhitelabel,
  CreateWhitelabelSuccessActionPayload,
  DeleteConfig,
  DeleteWhitelabel,
  GetConfig,
  GetWhitelabel,
  RunWhitelabelBuild,
  SetBuilds,
  UpdateConfig,
  UpdateConfigActionPayload,
  UpdateWhitelabel,
  UploadCertificate,
  UploadCertificatePayload,
} from '../actions/types/Whitelabels';
import { CreateWhitelabelActionPayload, UpdateWhitelabelActionPayload } from 'store/actions/types/Whitelabels';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  changeAppsVersionSuccess,
  createConfigSuccess,
  createWhitelabelSuccess,
  deleteConfigSuccess,
  deleteWhitelabelSuccess,
  getConfigSuccess,
  getReleaseNotesSuccess,
  getWhitelabelSuccess,
  getWhitelabelsSuccess,
  handleCloseWhitelabelModal,
  handleCreateConfigModal,
  runWhitelabelBuildSuccess,
  setBuildLoading,
  setBuildsFailure,
  setBuildsSuccess,
  updateConfigSuccess,
  updateWhitelabelSuccess,
} from '../actions';
import {
  deleteConfig,
  deleteWhitelabel,
  getConfig,
  getConfigs,
  getWhitelabel,
  getWhitelabels,
  runCircleCiWhitelabelBuild,
  uploadCertificate,
  uploadImage,
} from 'services/helix/helix.service';
import { get, isString } from 'lodash';
import { getAppcenterVars, getPlatformData } from 'util/whitelabel-config.utils';
import { loadBuildsSaga, loadConfigSaga, saveAppsVersionRequest } from 'services/helix.service';
import { selectWhitelabelConfigByBranch, selectWhitelabelConfigs } from '../selectors/whitelabel-config-selector';

import Build from 'models/Build';
import BuildsConfig from 'models/BuildsConfig';
import BuildsData from 'models/BuildsData';
import Config from 'models/Config';
import { NotificationManager } from 'react-notifications';
import Whitelabel from 'models/Whitelabel';
import WhitelabelConfigQuery from 'models/WhitelabelConfigQuery';
import WhitelabelQuery from 'models/WhitelabelQuery';
import WhitelabelsQuery from 'models/WhitelabelsQuery';
import api from 'services/helix/helix.api';
import appConfig from 'config';
import { getImageUrl } from 'util/get-image-url.utils';
import i18n from '../../i18n';
import initBuilds from 'util/whitelabel.utils';
import { redirectToPage } from '../history';
import { runWhitelabelBuild } from 'services/appcenter/appcenter.service';
import { selectBaseConfig } from '../selectors/base-config-selector';
import { trackPromise } from 'react-promise-tracker';

const sagaErrorKey = 'notifications.sagaError';

export const getWhitelabelsRequest = async (): Promise<Array<Whitelabel> | Error> =>
  await trackPromise(
    getWhitelabels()
      .then((data: WhitelabelsQuery) => get(data, 'data.whitelabels', []))
      .catch((error: Error) => error)
  );

export const getWhitelabelRequest = async (uuid: string): Promise<Whitelabel | null | Error> =>
  await trackPromise(
    getWhitelabel(uuid)
      .then((data: WhitelabelQuery) => get(data, 'data.whitelabel', null))
      .catch((error: Error) => error)
  );

export const getConfigsRequest = async (): Promise<Array<Config> | Error> =>
  await trackPromise(
    getConfigs()
      .then((data: Array<Config>) => data || [])
      .catch((error: Error) => error)
  );

export const getConfigRequest = async (id: number): Promise<Config | null | Error> =>
  await trackPromise(
    getConfig(id)
      .then((data: WhitelabelConfigQuery) => get(data, 'data.whitelabel_config', null))
      .catch((error: Error) => error)
  );

export const updateConfigRequest = async (config: Config): Promise<void> =>
  trackPromise(api.put<{ status: string }>(`${WHITELABEL_CONFIGS}/${config.id}`, config)).then((e) => {
    if (e.status === 'ok') {
      NotificationManager.success(i18n.t('notifications.success', { eventName: 'Configuration saved' }));
    }
  });

export const uploadCertificateRequest = async (payload: UploadCertificatePayload): Promise<unknown> =>
  await trackPromise(uploadCertificate(payload)).catch((error: Error) => error);

export const createConfigRequest = async (config: Partial<Config>): Promise<Config | Error> =>
  await trackPromise(
    api
      .post(`${WHITELABEL_CONFIGS}`, config)
      .then((newConfig: Config) => newConfig)
      .catch((error: Error) => error)
  );

export const updateWhitelabelRequest = async (payload: UpdateWhitelabelActionPayload): Promise<Whitelabel | Error> =>
  await trackPromise(
    api
      .put(`${WHITELABELS}/${payload.uuid}`, payload)
      .then((whitelabel: Whitelabel) => whitelabel)
      .catch((error: Error) => error)
  );

export const createWhitelabelRequest = async (payload: CreateWhitelabelActionPayload): Promise<Whitelabel | Error> =>
  await trackPromise(
    api
      .post(WHITELABELS, payload)
      .then((whitelabel: Whitelabel) => whitelabel)
      .catch((error: Error) => error)
  );

export const uploadImageRequest = async (file: string | HTMLCanvasElement): Promise<string | Error> => {
  if (isString(file)) {
    return file;
  }
  return await trackPromise(
    uploadImage(file)
      .then((json) => getImageUrl(decodeURIComponent(json.path)))
      .catch((error: Error) => error)
  );
};

export function* uploadConfigCertificateSaga({ payload }: UploadCertificate): Generator {
  try {
    const { status } = (yield call(uploadCertificateRequest, payload)) as { status: string };
    if (status === STATUS_MESSAGES.OK) {
      const config = (yield call(getConfigRequest, payload.id)) as Config;
      if (config) {
        yield put(getConfigSuccess({ config }));
      }
    } else {
      NotificationManager.error(i18n.t('notifications.requestFailed', { requestName: 'uploadConfigCertificate' }));
    }
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'uploadConfigCertificate', error: error.message }));
  }
}

function* getWhitelabelsSaga() {
  try {
    const whitelabels: Array<Whitelabel> = yield call(getWhitelabelsRequest);
    const configs: Array<Config> = yield call(getConfigsRequest);
    whitelabels.forEach((whitelabel: Whitelabel) => {
      initBuilds(whitelabel);
    });
    yield put(getWhitelabelsSuccess({ items: whitelabels, configs }));
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'getWhitelabels', error: error.message }));
  }
}

function* getWhitelabelSaga({ payload }: GetWhitelabel) {
  try {
    const whitelabel: Whitelabel = yield call(getWhitelabelRequest, payload.uuid);
    if (whitelabel) {
      initBuilds(whitelabel);
    }
    yield put(getWhitelabelSuccess({ item: whitelabel }));
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'getWhitelabel', error: error.message }));
  }
}

export function* setBuildsByConfigSaga(id: number, config: Config): Generator {
  try {
    const appcenterKey = get(config, 'build_data.appcenterConfig.apiToken', '');

    const { owner, ios, android } = (yield call(getAppcenterVars, config)) as {
      owner: string;
      ios: string;
      android: string;
    };

    const builds: BuildsData = {
      iosConfig: (yield call(loadConfigSaga, owner, ios, appcenterKey)) as BuildsConfig,
      androidConfig: (yield call(loadConfigSaga, owner, android, appcenterKey)) as BuildsConfig,
      iosBuilds: (yield call(loadBuildsSaga, owner, ios, appcenterKey)) as Array<Build>,
      androidBuilds: (yield call(loadBuildsSaga, owner, android, appcenterKey)) as Array<Build>,
    };

    yield put(setBuildsSuccess({ builds, id }));
    if (id === HQO_WHITELABEL_ID && builds.iosConfig) {
      // if its hqo config, get the hqo release notes as a base
      const notes = get(builds.iosConfig, 'toolsets.distribution.releaseNotes', '');
      yield put(getReleaseNotesSuccess(notes));
    }
  } catch (e) {
    yield put(setBuildsFailure({ id }));
  }
}

export function* setBuildsSaga({ payload }: SetBuilds): Generator {
  const { id, config } = payload;

  try {
    if (config) {
      yield call(setBuildsByConfigSaga, id, config);
    } else {
      const configs = (yield select(selectWhitelabelConfigs, id)) as Array<Config>;
      yield all(configs.map((selectedConfig: Config) => call(setBuildsByConfigSaga, id, selectedConfig)));
    }
  } catch (e) {
    yield put(setBuildsFailure({ id }));
  }
}

export function* changeAppsVersionSaga({ payload }: ChangeAppsVersion): Generator {
  try {
    const baseConfig = (yield select(selectBaseConfig)) as Config;
    baseConfig.build_data.appVersion = payload;

    yield call(saveAppsVersionRequest, baseConfig);

    yield put(changeAppsVersionSuccess({ configId: BASE_CONFIG_ID, config: baseConfig }));
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'changeAppVersion', error: error.message }));
  }
}

export function* runWhitelabelBuildSaga({ payload }: RunWhitelabelBuild): Generator {
  if (payload.platform === PLATFORMS.CIRCLE_CI) {
    try {
      yield call(runCircleCiWhitelabelBuild, {
        core: payload.id.toString(),
        branch: 'develop',
      });
      NotificationManager.success('CircleCI build started');
    } catch (error) {
      NotificationManager.error('An unexpected error occurred. Please try again or contact support!');
    }
  } else {
    try {
      yield put(
        setBuildLoading({
          id: payload.id,
          platform: payload.platform,
          isLoading: true,
        })
      );
      const config = (yield select(selectWhitelabelConfigByBranch, payload.id)) as Config;

      const { owner, appcenterKey } = (yield call(getAppcenterVars, config)) as {
        owner: string;
        appcenterKey: string;
      };
      const platformData = (yield call(getPlatformData, payload.platform, config)) as {
        platform: string;
        platformBuilds: string;
      } | null;

      if (!platformData || !platformData.platform) {
        throw new Error(`Failed to run build for whitelabel ${payload.id} platform ${payload.platform}!`);
      }

      const build = (yield call(
        runWhitelabelBuild,
        owner,
        platformData.platform,
        appConfig.appcenterBranch,
        appcenterKey
      )) as Build;

      if (!config || !build?.buildNumber) {
        throw Error;
      }

      yield put(
        runWhitelabelBuildSuccess({
          build,
          id: payload.id,
          platformBuilds: platformData.platformBuilds,
          platform: payload.platform,
        })
      );
    } catch (error) {
      yield put(
        setBuildLoading({
          id: payload.id,
          platform: payload.platform,
          isLoading: false,
        })
      );
    }
  }
}

export function* getConfigSaga({ payload }: GetConfig): Generator {
  try {
    const config = (yield call(getConfigRequest, payload.id)) as Config;
    if (config) {
      yield put(getConfigSuccess({ config }));
    }
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'getConfig', error: error.message }));
  }
}

export function* updateConfigSaga({ payload }: UpdateConfig): Generator {
  try {
    const { config, imagesHash }: UpdateConfigActionPayload = payload;
    if (imagesHash) {
      const icon = (yield call(uploadImageRequest, imagesHash[CONFIG_ASSETS.APP_ICON])) as string;
      const headerLogo = (yield call(uploadImageRequest, imagesHash[CONFIG_ASSETS.HEADER_LOGO])) as string;
      const landlordBranding = (yield call(uploadImageRequest, imagesHash[CONFIG_ASSETS.LANDLORD_BRANDING])) as string;
      const headerImage = (yield call(uploadImageRequest, imagesHash.headerImage)) as string;
      const launchImage = (yield call(uploadImageRequest, imagesHash.launchImage)) as string;

      if (imagesHash[CONFIG_ASSETS.ANDROID_NOTIFICATION_ICON]) {
        config.build_data.assets[CONFIG_ASSETS.ANDROID_NOTIFICATION_ICON] = (yield call(
          uploadImageRequest,
          imagesHash[CONFIG_ASSETS.ANDROID_NOTIFICATION_ICON]
        )) as string;
      }
      config.build_data.iconImage = icon;
      config.build_data.assets[CONFIG_ASSETS.APP_ICON] = icon;
      config.build_data.assets[CONFIG_ASSETS.HEADER_LOGO] = headerLogo;
      config.build_data.assets[CONFIG_ASSETS.LANDLORD_BRANDING] = landlordBranding;
      config.build_data.headerImage = headerImage;
      config.build_data.launchImage = launchImage;
    }

    yield call(updateConfigRequest, config);
    yield put(updateConfigSuccess({ config }));
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'updateConfig', error: error.message }));
  }
}

export function* createConfigSaga({ payload }: CreateConfig): Generator {
  try {
    const { config, targetPageRoute } = payload;
    const data = (yield call(createConfigRequest, config)) as Config;

    yield put(createConfigSuccess({ config: data }));

    // if no errors during the action
    redirectToPage(targetPageRoute || ROUTES.RELEASE_APPS);
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'createConfig', error: error.message }));
  }
}

export function* deleteConfigSaga({ payload }: DeleteConfig): Generator {
  try {
    const { id, targetPageRoute } = payload;

    yield call(deleteConfigById, id);

    // if no errors during the action and target page route to redirect exist
    if (targetPageRoute) {
      redirectToPage(targetPageRoute);
    }
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'deleteConfig', error: error.message }));
  }
}

export function* deleteConfigById(id: number): Generator {
  try {
    yield call(trackPromise, deleteConfig(id));

    yield put(deleteConfigSuccess(id));
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'deleteConfigById', error: error.message }));
  }
}

export function* createWhitelabelSaga({ payload }: CreateWhitelabel): Generator {
  try {
    const {
      isConfigDialogOpened,
      brand,
      brand_identifier,
      is_whitelabel,
      domain,
      braze_segment_id,
      development_braze_segment_id,
    } = payload;

    const { data } = (yield call(createWhitelabelRequest, {
      brand,
      brand_identifier,
      is_whitelabel,
      domain,
      braze_segment_id,
      development_braze_segment_id,
    })) as { data: { whitelabel: CreateWhitelabelSuccessActionPayload; message: string } };

    if (data.whitelabel.id) {
      initBuilds(data.whitelabel);

      yield put(createWhitelabelSuccess(data.whitelabel));
      yield put(handleCloseWhitelabelModal());

      if (isConfigDialogOpened) {
        yield put(handleCreateConfigModal(data.whitelabel.id, ROUTES.RELEASE_APPS));
      } else {
        // if no errors during the action and decline config creation
        redirectToPage(ROUTES.RELEASE_APPS);
      }
    } else {
      NotificationManager.error(i18n.t('notifications.requestFailed', { requestName: 'createWhitelabel' }));
    }
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'createWhitelabel', error: error.message }));
  }
}

export function* updateWhitelabelSaga({ payload }: UpdateWhitelabel): Generator {
  try {
    const data = (yield call(updateWhitelabelRequest, payload)) as Whitelabel;

    if (data) {
      initBuilds(data);
    }

    yield put(updateWhitelabelSuccess(payload));
    yield put(handleCloseWhitelabelModal());
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'updateWhitelabel', error: error.message }));
  }
}

export function* deleteWhitelabelSaga({ payload }: DeleteWhitelabel): Generator {
  try {
    const configs = (yield select(selectWhitelabelConfigs, payload.id)) as Array<Config>;

    yield call(trackPromise, deleteWhitelabel(payload.uuid));
    yield all(configs.map((selectedConfig: Config) => call(deleteConfigById, selectedConfig.id)));

    yield put(deleteWhitelabelSuccess(payload.id));
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'deleteWhitelabel', error: error.message }));
  }
}

export default function* root(): Generator {
  try {
    yield all([
      takeLatest(GET_WHITELABELS, getWhitelabelsSaga),
      takeLatest(GET_WHITELABEL, getWhitelabelSaga),
      takeEvery(SET_BUILDS, setBuildsSaga),
      takeLatest(CHANGE_APPS_VERSION, changeAppsVersionSaga),
      takeLatest(GET_CONFIG, getConfigSaga),
      takeEvery(RUN_WHITELABEL_BUILD, runWhitelabelBuildSaga),
      takeEvery(UPDATE_CONFIG, updateConfigSaga),
      takeEvery(CREATE_CONFIG, createConfigSaga),
      takeEvery(CREATE_WHITELABEL, createWhitelabelSaga),
      takeEvery(UPDATE_WHITELABEL, updateWhitelabelSaga),
      takeEvery(DELETE_CONFIG, deleteConfigSaga),
      takeEvery(DELETE_WHITELABEL, deleteWhitelabelSaga),
      takeLatest(UPLOAD_CONFIG_CERTIFICATE, uploadConfigCertificateSaga),
    ]);
  } catch (error) {
    NotificationManager.warning(i18n.t(sagaErrorKey, { sagaName: 'rootWhitelabel', error: error.message }));
  }
}
