import './build-spinner.scss';

import Spinner, { SpinnerStyle } from 'components/spinner';

import i18next from 'i18next';

interface BuildSpinnerProps {
  containerStyle?: string;
}

const BuildSpinner = ({ containerStyle }: BuildSpinnerProps): JSX.Element => (
  <div className={`build-spinner ${containerStyle}`}>
    <Spinner style={SpinnerStyle.SMALL} />
    <span> {i18next.t('status.loading')}</span>
  </div>
);

BuildSpinner.defaultProps = {
  containerStyle: '',
};

export default BuildSpinner;
