import WhitelabelPreview, {
  WhitelabelPreviewComponentProps,
  WhitelabelPreviewDispatchProps,
  WhitelabelPreviewStoreProps,
} from 'release-apps/WhitelabelPreview';
import { handleCreateConfigModal, handleDeleteItemModal, handleEditWhitelabelModal } from 'store/actions/Modals';
import { runWhitelabelBuild, setBuilds, setBuildsFailure } from 'store/actions/Whitelabels';

import AppState from 'models/AppState';
import { connect } from 'react-redux';

const mapStateToProps = (
  { whitelabels }: AppState,
  { id }: WhitelabelPreviewComponentProps
): WhitelabelPreviewStoreProps => {
  const { items, configs } = whitelabels;
  const whitelabel = items.find((item) => item.id === id);
  const whitelabelConfigs = configs.filter((item) => item.whitelabel_id === id);

  return { whitelabel, configs: whitelabelConfigs };
};

const mapDispatchToProps: WhitelabelPreviewDispatchProps = {
  setBuilds,
  setBuildsFailure,
  runWhitelabelBuild,
  handleCreateConfigModal,
  handleEditWhitelabelModal,
  handleDeleteItemModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(WhitelabelPreview);
