import { MASTER_OPTION_ID, PLATFORMS, SET_DEFAULT_OPTION_ID, STAGING_OPTION_ID } from 'const';
import { getAppcenterVars, getPlatformData } from 'util/whitelabel-config.utils';
import { useEffect, useState } from 'react';

import AppState from 'models/AppState';
import Option from 'models/Option';
import SelectDropDown from '../base/index';
import { getBranchesList } from 'services/appcenter/appcenter.service';
import i18n from 'i18next';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

interface Branch {
  branch: {
    name: string;
  };
}

const AppCenterBranchDropDown = (): JSX.Element => {
  const configs = useSelector((store: AppState) => store.whitelabels.configs);
  const [options, setOptions] = useState<Array<Option<string>>>([]);
  const defaultOptions: Array<Option<string>> = [
    {
      id: SET_DEFAULT_OPTION_ID,
      label: i18n.t('options.app-center.setDefault'),
      handleOptionClick: () => {
        /* do nothing */
      },
    },
    {
      id: MASTER_OPTION_ID,
      label: i18n.t('options.app-center.master'),
      handleOptionClick: () => {
        /* do nothing */
      },
    },
    {
      id: STAGING_OPTION_ID,
      label: i18n.t('options.app-center.staging'),
      handleOptionClick: () => {
        /* do nothing */
      },
    },
  ];

  useEffect(() => {
    const fetchBranches = async () => {
      const { owner, appcenterKey } = getAppcenterVars(configs[0]);
      const platformData = getPlatformData(PLATFORMS.IOS, configs[0]);
      const branches = (await getBranchesList(owner, platformData.platform, appcenterKey)) as Array<Branch>;

      if (branches?.length) {
        setOptions(
          branches
            .reduce((result, item) => {
              const {
                branch: { name },
              } = item;
              if (name !== MASTER_OPTION_ID && name !== STAGING_OPTION_ID) {
                result.push({
                  id: name,
                  label: name,
                  handleOptionClick: () => {
                    /* do nothing */
                  },
                });
              }

              return result;
            }, [])
            .sort()
        );
      }
    };

    if (!isEmpty(configs)) {
      fetchBranches();
    }
  }, [configs]);

  return (
    <SelectDropDown
      defaultOptions={defaultOptions}
      options={options}
      defaultValue={i18n.t('options.app-center.default')}
      className="select-drop-down"
      isDividerNeeded
    />
  );
};

export default AppCenterBranchDropDown;
