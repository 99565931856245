import { SIGNIN_USER, SIGNOUT_USER } from 'constants/ActionTypes';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { showAuthMessage, userSignInSuccess, userSignOutSuccess } from '../actions';

import { NotificationManager } from 'react-notifications';
import User from 'models/User';
import { UserSignInAction } from 'store/actions/types/Auth';
import _ from 'lodash';
import api from '../../services/helix/helix.api';
import i18n from '../../i18n';
import { trackPromise } from 'react-promise-tracker';

const signInUserWithEmailPasswordRequest = async (email: string, password: string) =>
  await trackPromise(
    api
      .post('auth/login', { email, password })
      .then((authUser: User) => authUser)
      .catch((error: Error) => error)
  );

function* signInUserWithEmailPassword({ payload }: UserSignInAction) {
  const { email, password } = payload;
  try {
    const signIn = (yield call(signInUserWithEmailPasswordRequest, email, password)) as {
      user: User;
      token: string;
      message: string;
    };
    if (signIn.message || !_.get(signIn, 'user.hqo', false)) {
      yield put(showAuthMessage(i18n.t('login.invalid-login-error')));
    } else {
      localStorage.setItem('user_id', signIn.user.id.toString());
      localStorage.setItem('user_uuid', signIn.user.uuid);
      localStorage.setItem('user', JSON.stringify(signIn.user));
      localStorage.setItem('token', signIn.token);
      yield put(userSignInSuccess(signIn.user));
    }
  } catch (error) {
    yield put(showAuthMessage(i18n.t('login.invalid-login-error')));
  }
}

function* signOut() {
  try {
    localStorage.removeItem('user_id');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    yield put(userSignOutSuccess());
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

export function* signInUser(): Generator {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser(): Generator {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga(): Generator {
  try {
    yield all([fork(signInUser), fork(signOutUser)]);
  } catch (error) {
    NotificationManager.warning(error.message);
  }
}
