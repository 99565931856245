import {
  APP_BRAND,
  CONTENT_TYPES,
  HQO_UUID,
  HQO_VERSION,
  HTTP_METHODS,
  REQUEST_TYPE,
  Region,
  STATUS_CODES,
  VERSION,
} from '../../const';

import config, { euConfig } from '../../config';
import FilePayload from 'models/FilePayload';
import Request from './Request';
import _ from 'lodash';
import { reportError } from 'util/reportError';
import { storage } from '../local-storage.service';
import { toast } from 'react-toastify';
import { trackPromise } from 'react-promise-tracker';
import uuid from 'uuid/v4';

function handleApiResponse<TOutput>(response: Response, request: Request) {
  if (response?.status && isError(response.status)) {
    reportError({
      message: `${response.status} ${request.method} ${response.url}`,
      error: response as unknown as Error,
    });
    errorToastHandler(response.status);
    throw new Error(response.statusText);
  }

  if (response.status === STATUS_CODES.NO_CONTENT) {
    return undefined;
  }

  return response?.json() as Promise<TOutput>;
}

async function sendRequest<TOutput>(
  method: string,
  url: string,
  payload: unknown,
  version = VERSION
): Promise<TOutput> {
  const buildingId = buildingUUID();
  const hqoTraceId = uuid();
  const token = getToken();

  const cleanedPayload = cleanData(payload);

  const request: Request = {
    method,
    redirect: 'follow',
    headers: {
      ...DEFAULT_HEADERS,
      Accept: version,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': CONTENT_TYPES.JSON,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'HqO-App-Brand': APP_BRAND,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'HqO-Building-UUID': buildingId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Hqo-Trace-Id': hqoTraceId,
    },
  };
  addAuthHeaders(request, token);
  if (cleanedPayload) {
    request.body = JSON.stringify(cleanedPayload);
  }

  return trackPromise(fetch(getApiUrl(url), request).then((response) => handleApiResponse<TOutput>(response, request)));
}

async function uploadFile<TOutput>(
  url: string,
  method: string,
  payload: FilePayload,
  version = VERSION
): Promise<TOutput> {
  const buildingId = buildingUUID();
  const hqoTraceId = uuid();
  const token = getToken();

  const request: Request = {
    method,
    headers: {
      ...DEFAULT_HEADERS,
      Accept: version,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'HqO-App-Brand': APP_BRAND,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'HqO-Building-UUID': buildingId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Hqo-Trace-Id': hqoTraceId,
    },
  };

  addAuthHeaders(request, token);

  const data = new FormData();
  data.append(payload.key, payload.file);

  request.body = data;

  return trackPromise(fetch(getApiUrl(url), request).then((response) => handleApiResponse<TOutput>(response, request)));
}

function del<TOutput>(url: string): Promise<TOutput> {
  return sendRequest<TOutput>(HTTP_METHODS.DELETE, url, null);
}

function put<TOutput>(url: string, payload: unknown): Promise<TOutput> {
  return sendRequest<TOutput>(HTTP_METHODS.PUT, url, payload);
}

function post<TOutput>(url: string, payload: unknown): Promise<TOutput> {
  return sendRequest<TOutput>(HTTP_METHODS.POST, url, payload);
}

function get<TOutput>(url: string): Promise<TOutput> {
  return sendRequest<TOutput>(HTTP_METHODS.GET, url, null);
}

const buildingUUID = () => window.localStorage.currentBuildingUuid;

const getToken = () => storage.get('token');

const DEFAULT_HEADERS = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'HqO-App-Version': HQO_VERSION,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'HqO-App-UUID': HQO_UUID,
  hqoVersion: HQO_VERSION,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'X-Requested-With': REQUEST_TYPE,
};

const errorToastHandler = (status: number): void => {
  if (status === STATUS_CODES.FORBIDDEN) {
    toast.error('You do not have permission to do this!');
  } else if (status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
    toast.error('Validation failed. Please check all required fields have been filled!');
  } else if (status === STATUS_CODES.INTERNAL_SERVER_ERROR) {
    toast.error('API broken. Please try again in a moment or contact support!');
  } else if (status >= STATUS_CODES.MULTIPLE_CHOICE && status <= STATUS_CODES.NETWORK_CONNECT_TIMEOUT_ERROR) {
    toast.error('An unexpected error occurred. Please try again or contact support!');
  }
};

const cleanData = (data: unknown) => {
  // Replace any empty strings with NULL. Some API validations will fail.
  if (!data) {
    return null;
  }
  const newData = { ...(data as { [key: string]: string | unknown }) };

  _.each(newData, (value: string, key: string) => {
    if (typeof value === 'string' && !value.length) {
      newData[key] = null;
    }
  });

  return newData;
};

const isError = (status: number) => status < STATUS_CODES.OK || status >= STATUS_CODES.MULTIPLE_CHOICE;

export const addAuthHeaders = (request: Request, token: string): void => {
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
};

const defaultRegion = 'us';

export const setHelixRegion = (region: Region): void => {
  localStorage.setItem('region', region);
};

export const getHelixRegion = (): string => localStorage.getItem('region') || defaultRegion;

const getApiUrl = (url: string) => `${getHelixRegion() === defaultRegion ? config.apiURL : euConfig.apiUrl}${url}`;

const helixApi = {
  del,
  put,
  post,
  get,
  errorToastHandler,
  uploadFile,
};

export default helixApi;
