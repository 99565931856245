import './options.scss';

import { MouseEvent as ReactMouseEvent, useState } from 'react';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Option from 'models/Option';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Row from '../models/Row';
// eslint-disable-next-line import/no-unresolved
import { TransitionProps as Transition } from '@material-ui/core/transitions/transition';

interface OptionsProps {
  options: Array<Option<Row>>;
  row: Row;
}

interface PopperChildrenProps {
  TransitionProps: Transition;
  placement: string;
}

// eslint-disable-next-line react/function-component-definition
export default function Options({ options, row }: OptionsProps): JSX.Element {
  const [element, setElement] = useState<null | HTMLElement>(null);

  function handleClick(event: ReactMouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setElement(event.currentTarget);
  }

  function handleClose() {
    setElement(null);
  }

  function handleOptionClick(option: Option<Row>) {
    return () => {
      option.handleOptionClick(row);
      handleClose();
    };
  }

  return (
    <div>
      <Button
        aria-controls={Boolean(element) ? 'options' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className="options-button"
      >
        <MoreVertIcon />
      </Button>
      <Popper open={Boolean(element)} anchorEl={element} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }: PopperChildrenProps) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: `center ${placement === 'bottom' ? 'top' : 'bottom'}`,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={Boolean(element)} id="options">
                  {options.map((option: Option<Row>) => (
                    <MenuItem key={option.label} onClick={handleOptionClick(option)}>
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
