import { Reducer, combineReducers } from 'redux';

import Auth from './Auth';
import { History } from 'history';
import Modals from './Modals';
import Settings from './Settings';
import Whitelabels from './Whitelabels';
import { connectRouter } from 'connected-react-router';

export default (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    whitelabels: Whitelabels,
    modals: Modals,
  });
