import { FIXED_DRAWER, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH } from 'constants/ActionTypes';

import AppSettings from 'models/AppSettings';
import { SettingsActionTypes } from 'store/actions/types/Settings';

const initialSettings: AppSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  themeColor: 'blue',
  width: window.innerWidth,
  companyName: 'HqO',
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
};

// eslint-disable-next-line default-param-last
const settings = (state = initialSettings, action: SettingsActionTypes): AppSettings => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.payload,
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.payload,
      };
    default:
      return state;
  }
};

export default settings;
