import ConfigSectionData from 'models/ConfigSectionData';
import GeneralSection from '../../general-section/GeneralSection';
import { PlistData } from 'models/PlistData';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';

interface SpreedlyProductionSectionProps {
  plistSection: PlistData;
  setPlistSection: (value: PlistData) => void;
  handleSave?: () => void;
  resetFields?: () => void;
}

const SpreedlyProductionSection = ({
  plistSection,
  setPlistSection,
  handleSave,
  resetFields,
}: SpreedlyProductionSectionProps): JSX.Element => {
  const { t } = useTranslation();

  function setSection(key: string, value: string) {
    setPlistSection({
      ...plistSection,
      spreedlyProduction: { ...(plistSection.spreedlyProduction as ConfigSectionData), [key]: value },
    });
  }

  function deleteField(key: string) {
    setPlistSection({
      ...plistSection,
      spreedlyProduction: { ...omit(plistSection.spreedlyProduction as ConfigSectionData, key) },
    });
  }

  return (
    <GeneralSection
      section={plistSection.spreedlyProduction as ConfigSectionData}
      title={t('release-apps.config-info.config-tab.sections.spreedlyProduction')}
      setSection={setSection}
      deleteField={deleteField}
      fieldsToExclude={null}
      isDynamic
      handleSave={handleSave}
      resetFields={resetFields}
    />
  );
};

SpreedlyProductionSection.defaultProps = {
  handleSave: () => {
    /* do nothing */
  },
  resetFields: () => {
    /* do nothing */
  },
};

export default SpreedlyProductionSection;
