import './add-font.scss';
import BaseDialog from '../base/index';
import { Button } from '@material-ui/core';
import { DEFAULT_VALIDATION_OPTIONS } from 'constants/ActionTypes';
import ReactHookFormError from '../../../models/ReactHookFormError';

import ValidationTextField from 'components/validation-text-field';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (placement: string, url: string, fontFamily: string) => void;
  fontList: Array<string>;
}

const AddFont = ({ open, onClose, onSubmit, fontList }: Props): JSX.Element => {
  const [placement, setPlacement] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [fontFamily, setFontFamily] = useState<string>('');
  const {
    formState: { errors },
    control,
  } = useForm(DEFAULT_VALIDATION_OPTIONS);
  const { t } = useTranslation();
  const saveDisabled = !placement.trim() || !url.trim() || !fontFamily.trim() || fontList.includes(placement.trim());

  function resetState() {
    setPlacement('');
    setUrl('');
    setFontFamily('');
  }

  function handleSubmit() {
    onSubmit(placement, url, fontFamily);
    resetState();
    onClose();
  }

  const actions = [
    <Button key="cancel" variant="text" color="default" onClick={onClose}>
      {t('buttons.cancel')}
    </Button>,
    <Button key="save" variant="contained" color="primary" onClick={handleSubmit} disabled={saveDisabled}>
      {t('buttons.save')}
    </Button>,
  ];

  return (
    <BaseDialog title={t('dialogs.addFont.header')} open={open} handleClose={onClose} actions={actions}>
      <div className="form">
        {fontList.includes(placement.trim()) && (
          <div className="placement-error">{t('dialogs.addFont.placement-error')}</div>
        )}
        <ValidationTextField
          name="font-placement"
          label={t('release-apps.fonts.placement')}
          value={placement}
          setValue={setPlacement}
          type="text"
          errors={errors as unknown as ReactHookFormError}
          control={control}
          required
        />
        <ValidationTextField
          name="font-url"
          label={t('release-apps.fonts.url')}
          value={url}
          setValue={setUrl}
          type="text"
          errors={errors as unknown as ReactHookFormError}
          control={control}
          required
        />
        <ValidationTextField
          name="font-family"
          label={t('release-apps.fonts.font-family')}
          value={fontFamily}
          setValue={setFontFamily}
          type="text"
          errors={errors as unknown as ReactHookFormError}
          control={control}
          required
        />
      </div>
    </BaseDialog>
  );
};

export default AddFont;
