import { MASTER_OPTION_ID, SET_DEFAULT_OPTION_ID, STAGING_OPTION_ID } from 'const';

import Option from 'models/Option';
import SelectDropDown from '../base/index';
import i18n from 'i18next';

const OmegaEnvironmentDropDown = (): JSX.Element => {
  const defaultOptions: Array<Option<string>> = [
    {
      id: SET_DEFAULT_OPTION_ID,
      label: i18n.t('options.omega.setDefault'),
      handleOptionClick: () => {
        /* do nothing */
      },
    },
  ];
  const options: Array<Option<string>> = [
    {
      id: MASTER_OPTION_ID,
      label: i18n.t('options.omega.master'),
      handleOptionClick: () => {
        /* do nothing */
      },
    },
    {
      id: STAGING_OPTION_ID,
      label: i18n.t('options.omega.staging'),
      handleOptionClick: () => {
        /* do nothing */
      },
    },
  ];

  return (
    <SelectDropDown
      defaultOptions={defaultOptions}
      options={options}
      defaultValue={i18n.t('options.omega.default')}
      className="select-drop-down"
    />
  );
};

export default OmegaEnvironmentDropDown;
