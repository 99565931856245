import './types-section.scss';

import { Button, IconButton, TextField, Tooltip } from '@material-ui/core';
import { CFBundleURLTypes, PlistData } from 'models/PlistData';
import { ChangeEvent, useState } from 'react';
import { Check, Close } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FieldsContainer from '../../fields-container/FieldsContainer';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

interface TypesSectionProps {
  plistSection: PlistData;
  setPlistSection: (value: PlistData) => void;
  handleSave?: () => void;
  resetFields?: () => void;
}

const TypesSection = ({ plistSection, setPlistSection, handleSave, resetFields }: TypesSectionProps): JSX.Element => {
  const { t } = useTranslation();

  const types = plistSection.CFBundleURLTypes ? (plistSection.CFBundleURLTypes as CFBundleURLTypes) : [];
  const typesClone = cloneDeep(types);
  const [isEdit, setEditMode] = useState<boolean>(false);

  function addType() {
    typesClone.push({
      CFBundleTypeRole: '',
      CFBundleURLName: '',
      CFBundleURLSchemes: [''],
    });
    setPlistSection({ ...plistSection, CFBundleURLTypes: [...typesClone] });
  }

  function deleteType(typeIndex: number) {
    return () => {
      typesClone.splice(typeIndex, 1);
      setPlistSection({ ...plistSection, CFBundleURLTypes: [...typesClone] });
    };
  }

  function setRole(typeIndex: number) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      typesClone[typeIndex].CFBundleTypeRole = value;
      setPlistSection({ ...plistSection, CFBundleURLTypes: [...typesClone] });
    };
  }

  function setName(typeIndex: number) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      typesClone[typeIndex].CFBundleURLName = value;
      setPlistSection({ ...plistSection, CFBundleURLTypes: [...typesClone] });
    };
  }

  function setSchemeField(typeIndex: number) {
    return (value: string, itemIndex: number) => {
      typesClone[typeIndex].CFBundleURLSchemes[itemIndex] = value;
      setPlistSection({ ...plistSection, CFBundleURLTypes: [...typesClone] });
    };
  }

  function addSchemeField(typeIndex: number) {
    return () => {
      typesClone[typeIndex].CFBundleURLSchemes.push('');
      setPlistSection({ ...plistSection, CFBundleURLTypes: [...typesClone] });
    };
  }

  function deleteSchemeField(typeIndex: number) {
    return (itemIndex: number) => {
      typesClone[typeIndex].CFBundleURLSchemes.splice(itemIndex, 1);
      setPlistSection({ ...plistSection, CFBundleURLTypes: [...typesClone] });
    };
  }

  function handleSectionSave() {
    handleSave();
    setEditMode(!isEdit);
  }

  function resetSection() {
    resetFields();
    setEditMode(!isEdit);
  }

  function handleEditModeChange() {
    setEditMode(!isEdit);
  }

  return (
    <div>
      <div className="title-container">
        <span className="section-title">{t('release-apps.config-info.config-tab.sections.cf-bundle-url-types')}</span>
        <div>
          {isEdit ? (
            <span>
              <Tooltip title={t('release-apps.config-info.config-tab.add-type')} placement="top" arrow>
                <Button onClick={addType} size="small">
                  <AddIcon />
                </Button>
              </Tooltip>
              <Button onClick={handleSectionSave} size="small">
                <Check />
              </Button>
              <Button onClick={resetSection} size="small">
                <Close />
              </Button>
            </span>
          ) : (
            <Button onClick={handleEditModeChange} size="small">
              <EditIcon />
            </Button>
          )}
        </div>
      </div>
      <div>{t('release-apps.config-info.config-tab.sections.cf-bundle-url-types-description')}</div>
      <div>
        {types.map((type, typeIndex) => (
          <div className="types-container" key={`${type.CFBundleTypeRole}-${type.CFBundleURLName}`}>
            <div className="types-header">
              {isEdit && (
                <IconButton onClick={deleteType(+typeIndex)} size="small">
                  <DeleteIcon className="delete-icon" />
                </IconButton>
              )}
            </div>
            <div className="fields-container">
              <TextField
                className="text-field"
                value={type.CFBundleTypeRole}
                disabled={!isEdit}
                label={t('release-apps.config-info.config-tab.labels.cf-bundle-type-role')}
                onChange={setRole(+typeIndex)}
              />
              <TextField
                className="text-field"
                value={type.CFBundleURLName}
                disabled={!isEdit}
                label={t('release-apps.config-info.config-tab.labels.cf-bundle-url-name')}
                onChange={setName(+typeIndex)}
              />
            </div>
            <FieldsContainer
              title={t('release-apps.config-info.config-tab.sections.cf-bundle-url-schemes')}
              titleClassName="sub-title"
              items={type.CFBundleURLSchemes}
              setField={setSchemeField(+typeIndex)}
              addField={addSchemeField(+typeIndex)}
              deleteField={deleteSchemeField(+typeIndex)}
              locked={!isEdit}
              handleSave={handleSave}
              resetFields={resetFields}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

TypesSection.defaultProps = {
  handleSave: () => {
    /* do nothing */
  },
  resetFields: () => {
    /* do nothing */
  },
};

export default TypesSection;
