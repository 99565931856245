import {
  HIDE_MESSAGE,
  INIT_URL,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
} from 'constants/ActionTypes';

import { AuthActionTypes } from './types/Auth';
import User from 'models/User';

export const userSignIn = (user: { email: string; password: string }): AuthActionTypes => ({
  type: SIGNIN_USER,
  payload: user,
});
export const userSignOut = (): AuthActionTypes => ({
  type: SIGNOUT_USER,
});

export const userSignInSuccess = (authUser: User): AuthActionTypes => ({
  type: SIGNIN_USER_SUCCESS,
  payload: authUser,
});
export const userSignOutSuccess = (): AuthActionTypes => ({
  type: SIGNOUT_USER_SUCCESS,
});

export const showAuthMessage = (message: string): AuthActionTypes => ({
  type: SHOW_MESSAGE,
  payload: message,
});

export const setInitUrl = (url: string): AuthActionTypes => ({
  type: INIT_URL,
  payload: url,
});

export const hideMessage = (): AuthActionTypes => ({
  type: HIDE_MESSAGE,
});
