import BaseDialog from '../base/index';
import Button from '@material-ui/core/Button';
import { handleConfirmCancelAllModal } from 'store/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface ConfirmCancelAllDialogProps {
  open: boolean;
}

const BUTTONS_KEYS = {
  YES: 'yes',
  NO: 'no',
};

const ConfirmCancelAllDialog = ({ open }: ConfirmCancelAllDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onCancel = useCallback(() => dispatch(handleConfirmCancelAllModal()), [dispatch]);
  const actions = [
    <Button key={BUTTONS_KEYS.NO} variant="contained" color="secondary" onClick={onCancel}>
      {t('buttons.no')}
    </Button>,
    <Button key={BUTTONS_KEYS.YES} variant="contained" color="primary" onClick={onCancel}>
      {t('buttons.yes')}
    </Button>,
  ];
  const baseDialogProps = {
    title: t('dialogs.confirmCancelAll.title'),
    text: t('dialogs.confirmCancelAll.text'),
    actions,
    open,
    handleClose: onCancel,
  };

  return <BaseDialog {...baseDialogProps} />;
};

export default ConfirmCancelAllDialog;
