import './release-management.scss';

import EditReleaseDialog, { RenderReleaseEditorProps } from '../dialogs/edit-release-dialog';
import { getOmegaData, resolveOmegaAction } from '../../util/release-management.utils';

import BaseTable from 'components/tables/base';
import { Button } from '@material-ui/core';
import { Component } from 'react';
import HeadCell from 'components/tables/models/HeadCell';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Option from 'models/Option';
import { ROUTES } from '../../const';
import Release from 'models/Release';
import ReleaseBuild from '../../models/ReleaseBuild';
import ReleaseData from '../../models/ReleaseData';
import { ReleaseManagmentServerResponse } from '../../models/ReleaseManagementServerResponse';
import { ReleaseTableRow } from 'components/tables/models/Row';
import Row from '../tables/models/Row';
import i18next from 'i18next';

export interface ReleaseManagementState {
  releases: Array<Release>;
  builds: Array<ReleaseBuild>;
  focused: boolean;
  focusedReleaseID: string;
  prevResultsURL: string;
  nextResultsURL: string;
  contextRelease?: ReleaseData;
  showContextRelease: boolean;
  showCreation?: boolean;
}

export interface ReleaseManagementProps {
  renderReleaseEditor: (props: RenderReleaseEditorProps) => void;
}

const renderReleaseEditor = (props: RenderReleaseEditorProps): JSX.Element => <EditReleaseDialog {...props} />;

export class ReleaseManagement extends Component<ReleaseManagementProps, ReleaseManagementState> {
  // Expected columns for the release table
  releaseColumns: Array<HeadCell<Release>> = [
    {
      id: 'uuid',
      align: 'left',
      label: i18next.t('release-management.table.releaseUUID'),
    },
    {
      id: 'notes',
      align: 'right',
      label: i18next.t('release-management.table.notes'),
    },
    {
      id: 'app_version',
      align: 'right',
      label: i18next.t('release-management.table.appVersion'),
    },
    {
      id: 'build_system',
      align: 'right',
      label: i18next.t('release-management.table.buildSystem'),
    },
    {
      id: 'user_uuid',
      align: 'right',
      label: i18next.t('release-management.table.userUUID'),
    },
    {
      id: 'created_at',
      align: 'right',
      label: i18next.t('release-management.table.createdAt'),
    },
    {
      id: 'update_at',
      align: 'right',
      label: i18next.t('release-management.table.updatedAt'),
    },
    {
      id: 'branch',
      align: 'right',
      label: i18next.t('release-management.table.branch'),
    },
    {
      id: 'commit_hash',
      align: 'right',
      label: i18next.t('release-management.table.commit'),
    },
  ];

  // Expected columns for the build table
  buildColumns: Array<HeadCell<ReleaseBuild>> = [
    {
      id: 'uuid',
      align: 'left',
      label: i18next.t('build-management.table.buildUUID'),
    },
    {
      id: 'release_uuid',
      align: 'right',
      label: i18next.t('build-management.table.releaseUUID'),
    },
    {
      id: 'validated',
      align: 'right',
      label: i18next.t('build-management.table.validated'),
    },
    {
      id: 'validation_media',
      align: 'right',
      label: i18next.t('build-management.table.validationMedia'),
    },
    {
      id: 'created_at',
      align: 'right',
      label: i18next.t('build-management.table.createdAt'),
    },
    {
      id: 'update_at',
      align: 'right',
      label: i18next.t('build-management.table.updatedAt'),
    },
    {
      id: 'whitelabel_uuid',
      align: 'right',
      label: i18next.t('build-management.table.whitelabelUUID'),
    },
    {
      id: 'status',
      align: 'right',
      label: i18next.t('build-management.table.status'),
    },
    {
      id: 'session_uuid',
      align: 'right',
      label: i18next.t('build-management.table.sessionUUID'),
    },
    {
      id: 'released_at',
      align: 'right',
      label: i18next.t('build-management.table.releasedAt'),
    },
    {
      id: 'build_system_job_id',
      align: 'right',
      label: i18next.t('build-management.table.buildSystemJobID'),
    },
    {
      id: 'build_number',
      align: 'right',
      label: i18next.t('build-management.table.buildNumber'),
    },
    {
      id: 'built_at',
      align: 'right',
      label: i18next.t('build-management.table.builtAt'),
    },
  ];

  constructor(props: Readonly<ReleaseManagementProps>) {
    super(props);
    const newReleaseArray: Array<Release> = [];
    const newBuildArray: Array<ReleaseBuild> = [];
    this.state = {
      releases: newReleaseArray,
      builds: newBuildArray,
      focused: false,
      focusedReleaseID: '',
      nextResultsURL: '',
      prevResultsURL: '',
      showContextRelease: false,
    };
    this.removeFocus = this.removeFocus.bind(this);
    this.updateReleases();
  }

  // Gets the release table, filled in by the state values
  getReleaseTable(): JSX.Element {
    const { releases, showContextRelease, contextRelease } = this.state;
    const { renderReleaseEditor: renderEditor = renderReleaseEditor } = this.props;
    return (
      <>
        <BaseTable
          rows={this.parseReleaseData(releases)}
          columns={this.releaseColumns}
          options={this.getReleaseRowOptions()}
        />
        {renderEditor({
          closeDialog: this.hideContextRelease,
          open: showContextRelease,
          release: contextRelease,
        })}
      </>
    );
  }

  // Gets the build table, filled in by the state values
  getBuildTable(): JSX.Element {
    const { builds } = this.state;
    return <BaseTable rows={this.parseBuildData(builds)} columns={this.buildColumns} options={null} />;
  }

  // Returns a button with the given styling and on-click functionality
  getFuncButton(title: string, func: () => Promise<void>): JSX.Element {
    return (
      <div>
        <Button onClick={func} variant="contained" color="primary">
          {title}
        </Button>
      </div>
    );
  }

  // Sets the options for a particular release row
  getReleaseRowOptions(): Array<Option<Row>> {
    return [
      {
        label: i18next.t('release-management.see-builds'),
        handleOptionClick: (r: ReleaseTableRow) => {
          this.assignFocus(r.releaseUUID);
          this.updateBuilds(r.releaseUUID);
        },
      },
      {
        label: i18next.t('release-management.launch-builds'),
        handleOptionClick: (r: ReleaseTableRow) => this.startBuildsByReleaseID(r.releaseUUID),
      },
      {
        label: 'Edit Release',
        handleOptionClick: (r: ReleaseTableRow) =>
          this.setState({
            contextRelease: r.data as unknown as ReleaseData,
            showContextRelease: true,
          }),
      },
    ];
  }

  // Gets the next results from the next URL in state
  getNextResults = async (): Promise<void> => {
    const { nextResultsURL } = this.state;
    this.updateReleases(nextResultsURL);
  };

  // Gets the previous results from the previous URL in state
  getPrevResults = async (): Promise<void> => {
    const { prevResultsURL } = this.state;
    this.updateReleases(prevResultsURL);
  };

  hideContextRelease = (): void => {
    this.setState({
      showContextRelease: false,
    });
  };

  refreshBuilds = async (): Promise<void> => {
    const { contextRelease: { releaseUUID } = {} } = this.state;
    this.updateBuilds(releaseUUID);
  };

  refreshReleases = async (): Promise<void> => {
    this.updateReleases();
  };

  // Starts a set of builds chosen by their releaseID
  startBuildsByReleaseID = async (releaseID: string): Promise<void> => {
    const url = `/v1/releases/${releaseID}/launch`;
    await resolveOmegaAction(url)
      .then(() => {
        NotificationManager.success(
          i18next.t('build-management.launch-build-success-message'),
          i18next.t('build-management.launch-build-success-title')
        );
        // Catch errors not recieving a good response
      })
      .catch((err) => {
        NotificationManager.warning(err);
      });
  };

  // Parses a set of releases for display in the release table
  parseReleaseData(releases: Array<Release>): Array<Row> {
    if (releases === null || typeof releases == 'undefined') {
      return [];
    }
    return releases.map((r: Release, index) => {
      const {
        uuid,
        notes,
        app_version,
        build_system,
        user_uuid,
        created_at,
        update_at,
        branch,
        commit_hash,
        platform,
      } = r;
      return {
        id: index,
        releaseUUID: uuid,
        data: {
          releaseUUID: uuid,
          notes,
          appVersion: app_version,
          buildSystem: build_system,
          userUUID: user_uuid,
          createdAt: created_at,
          updatedAt: update_at,
          branch,
          commit: commit_hash,
          platform,
        },
      };
    });
  }

  // Parses a st of builds for display in the build table
  parseBuildData(builds: Array<ReleaseBuild>): Array<Row> {
    if (builds === null || typeof builds == 'undefined') {
      return [];
    }
    return builds.map((b: ReleaseBuild, index) => {
      const {
        uuid,
        release_uuid,
        validated,
        validation_media,
        created_at,
        update_at,
        whitelabel_uuid,
        status,
        session_uuid,
        released_at,
        build_system_job_id,
        build_number,
        built_at,
      } = b;
      return {
        id: index,
        releaseUUID: release_uuid,
        data: {
          buildUUID: uuid,
          releaseUUID: release_uuid,
          validated,
          validationMedia: validation_media,
          createdAt: created_at,
          updatedAt: update_at,
          whitelabelUUID: whitelabel_uuid,
          status,
          sessionUUID: session_uuid,
          releasedAt: released_at,
          buildSystemJobID: build_system_job_id,
          buildNumber: build_number,
          builtAt: built_at,
        },
      };
    });
  }

  async updateReleases(extendedURL = '/v1/releases'): Promise<void> {
    await getOmegaData<ReleaseManagmentServerResponse>(extendedURL)
      .then((resp) => {
        if (resp !== null) {
          const { paging: { next = '', previous = '' } = {}, releases } = resp;

          this.setState({
            releases,
            nextResultsURL: next,
            prevResultsURL: previous,
          });
        }
      })
      .catch((err) => {
        NotificationManager.warning('Unable to get releases', err);
      });
  }

  async updateBuilds(releaseUUID: string): Promise<void> {
    const { focusedReleaseID } = this.state;
    const extendedURL = `/v1/releases/${releaseUUID ?? focusedReleaseID}/builds`;
    await getOmegaData<ReleaseManagmentServerResponse>(extendedURL)
      .then((resp) => {
        if (resp !== null) {
          const { paging: { next = '', previous = '' } = {}, builds } = resp;

          this.setState({
            builds,
            nextResultsURL: next,
            prevResultsURL: previous,
          });
        }
      })
      .catch((err) => {
        NotificationManager.warning('Unable to get releases', err);
      });
  }

  // Assigns focus to a particular release
  // Signals transition to build table
  assignFocus(releaseID: string): void {
    const { releases } = this.state;
    this.setState({ releases, focused: true, focusedReleaseID: releaseID });
  }

  // Removes focus from a particular release
  // Signals transition to release table
  removeFocus(): void {
    const { releases } = this.state;
    this.setState({ releases, focused: false, focusedReleaseID: '' });
  }

  // Renders the component
  render(): JSX.Element {
    let mainTable;
    let title;
    let nextResultsButton;
    let prevResultsButton;
    let refreshButton;
    const { focused, focusedReleaseID, prevResultsURL, releases, nextResultsURL } = this.state;
    if (!focused) {
      // Release table
      mainTable = this.getReleaseTable();
      title = i18next.t('release-management.table.title');
      nextResultsButton = this.getFuncButton(i18next.t('release-management.more-releases'), this.getNextResults);
      prevResultsButton = this.getFuncButton(i18next.t('release-management.prev-releases'), this.getPrevResults);
      refreshButton = this.getFuncButton(i18next.t('release-management.refresh-releases'), this.refreshReleases);
    } else {
      // Build Table
      mainTable = this.getBuildTable();
      title = i18next.t('build-management.table.title');
      title += focusedReleaseID;
      refreshButton = this.getFuncButton(i18next.t('release-management.refresh-builds'), this.refreshBuilds);
    }
    return (
      <div className="release-management-container">
        <h2>{title}</h2>
        {focused && (
          <div className="back-btn">
            <Button onClick={this.removeFocus} variant="contained" color="primary">
              Go Back
            </Button>
          </div>
        )}
        {mainTable}
        <div className="release-management-controls">
          {prevResultsURL && releases && releases.length > 0 && prevResultsButton}
          {nextResultsURL && releases && releases.length > 0 && nextResultsButton}
          {!focused && (
            <div>
              <Link to={ROUTES.RELEASE_CREATE} style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="primary">
                  {i18next.t('release-management.create-new-release')}
                </Button>
              </Link>
              <br />
            </div>
          )}
          {refreshButton}
        </div>
      </div>
    );
  }
}
