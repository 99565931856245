import { BASE_CONFIG_ID, WHITELABEL_CONFIGS } from 'const';
import { getBuilds, getConfig } from 'services/appcenter/appcenter.service';

import Build from 'models/Build';
import BuildsConfig from '../models/BuildsConfig';
import Config from 'models/Config';
import api from 'services/helix/helix.api';

export const saveAppsVersionRequest = async (config: Config): Promise<Response> =>
  api.put<Response>(`${WHITELABEL_CONFIGS}/${BASE_CONFIG_ID}`, config);

export const loadConfigSaga = async (owner: string, platform: string, token: string): Promise<BuildsConfig | null> => {
  try {
    return await getConfig(owner, platform, token);
  } catch (error) {
    return null;
  }
};

export const loadBuildsSaga = async (owner: string, platform: string, token: string): Promise<Array<Build> | string> =>
  await getBuilds(owner, platform, token)
    .then((data: Array<Build>) => data)
    .catch((error: string) => error);
