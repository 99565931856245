import { Button, IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { ChangeEvent, ReactText, useState } from 'react';
import { Check, Close } from '@material-ui/icons';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';

interface FieldsContainerProps {
  title: string;
  titleClassName: string;
  items: Array<string>;
  setField: (value: string, index: number) => void;
  addField: () => void;
  deleteField: (index: number) => void;
  description?: string;
  locked?: boolean;
  handleSave?: () => void;
  resetFields?: () => void;
}

const FieldsContainer = ({
  title,
  titleClassName,
  items,
  setField,
  addField,
  deleteField,
  description,
  locked,
  handleSave,
  resetFields,
}: FieldsContainerProps): JSX.Element => {
  const { t } = useTranslation();
  const [isEdit, setEditMode] = useState<boolean>(false);

  function handleSectionSave() {
    handleSave();
    setEditMode(!isEdit);
  }

  function resetSection() {
    resetFields();
    setEditMode(!isEdit);
  }

  function handleTextFieldChange(index: number) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setField(value, index);
    };
  }

  function changeEditMode() {
    setEditMode(!isEdit);
  }

  function handleDeleteClick(index: number) {
    return () => deleteField(index);
  }

  const deleteIcon = (index: ReactText) => {
    if (isEdit) {
      return (
        <InputAdornment position="end">
          <IconButton onClick={handleDeleteClick(+index)} size="small">
            <DeleteIcon className="delete-icon" />
          </IconButton>
        </InputAdornment>
      );
    }

    return '';
  };

  const actionButtons = () => {
    if (isEdit && !locked) {
      return (
        <span>
          <Tooltip title={t('release-apps.config-info.config-tab.add-field')} placement="top" arrow>
            <Button onClick={addField} size="small">
              <AddIcon />
            </Button>
          </Tooltip>
          <Button onClick={handleSectionSave} size="small">
            <Check />
          </Button>
          <Button onClick={resetSection} size="small">
            <Close />
          </Button>
        </span>
      );
    }

    if (!locked) {
      return (
        <Button onClick={changeEditMode} size="small">
          <EditIcon />
        </Button>
      );
    }

    return '';
  };

  return (
    <div>
      <div className="title-container">
        <span className={titleClassName}>{title}</span>
        <span>{actionButtons()}</span>
      </div>
      <div className={titleClassName === 'sub-title' ? 'title-description' : ''}>{description}</div>
      <div className="fields-container">
        {Array.isArray(items) ? (
          items.map((field: string, index: ReactText) => (
            <TextField
              // eslint-disable-next-line react/no-array-index-key
              key={index.toString()}
              className="text-field"
              value={field}
              onChange={handleTextFieldChange(+index)}
              disabled={!isEdit}
              InputProps={{
                endAdornment: deleteIcon(index),
              }}
            />
          ))
        ) : (
          <TextField
            className="text-field"
            value={items}
            disabled={!isEdit}
            onChange={handleTextFieldChange(0)}
            InputProps={{
              endAdornment: deleteIcon(0),
            }}
          />
        )}
      </div>
    </div>
  );
};

FieldsContainer.defaultProps = {
  description: '',
  locked: false,
  handleSave: () => {
    /* do nothing */
  },
  resetFields: () => {
    /* do nothing */
  },
};

export default FieldsContainer;
