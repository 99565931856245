import { CREATE_RELEASE, UPDATE_RELEASE } from '../../constants/ActionTypes';

import { all, call, takeEvery } from 'redux-saga/effects';

// eslint-disable-next-line sort-imports
import { CreateRelease, UpdateRelease } from '../actions/types/Releases';

import { NotificationManager } from 'react-notifications';

import i18next from 'i18next';
import { resolveOmegaAction } from '../../util/release-management.utils';

export function* createReleaseSaga({ payload }: CreateRelease): Generator {
  const userUUID = localStorage.getItem('user_uuid');
  // eslint-disable-next-line redux-saga/no-unhandled-errors
  const result = yield call(resolveOmegaAction, '/v1/releases', { ...payload, UserUUID: userUUID });

  if (result) {
    NotificationManager.success(
      i18next.t('release-management.create-release-message'),
      i18next.t('release-management.create-release-title')
    );
  } else {
    NotificationManager.error(i18next.t('release-management.create-release-error'));
  }
}

export function* updateReleaseSaga({ payload }: UpdateRelease): Generator {
  const { releaseUUID, request } = payload;
  request.newReleaseDetails.userUUID = localStorage.getItem('user_uuid');
  NotificationManager.success(
    i18next.t('release-management.update-release-message'),
    i18next.t('release-management.update-release-title')
  );
  // eslint-disable-next-line redux-saga/no-unhandled-errors
  yield call(resolveOmegaAction, `/v1/releases/${releaseUUID}`, request, 'PUT');
}

export default function* root(): Generator {
  // eslint-disable-next-line redux-saga/no-unhandled-errors
  yield all([takeEvery(CREATE_RELEASE, createReleaseSaga), takeEvery(UPDATE_RELEASE, updateReleaseSaga)]);
}
